import React from 'react';
import './style.scss';
import { Anchor, Title } from '../../Typography2';
import { Paragraph } from '../../Typography2';
import Login from '../../../containers/Login';

const Form = () => {
  return (
    <div className='container pair-vr-form'>
        <div>
        <Title>Welcome to <span>Vortic VR</span></Title>
        <Paragraph>In order to access exhibitions in VR, please log in. Once logged in you’ll, create a customised avatar.</Paragraph>
        <Anchor to="signup" isLocalLink>If you’re not registered, please create an account</Anchor>
        </div>
        <div>
            <div className='forms'>
                {/* @ts-ignore */}
                <Login title="LOG IN"  emailPlaceholder="Email Address" passwordPlaceholder="Password" />
            </div>
        </div>
    </div>
  )
}

export default Form;
