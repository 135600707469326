import {useInView} from "react-intersection-observer";
import React from "react";

const ScrollAnimation = (props) => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.0,
        delay: props.delay || 100
    });

    const delayTime = props.delay ? props.delay / 1000 : 0

    return (
        <div
            ref={ref}
            data-inview={inView}
            style={{
                width: '100%',
                animation: `${inView && delayTime ? `fadeUp ${delayTime}s ease forwards` : ''}`,
                ...props.style
            }}
            className={`${inView ? `${props.isExhibition ? 'fade-up exhibitions-card' : 'fade-up '}` : 'fade-out'} ${props.className}`}
        >
            {props.children}
        </div>
    )
}

export default ScrollAnimation;
