import React from "react";
import { IConfirmationPopup } from "./types";
import "./style.scss";
import Button from "../Button";
import { Title2 } from "../Typography2";

const ConfirmationPopup: React.FC<IConfirmationPopup> = ({
  variant,
  handleConfirm,
  handleCancel,
}) => {
  const isExitScreen = variant === "exitScreen";

  return (
    <div className="curate-instructions exit-exhibition">
      <div className="confirmation-box">
        <Title2
          value={
            isExitScreen
              ? "Are you sure you want to leave the exhibition?"
              : "Do you want to visit a different room in the exhibition?"
          }
        />
        <div className="confirmation-btn-wrap">
          <Button
            value={isExitScreen ? "Leave Exhibition" : "Load Room"}
            size="large"
            className={`secondary btn-bright`}
            onClick={handleConfirm}
          />
          <Button
            value="Cancel"
            size="large"
            className={`secondary btn-transparent`}
            onClick={handleCancel}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPopup;
