import React, { useEffect, useRef, useState } from 'react';
import "./style.scss";

const CurateModeSwitch = ({ isFreeMode, closeDetails, openDetails }) => {

    const isDetailedMode = !isFreeMode;
    const [isHoverOver, setIsHoverOver] = useState(false);
    const [isLocalFreeMode, setIsLocalFreeMode] = useState(false);
    const [isHoverEffectStarted, setIsHoverEffectStarted] = useState(false);
    const tooltipTimerRef = useRef<any>(null);
    const hoverStartRef = useRef<any>(null);
    const modeSwitchTimerRef = useRef<any>(null);

    const handleOnChange = () => {
        if (isDetailedMode) {
            closeDetails();
        } else {
            openDetails();
        }

        setIsHoverEffectStarted(false);
        handleOnMouseLeave();
    }

    const handleOnMouseHover = () => {
        tooltipTimerRef.current = setTimeout(() => {
            setIsHoverOver(true);
            setIsHoverEffectStarted(true);
        }, 1000)
    }

    const handleOnMouseLeave = () => {
        clearTimeout(tooltipTimerRef.current);
        setIsHoverOver(false);
        if (!isHoverEffectStarted) {
            setIsHoverEffectStarted(false);
        }
    }

    useEffect(() => {
        if (isHoverEffectStarted && !isHoverOver) {
            hoverStartRef.current = setTimeout(() => {
                setIsHoverEffectStarted(false);
            }, 1000)
        } else {
            if (hoverStartRef.current && !isHoverEffectStarted) {
                clearTimeout(hoverStartRef.current);
            }
        }
    }, [isHoverEffectStarted, isHoverOver])

    useEffect(() => {
        clearTimeout(modeSwitchTimerRef.current);
        modeSwitchTimerRef.current = setTimeout(() => {
            setIsLocalFreeMode(isFreeMode);
        }, 1000)
    }, [isFreeMode])

    useEffect(() => {
        return () => {
            try {
                clearTimeout(tooltipTimerRef.current);
                clearTimeout(hoverStartRef.current);
                clearTimeout(modeSwitchTimerRef.current);
            } catch (err) {
                console.log('err : ', err);
            }
        }
    }, [])

    return (
        <button className={`curate-mode-switch ${isHoverOver ? 'curate-mode-switch--hover' : ''} ${isFreeMode ? 'curate-mode-switch--free-mode-active' : ''}`} onClick={handleOnChange} onMouseEnter={() => handleOnMouseHover()} onMouseLeave={() => handleOnMouseLeave()}>
            <span className={`curate-mode-switch__tooltip ${!isLocalFreeMode ? 'curate-mode-switch__tooltip--free-mode' : ''}`}>
                <span className='curate-mode-switch__tooltip-text'>{!isLocalFreeMode ? 'Enter Free Move Mode' : 'Return to Tour'}</span>
            </span>
            <span className={`curate-mode-switch__svg-container ${isFreeMode ? 'curate-mode-switch__svg-container--free-mode' : ''} ${isHoverEffectStarted ? 'curate-mode-switch__svg-container--after-effect' : ''}`}>
                <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.98664 11.5416L7.44546 8.86391V2.55173H8.8976V8.86391L14.3564 11.5416L13.6266 12.7506L8.16773 10.0729L2.7089 12.7506L1.98664 11.5416ZM0.0935324 12.6764L1.37841 9.84301C1.47978 9.60565 1.6141 9.43752 1.78136 9.33863C1.95369 9.23973 2.12855 9.21748 2.30595 9.27187C2.48842 9.32132 2.64555 9.45978 2.77733 9.68724L4.66283 12.8693C4.80475 13.1017 4.8529 13.3069 4.80728 13.4849C4.76673 13.6679 4.65776 13.8039 4.48036 13.8929C4.30803 13.9868 4.08755 14.019 3.81892 13.9893L0.663744 13.6481C0.374837 13.6135 0.177163 13.5097 0.0707239 13.3366C-0.030647 13.1635 -0.0230442 12.9435 0.0935324 12.6764ZM16.2419 12.6764C16.3585 12.9435 16.3636 13.1635 16.2571 13.3366C16.1558 13.5097 15.9606 13.6135 15.6717 13.6481L12.5165 13.9893C12.253 14.019 12.0325 13.9868 11.8551 13.8929C11.6777 13.8039 11.5662 13.6679 11.5206 13.4849C11.48 13.3069 11.5307 13.1017 11.6726 12.8693L13.5581 9.68724C13.6899 9.45978 13.8445 9.32132 14.0219 9.27187C14.2044 9.21748 14.3792 9.23973 14.5465 9.33863C14.7188 9.43752 14.8582 9.60565 14.9647 9.84301L16.2419 12.6764ZM7.58992 0.348768C7.76731 0.111412 7.95739 -0.00479354 8.16013 0.000151375C8.36287 0.000151375 8.55294 0.116357 8.73034 0.348768L10.6082 2.83359C10.7704 3.05116 10.8541 3.25638 10.8591 3.44923C10.8693 3.64208 10.8034 3.80032 10.6615 3.92394C10.5246 4.04757 10.3168 4.10938 10.038 4.10938H6.28223C6.0136 4.10938 5.80832 4.04757 5.6664 3.92394C5.52955 3.80032 5.46366 3.63961 5.46873 3.44181C5.4738 3.24402 5.55489 3.04127 5.71202 2.83359L7.58992 0.348768Z" fill="currentColor" />
                </svg>
            </span>
        </button>
    )
}

export default CurateModeSwitch;