import React, { useEffect,useState, useRef } from 'react'
import {IDetailsHeaderProps} from './types';
import {Label, Title, Paragraph} from "../Typography2";
import IconBtn from '../IconBtn';

const AUTO_SCROLL_TIME = 7000;

const DetailsHeader: React.FC<IDetailsHeaderProps> = ({title, button, CardsView , CollectivesCards , onButtonClick, position, handleLeftClick, handleRightClick, className, totalLength, currentItem, resetAll, isHover, setIsHover}) => {
    const ItemRef = useRef(null);
    let interval;
    const [showArrow , setShowArrow] = useState(false);
     const handleArrowLeft = () => {
        setIsHover && setIsHover(false);
        handleLeftClick();
        setIsHover && setIsHover(true);
        setTimeout(() => {
            setIsHover && setIsHover(false);
        }, 350)
    }

    const handleArrowRight = () => {
        setIsHover && setIsHover(false);
        handleRightClick();
        setIsHover && setIsHover(true);
        setTimeout(() => {
            setIsHover && setIsHover(false);
        }, 350)
    }

    const handleInterval = () => {
        interval = setInterval(() => {
            const position = ItemRef?.current?.querySelector('.position') && ItemRef.current.querySelector('.position').innerText && ItemRef.current.querySelector('.position').innerText.split('/');
            const current = position && position[0] && parseInt(position[0] || 1);
            const total = position && position[1] && parseInt(position[1]);
            if(current < total){
                const arrow = ItemRef.current.querySelector('.carousel-right-arrow');
                arrow.click();
            }
            else{
                resetAll();
            }
        }, AUTO_SCROLL_TIME);
    }

    const toggleArrows = () => {
        if ( CollectivesCards?.current?.offsetWidth >  CardsView?.current?.offsetWidth ) {
            setShowArrow(true)
        }
        else {
            setShowArrow(false)
        }
    }

    useEffect(() => {
        toggleArrows();
        
        window.addEventListener("resize", () => {
            toggleArrows();
        });

        if (isHover === false) {
            handleInterval();
        }
        else {
            clearInterval(interval);
        }
    }, []);

    return (
        <div className={`details-header ${className || ''}`} ref={ItemRef}>
            <Title value={title || ''}/>
            {button && (
                <div className="details-header__button" onClick={onButtonClick}>
                    <Label className="" value={button} />
                    <IconBtn type="down" />
                </div>
            )}
            {(position) && <div className='scroll-pointers'>
                {position && <Paragraph className={'position'} value={position}/>}
                {handleLeftClick && CardsView && showArrow && <IconBtn type ='back' className='remove-arrow inverse-img' onClick={handleArrowLeft} />}
                {handleRightClick && showArrow && <IconBtn type ='next' className='remove-arrow inverse-img' onClick={handleArrowRight} />}
                {handleLeftClick && !CardsView && <IconBtn type ='back' className='remove-arrow inverse-img' onClick={handleArrowLeft} />}
                {handleRightClick && !CardsView && <IconBtn type ='next' className='remove-arrow inverse-img' onClick={handleArrowRight} />}
            </div>}
        </div>
    )
};

export default DetailsHeader
