import {useEffect, useState} from "react";
import {fetchCollaborations, fetchCurateDetails} from "../../services/api";
import CollaborationsNewPage from './CollaborationsNewPage';
import React from "react";
import {CurateContextProvider} from "./contexts/curateContext";
import {
    isMobileDevice,
  } from "../../utils";
import Spinner from "../../components/Spinner";
import rudderStackEvents from "../../services/rudderstack-events";
import isProductionWebAppUrl from "../../utils/isProductionWebAppUrl";

const CollaborationsNewPageContainer = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [curateDetails, setCurateDetails] = useState(null);
    const [collaborationsData, setCollaborationsData] = useState(null);
    const loadCurateDetails = async () => {
        const url = isMobileDevice() ? 'untitled-exhibition-mobile-version-8057' : 'untitled-lobby-web-version-8044';
        const curateData = await fetchCurateDetails(url, undefined, false, false, false)
        const _collaborationsData = await fetchCollaborations()

        if(curateData) {
            setCurateDetails({
                galleryFiles: curateData?.models[0],
                linkedArtworks: curateData?.dimensions
            })
        }
        if(_collaborationsData) {
            setCollaborationsData(_collaborationsData?.data?.data)
        }
        setIsLoading(false);
    }

    useEffect(() => {
        loadCurateDetails();
        if (isProductionWebAppUrl()) {
            rudderStackEvents.setPagePath(window.location.href);
            rudderStackEvents.onUntitledPageLoad()
        }
    }, [])

    if(isLoading) {
        return <div className="collaborations-new-page-loader"><Spinner /></div>;
    }

    return <CurateContextProvider {...curateDetails} collaborationsData={collaborationsData}><CollaborationsNewPage initialCollaborationsData={collaborationsData} /></CurateContextProvider>;
}
export default CollaborationsNewPageContainer;
