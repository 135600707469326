import React from 'react'
import { Paragraph } from '../Typography2';
import "./style.scss";

const SpinningLoader = () => {
  return (
    <div className="spinning-loader-wrapper">
        <div className="spinning-loader" />
        <Paragraph>Loading additional images</Paragraph>
    </div>
  )
}

export default SpinningLoader;