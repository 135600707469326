import React from 'react';
import Left from '../../assets/icons/left.svg';
import Right from '../../assets/icons/right.svg';
import {IPagination} from './types'

const Pagination: React.FC<IPagination> = ({items, handlePrevious, handleNext, handleDotClick, active}) => {

    return (
        <div className="pagination">
            <img src={Left} alt="Left" onClick={handlePrevious}/>
            <div className="dots-wrapper">
                {
                    !!items && items.map((item, index) => {
                        return (
                            <div className={'dot' + (active === index ? ' active' : '')}
                                 onClick={() => handleDotClick(index)}/>
                        )
                    })
                }
            </div>
            <img src={Right} alt="Right" onClick={handleNext}/>
        </div>
    )
}

export default Pagination;
