/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useImperativeHandle} from 'react';
import './style.scss';
const audioRef = {current: new Audio()}
const AudioComponent = React.forwardRef((props, ref) => {
    // @ts-ignore
    const {url, setCurrentTime, setTotalTime, setPlaying} = props;
    const [localCurrentTime, setLocalCurrentTime] = useState(0);

    useEffect(() => {
      if(localCurrentTime === audioRef.current.duration){
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        setCurrentTime(0);
      } else {
        setCurrentTime(localCurrentTime);
      }
    }, [localCurrentTime])

    const resetAll = React.useCallback(() => {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        setTotalTime(0);
        setCurrentTime(0);
    }, [])

    const restart =  React.useCallback(() => {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        setCurrentTime(0);
    }, [])
    

    useEffect(() => {
        return () => {
            resetAll();
        };
    }, [])
    useEffect(() => {
        audioRef.current.crossOrigin = "anonymous";
        audioRef.current.src = url;
    }, [url]);

    const loadData = () => {
        setTotalTime(Math.round(audioRef.current.duration))
        setCurrentTime(Math.round(audioRef.current.currentTime));
    }

    const timeUpdate = () => {
        setLocalCurrentTime(audioRef.current.currentTime);
    }

    const handleAudioPlay = (value) => {
        try {
            setPlaying(value);
        } catch (err) {
            console.error('err');
        }
    }

    useEffect(() => {
        resetAll();
        try {
            audioRef.current.load();
        } catch (err) {
            console.error('err');
        }
        audioRef.current.addEventListener('loadeddata', loadData);
        audioRef.current.addEventListener('timeupdate', timeUpdate)
        audioRef.current.addEventListener('play', () => handleAudioPlay(true));
        audioRef.current.addEventListener('pause', () => handleAudioPlay(false));

        return () => {
            audioRef.current.removeEventListener('loadeddata', loadData);
            audioRef.current.removeEventListener('timeupdate', timeUpdate)
            audioRef.current.removeEventListener('play', () => handleAudioPlay(true));
            audioRef.current.addEventListener('pause', () => handleAudioPlay(false));
        }
    }, [audioRef.current.src])

    const togglePlay = (val) => {
        if(val) {
              audioRef.current.play();
        } else {
            audioRef.current.pause();
        }
    }

    useImperativeHandle(
        ref,
        () => ({
            reset: () => {
                resetAll();
            },
            restart: () => {
                restart();
            },
            togglePlay: (val) => {
                togglePlay(val);
            }
        }),
    );

    return (
        <></>
    )
});

export default AudioComponent;
