import React, { useState, useEffect, useRef } from 'react'
import ListviewBanner from '../ListviewBanner';
import ListviewAbout from '../ListviewAbout';
import ListViewDiscover from '../ListViewDiscover';
import ListviewHead from '../ListviewHead';
import ListviewArtworks from '../ListviewArtworks';
import Spinner from '../Spinner';
import "./style.scss";
import { constructImageUrl, isMobileDevice } from '../../utils';
import useReadMoreData from '../hooks/useReadMoreData';

const Listview = ({ image, video, about, isMuseumType, descriptionHtml, description, galleryName, exhibitionName, handleExploreClick, exhibitionId, startedAt, endedAt, collateralElement, exhibitionSlug, toggleFavourite, isFavourite, setToggleLogin, isLoggedIn, artworks, handleArtworkClick, setIs3dViewOpen, uuid, thumbnail, isVideoEnabled, isArtistVisible, isDateVisible }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [headeHeight, setHeaderHeight] = useState(0);
  const headerRef = useRef(null);
  const prevScrollY = useRef(0);
  const [goingUp, setGoingUp] = useState(false);
  const [isHeaderActive, setHeaderActive] = useState(false);
  const [isTransition, setIsTransition] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const {
    loadExhibitionArtists,
    artists
} = useReadMoreData(exhibitionSlug || uuid);

  useEffect(() => {
    loadExhibitionArtists();
  }, [])

  useEffect(() => {
    if (!!headerRef?.current?.clientHeight) {
      setHeaderHeight(headerRef.current.clientHeight * -1);
    }
  }, [headerRef]);

  const handleScrollDirection = () => {
    const listviewBody = document.querySelector('.curate-flexbox--listview');
    const currentScrollY = listviewBody.scrollTop;
    if (prevScrollY.current < currentScrollY && goingUp) {
      setGoingUp(false);
    }
    if (prevScrollY.current > currentScrollY && !goingUp) {
      setGoingUp(true);
    }
    prevScrollY.current = currentScrollY;
  }

  const handleAboutPosition = () => {
    const listviewAbout = document.querySelector('.listview-about');
    if (listviewAbout.scrollTop - listviewAbout?.getBoundingClientRect().top > 0) {
      setIsTransition(true);
      setHeaderActive(true);
    } else {
      setIsTransition(false);
    }
    if ((listviewAbout.scrollTop - headeHeight) - listviewAbout?.getBoundingClientRect().top < 0) {
      if (isHeaderActive) {
        setHeaderActive(false);
      }
    }
  };

  const handleScroll = () => {
    handleScrollDirection();
    handleAboutPosition();
  }

  useEffect(() => {
    if (window.innerWidth > 768) {
      const listviewBody = document.querySelector('.curate-flexbox--listview');
      listviewBody.addEventListener('scroll', handleScroll);
      return () => {
        listviewBody.removeEventListener('scroll', handleScroll);
      }
    }
  })

  useEffect(() => {
    setLoaded(false);
    let listview = document.querySelector('.curate-flexbox--listview');
    if(listview?.scrollTop > 0) {
      listview.scroll({ top: 0, left: 0 });
      setTimeout(() => {
        setLoaded(true);
      }, 1000)
    } else {
      setLoaded(true);
    }
    return () => {
      let listview = document.querySelector('.curate-flexbox--listview');
      if(listview?.scrollTop > 0) {
        setLoaded(false);
      }
    }
  }, [])

  return (
    <div className="listview">
      <header className={`listview__header ${isHeaderActive && goingUp ? 'active' : ''} ${isTransition ? 'transition' : ''}`} style={{ top: headeHeight }} ref={headerRef}>
        <ListviewHead isArtistVisible={isArtistVisible} galleryName={galleryName} exhibitionName={exhibitionName} handleExploreClick={handleExploreClick} artists={artists} />
      </header>
      <ListviewBanner
        galleryName={galleryName}
        video={video}
        image={image}
        exhibitionName={exhibitionName}
        handleExploreClick={handleExploreClick}
        setImageLoaded={setImageLoaded}
        imageLoaded={imageLoaded}
        headeHeight={headeHeight}
        thumbnail={thumbnail}
        isVideoEnabled={isVideoEnabled}
        isArtistVisible={isArtistVisible} 
        artists={artists}
      />
      <ListviewAbout description={description} descriptionHtml={descriptionHtml} about={about} imageLoaded={imageLoaded} exhibitionId={exhibitionId} exhibitionSlug={exhibitionSlug} isLoggedIn={isLoggedIn} toggleFavourite={toggleFavourite} isFavourite={isFavourite} setToggleLogin={setToggleLogin} uuid={uuid} />
      <ListviewArtworks
        artworks={artworks}
        isMuseumType={isMuseumType}
        handleArtworkClick={handleArtworkClick}
        setIs3dViewOpen={setIs3dViewOpen}
        isArtistVisible={isArtistVisible}
      />
      <ListViewDiscover isDateVisible={isDateVisible} exhibitionId={exhibitionId} exhibitionSlug={exhibitionSlug} uuid={uuid} showVideo={video?.data?.signedMobileMedia || video?.data?.signedDesktopMedia} signedVideoThumbnail={thumbnail ? constructImageUrl(thumbnail, (isMobileDevice() ? "480" : "1920")) : video?.data?.signedVideoThumbnail} startedAt={startedAt} endedAt={endedAt} name={galleryName} isCollateralDetails={true} collateralElement={collateralElement} signedMobileMedia={video?.data?.signedMobileMedia} signedDesktopMedia={video?.data?.signedDesktopMedia} />
      <div className={`listview__loader ${loaded ? 'hide' : ''}`}>
        <Spinner />
      </div>
    </div>
  )
}

export default Listview;
