import React, {useContext, useEffect, useState} from "react";
import "./styles.scss";
import crossIcon from '../../assets/icons/cross-white.svg';
import FilterPill from '../FilterPill/FilterPill';
import Button from "../Button";
import {GalleryItem} from "../GalleryList/types";
import {CollaborationContext} from "../../containers/CollaborationsNewPage/CollaborationsContext";


interface IProps {
    handleClose: () => void
    handleSelect: (regions: GalleryItem[]) => void
    data: GalleryItem[],
    isOpen?: boolean,
    selectedRegions?: string[]
}

const Filter = ({handleClose, isOpen = false,  }: IProps) => {
    const [regions, setRegions] = useState<GalleryItem[]>([]);
    const [disabledApply, setDisabledApply] = useState<boolean>(false)
    const [disabledCancel, setDisabledCancel] = useState(false)

    const {filterData:data, selectedRegions, handleRegion:handleSelect }=useContext(CollaborationContext)
    const handlePillClick = (id: number, state: boolean) => {
        const temp = [...regions]
        const index = temp.findIndex(item => item.id === id)
        if(state && temp[index].selected){
            return
        }
        temp[index] = {
            ...temp[index],
            selected: state
        }
        setRegions(temp)
        const isDisabledApply = !temp.some(item => item.selected);
        setDisabledApply(selectedRegions.length === 0 && isDisabledApply)
    }

    const handleReset = () => {
        let temp = regions.map(region => ({
            ...region,
            selected: false
        }))

        setRegions(temp)
        if(selectedRegions.length>0){
            handleSelect(temp)
        }
        setDisabledApply(true)
    }
    const handleCloseClick = () => {
        handleClose();
    }
    const handleApply = () => {
        const temp = regions.filter(item => item.selected)
        if (temp.length === 0) {
            handleReset()
        } else {
            handleSelect(regions)
        }
        handleClose()
    }

    useEffect(() => {
       if(data){
           let temp = []
           data.forEach(item => {
               if (!temp.some(current => current.continent === item.continent)) {
                   temp.push(item)
               }
           })
           setRegions(temp)
       }
    }, [data])


    useEffect(() => {
        const isDisabled = !regions.some(item => item.selected);
        setDisabledCancel(isOpen && isDisabled)
    }, [isOpen, regions])

    useEffect(() => {
        setDisabledApply(true)
        if (isOpen) {
            const temp = regions.map(item => ({
                ...item,
                selected: selectedRegions.includes(item.continent)
            }))
            setRegions(temp)
        }
    }, [isOpen])


    return (
        <div className="filter">
            <div className="filter__header">
                <p className="filter__header__label">Filter by region</p>
                <button className="filter__header__button" onClick={handleCloseClick}>
                    <img src={crossIcon} alt='search'/>
                </button>
            </div>
            <div className="filter__pills">
                {regions.map((region: GalleryItem) => {
                    if (region.continent === '') {
                        return null
                    }
                    return <FilterPill key={region.id} isSelected={region.selected} label={region.continent}
                                       id={region.id}
                                       handlePillClick={handlePillClick}/>
                })}
            </div>
            <div className="filter__footer">
                <Button type="secondary" onClick={handleReset} value="Reset" disable={disabledCancel}/>
                <Button type="secondary" className="button__white" onClick={handleApply} value="Apply"
                        disable={disabledApply}/>
            </div>
        </div>
    );
}

export default Filter;
