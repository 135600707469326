import React, { useEffect, useRef, useState, useMemo } from "react";
import {Paragraph, Heading } from '../Typography2';
import ImageLoader from "../ImageLoader/ImageLoader";
import RenderHtml from "./RenderHtml";
import AboutVideo from "../AboutVideo";
import { constructImageUrl, constructVideoUrl, isMobileDevice, IsSafari } from "../../utils";
import RenderTextBlocks from "../Layouts/RenderText";

const RenderText = (parsedData) => {
    const textBlocks = useMemo(() => parsedData.text, [parsedData]);

    if (textBlocks) {
        return <RenderTextBlocks textDetails={textBlocks} />   
      } else {
        return <RenderHtml data={parsedData} />;
      }
}
 
const RenderContent = ({type, data, noVideoFullScreen = false}) => {

    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const openFullscreen = () => {
        if (videoRef.current.paused) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        videoRef.current.controls;
        videoRef.current.play();
        setIsPlaying(true);
        if(!noVideoFullScreen){
            if (videoRef.current.requestFullscreen) {
                videoRef.current.requestFullscreen();
            } else if (videoRef.current.webkitRequestFullscreen) {
                /* Safari */
                videoRef.current.webkitRequestFullscreen();
            } else if (videoRef.current.msRequestFullscreen) {
                /* IE11 */
                videoRef.current.msRequestFullscreen();
            }
        }
        } else {
        videoRef.current.pause();
        setIsPlaying(false);
        }
    };

    if (type === "text") {
        const {text, html} = data;

        if(text && text.data && text.data.text) {
            return <div className="main-text-wrapper"><RenderText text={text && text.data && text.data.text && JSON.parse(text.data.text)} /></div>
        } else if(html) {
            return (
                <div className="main-text-wrapper draft-text-v2 html-text-wrapper" id="html-content" dangerouslySetInnerHTML={{ __html: html }} />
            )
        }
        return <></>;

    } else if (type === "image") {
        const {image, caption} = data;
        return (
            <div  className="collateral_img_preview-wrapper">
                {
                    image && image.data && (
                        <>
                                <div className="collateral__details__img-preview">
                                    <ImageLoader
                                        imgUrls={[constructImageUrl(image, "1024")]}/>
                                </div>

                            {caption &&
                              <Paragraph className="collateral__caption" value={caption}/>
                            }
                        </>
                    )
                }
            </div>
        )
    } else if (type === "video") {
        const {video, videoThumbnail, channel} = data;
        return (
            <>
                {   
                    video && video.data && (
                        <div className="collateral__video-wrapper">
                            <>
                                <div className="collateral__details__preview">
                                    <AboutVideo
                                        videoRef={videoRef}
                                        onClick={(e) => { e.stopPropagation(); openFullscreen() }}
                                        poster={constructImageUrl(videoThumbnail, (isMobileDevice() ? "480" : "1024"))}
                                        image={constructImageUrl(videoThumbnail, (isMobileDevice() ? "480" : "1024"))}
                                        classNames={noVideoFullScreen ? `no-fullscreen ${isPlaying ? "no-fullscreen--playing" : ""}` : 'thumbnail-view'}
                                        src={[constructVideoUrl(video)]}
                                    />
                                    
                                </div>
                            </>
                        </div>
                    )
                }
            </>
        )
    } else {
        return <></>
    }
}

function CollateralLayouts  ({layout, noVideoFullScreen = false}) {

    const getColClassName = (item) => {
        if (!!item) {
            let start = item.x + 1;
            let end = start + item.columnType;

            switch (start + '/' + end) {
                case '1/2':
                    return 'col-one-by-two';

                case '1/3':
                    return 'col-one-by-three';

                case '1/4':
                    return 'col-one-by-four';

                case '2/3':
                    return 'col-two-by-three';

                case '2/4':
                    return 'col-two-by-four';

                case '3/4':
                    return 'col-three-by-four';

                default:
                    return '';
            }
        } else return '';
    };

    return (
        <>
            <div className="layout-wrapper">
                {
                    layout && layout.length > 0 && layout.map((item, index) => (
                        <div key={index} className={`${getColClassName(item.data)}`}
                                style={{gridRow: `${item.data.y + 1} / auto`}}>
                            <div>
                                {item && item.data && item.data.title && <Heading className="collateral__main-heading"
                                            value={item && item.data && item.data.title}/>}
                                <RenderContent type={item.data.type} data={item && item.data} noVideoFullScreen={noVideoFullScreen} />
                            </div>
                        </div>
                    ))
                }
            </div>
        </>
    );
}

export default CollateralLayouts;
