import React from "react";
import ListviewArtworkGrid from "../ListviewArtworkGrid";
import Spinner from "../Spinner";
import "./style.scss";

const ListviewArtworks = ({
  artworks,
  handleArtworkClick,
  setIs3dViewOpen,
  isMuseumType,
  isArtistVisible
}) => {
  return (
    <div className="listview-artworks-wrapper" id="listview-artworks">
      {!(artworks?.filter((artwork) => !artwork.data.isCollateral).length > 0) ? (
        <Spinner />
      ) : (
        <ListviewArtworkGrid
            isMuseumType={isMuseumType}
          artworks={artworks.filter((artwork) => !artwork.data.isCollateral)}
          isArtistVisible={isArtistVisible}
          handleArtworkClick={(val) => {
            handleArtworkClick(val);
          }}
          setIs3dViewOpen={setIs3dViewOpen}
        />
      )}
    </div>
  );
};

export default ListviewArtworks;
