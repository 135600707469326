import './style.scss'
import {connect} from "react-redux";
import Curate from './Curate'
// import {setCurrentConversation} from "../Conversation/redux/actions";
// import {setConversation} from "../Global/redux/actions";


const mapStatesToProps = ({ 
    profile, 
    global,
    // conversation, 
    }) => {
    return {
        profile: profile,
        isLoggedIn: global.isLoggedIn,
        // channel: conversation.currentConversation,
        // isConversation: global.isConversation,
        // exhibitionArtworkId:global.artworkId
    }
};

// const mapDispatchToProps = dispatch => ({
    // setCurrentChannel: (conversation) => {dispatch(setCurrentConversation(conversation))},
    // setConversation: (conversation) => {dispatch(setConversation(conversation))},
// });

// @ts-ignore
export default connect(mapStatesToProps)(Curate)
