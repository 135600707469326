import {SET_CURRENT_CONVERSATION, SET_ENQUIRE_MESSAGE, FETCH_TOTAL_UNREAD_SUCCESS, SET_ALL_CONVERSATIONS, SEND_BIRD_SUCCESS, SEND_BIRD_INIT} from "./actionTypes";

// @ts-ignore
export function setCurrentConversation(value){
    return {
        type: SET_CURRENT_CONVERSATION,
        payload: value
    }
}

export function fetchTotalUnreadSuccess(value){
    return {
        type: FETCH_TOTAL_UNREAD_SUCCESS,
        payload: value
    }
}

function setAllConversations(value){
    return {
        type: SET_ALL_CONVERSATIONS,
        payload: value
    }
}

export function setEnquireMessage(value){
    return {
        type: SET_ENQUIRE_MESSAGE,
        payload: value
    }
}


export function fetchSendBirdInit(value){
    return {
        type: SEND_BIRD_INIT,
        payload: value
    }
}
export function fetchSendBirdSuccess(value){
    return {
        type: SEND_BIRD_SUCCESS,
        payload: value
    }
}
