import React, {useState, useRef, useEffect} from 'react';
import {IAdvanceCarouselProps} from './types';


const AdvanceCarousel:React.FC<IAdvanceCarouselProps> = ({ children, groupCells, exhibitionPositionX, exhibitionSliderRef, isAutoScrollEnabled, arrows, responsiveToVertical }) => {

    const [positionX, setPositionX] = useState(0);
    const sliderRef = useRef(null);
    const [count, setCount] = useState(0)
    const handleLeftArrowClick = () => {
        if(positionX !== 0){
            setPositionX(positionX + 100);
        }else if (positionX===0){
            setPositionX (-(sliderRef.current.children.length-1)*100);
        }
    }

    const handleRightArrowClick = () => {
        const x = 1 + (positionX !== 0 ? (-1 * (positionX)/100) : 0);
        if(x < sliderRef.current.children.length){
            setPositionX(positionX - 100);
        }else if (x === sliderRef.current.children.length){
            setPositionX(0);
        }
        setCount(0)
    }
    useEffect(() => {
        const timer = setTimeout(() => setCount(count + 1), 1e3)
        return () => clearTimeout(timer)
    })
    useEffect(()=>{
        if (isAutoScrollEnabled&&count===6){
                handleRightArrowClick()
        }
    },[count])

    return (
        <div className={"advance-carousel" + (responsiveToVertical ? " responsive-to-horizontal" : "") + (groupCells ? ` groupcell-${groupCells}` : "")}>
            <div ref={exhibitionSliderRef?exhibitionSliderRef:sliderRef} className="carousel-slider" style={{ transform: `translateX(${exhibitionPositionX?exhibitionPositionX:positionX}%)` }}>
                {children}
            </div>

            {
                arrows && (
                    <>
                        <div className="arrow arrow-left" onClick={()=> {
                            handleLeftArrowClick()
                            setCount(0)
                        }}>
                            <svg width="21" height="38" viewBox="0 0 21 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.4854 1.35522L1.80768 19.0329L19.4854 36.7106" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                            </svg>
                        </div>
                        <div className="arrow arrow-right" onClick={()=> {
                            handleRightArrowClick()
                            setCount(0)
                        }}>
                            <svg width="21" height="38" viewBox="0 0 21 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.48535 36.3552L19.163 18.6776L1.48535 0.999885" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                            </svg>
                        </div>
                    </>
                )
            }
        </div>
    )
}

export default AdvanceCarousel;