import React, { useState, useEffect } from "react";
import { fetchArtworkDetails, favouriteArtworks } from '../../services/api';
import {getShareURL, toInches} from '../../utils';
import {useParams, useNavigate} from 'react-router-dom'
import Artwork2dCarousel from '../../components/Artwork2DCarousel'
import logger from "../../utils/logger";

const ArtworkPreview = ({ isLoggedIn }) => {
  const [artworkPhotos, setArtworkPhotos] = useState([]);
  const [artwork, setArtwork] = useState(null);
  const [audioPlaying, setAudioPlaying] = useState(false);
  const [audioUrl, setAudioUrl] = useState("");
  const [audioCurrentTime, setAudioCurrentTime] = useState(0);
  const [audioTotalTime, setAudioTotalTime] = useState(0);
  const [isAnime, setIsAnime] = useState(false);
  const { id: artworkId } = useParams();
  const navigate = useNavigate();
  const [isFavourite, setIsFavourite] = useState(false);

  // @ts-ignore
  // const artworkId = route && route.params && route.params?.id;

  const getArtwork = async () => {
    const resp = await fetchArtworkDetails(artworkId);
    if(resp) {
      setArtwork(resp);
    }
  }

  useEffect(() => {
    getArtwork();
  }, [])
  
  const toggleArtworkFavourite = async (id) => {
    if(isLoggedIn) {
      const resp = await favouriteArtworks(id);
      if (resp.data) {
          setIsFavourite(resp.data.isFavourite);
      }
    } else {
      navigate(`/login?/artworks/preview/${id}`);
    }
  };

  return (
    // @ts-ignore
    <Artwork2dCarousel
      preview
      progress={100}
      artworkPhotos={artworkPhotos}
      dimensionsInCM={`${parseFloat(artwork?.data.height).toFixed(1)} x ${parseFloat(artwork?.data.width).toFixed(1)} x ${parseFloat(artwork?.data.depth).toFixed(1)} cm`}
      dimensionsInIN={`${toInches(artwork?.data.height)} x ${toInches(artwork?.data.width)} x ${toInches(artwork?.data.depth)} in`}
      exhibitionId={"sdsd 234"}
      artworks={artwork}
      setArtwork2dModal={(val) => logger.log('setArtwork2dModal => ', val)}
      selectedArtwork={artwork}
      artworkMedium={artwork?.data?.medium}
      isArtworkActiveIn3dFrom2dDetailedView={false}
      on3dButtonClick={(val) => logger.log('on3dButtonClick ytete', val)}
      handleClose={() => logger.log('yewya')}
      shareUrl={getShareURL("artwork", artwork?.data?.id)}
      isFavourite={isFavourite}
      isSidebar={false}
      onClickSlider={() => logger.log('onClickSlider => yeeyz')}
      onFavourite={() => toggleArtworkFavourite(artworkId)}
      chainLink={"sds"}
      getCurateHeight={null}
      exhibitionsId={null}
      galleryName={artwork?.data?.organisation?.data?.name}
      exhibitiondId={null}
      isArtwork2dModal
      is3dActive={false}
      setIs3dActive={(val) => logger.log('val => ', val)}
      is3dViewOpen={false}
      setIs3dViewOpen={(val) => logger.log('yeey')}
      handleSelectedArtwork={(val) => logger.log('handleSelectedArtwork', val)}
      setArtworkPhotos={setArtworkPhotos}
      isArtworkModal={false}
      handleArtworkClick={() => logger.log('handleArtworkClick')}
      setToggleLogin={() => toggleArtworkFavourite(artworkId)}
      toggleLogin={null}
      isLoggedIn={isLoggedIn}
      excludedTax={artwork?.data?.excludedTax} setArtworkModal={undefined} exhibitionSlug={undefined} isAbout={false} isListView={false} handleExit={function (val: boolean): void {
        throw new Error("Function not implemented.");
      } }    />
  );
};

export default ArtworkPreview;
