import React, {useState, useEffect, useRef} from 'react';
import {Title2, Timeline, Paragraph} from '../Typography2';
import IconBtn from '../IconBtn';
import Button from '../Button';
import ModelViewer from '../3dArtworkViewer';
import Spinner from "../Spinner";
import {constructImageUrl, getShareURL, isHTML, isMobileDevice, isTypeMuseum, IsSafari} from '../../utils'
import {enquireArtwork, fetchArtworkAgent} from '../../services/api'
import {setRedirectPath} from "../../containers/Global/redux/actions";
import ImageZoom from '../ImageZoom';
import './style.scss';
import ShareBtn from "../ShareBtn";
import Pagination from "../Pagination";
import logger from '../../utils/logger';
import CurateEnquireSideBar from '../CurateEnquireSideBar';
import AuthenticationForm from '../AuthenticationForm'
import {useDispatch} from 'react-redux'
import { setIsMobileForm } from '../../containers/Global/redux/actions';
import PlayButtonCircleLight from "../../assets/icons/play-light.svg";

const ArtworksDetails = ({details, artworkId, profile, setCurrentChannel, setChat, exhibitionId, setEnquireMessage, isLoggedIn, onClose, isLoading, toggleFavourite, isFavourite, isChatOpen}) => {
        const [showEnqureSideBar, setShowEnqureSideBar] = useState(false)
        const [isEnquired, setEnquired] = useState(false);
        const [agentDetails, setAgentDetails] = useState(null);
        const [isZoomHover, setIsZoomHover] = useState(false);
        const [isMouseOver, setIsMouseOver] = useState(false);
        const [isMouseOverImg, setIsMouseOverImg] = useState(false);
        const [isImgLoading, setIsImgLoading] = useState(false);
        const [isImgFullyLoaded, setIsImgFullyLoaded] = useState(false);
        const [allPhotos, setAllPhotos] = useState([]);
        const [activePhoto, setActivePhoto] = useState(null);
        const artworkImgRef = useRef(null);
        const [toggleLogin , setToggleLogin] = useState(false);
        const videoRef = useRef(null);
        var interv = null;
        var interv2 = null;

        // @ts-ignore
        const dispatch = useDispatch();

        useEffect(() => {
            dispatch(setIsMobileForm(true));
            return () => {
                dispatch(setIsMobileForm(false));
            }
        }, [])

        const handleLeftClick = () => {
            const index = allPhotos && allPhotos.indexOf(activePhoto);
            setActivePhoto(
                {
                    imgUrl: '',
                    visited: false
                }
            )
            setTimeout(() => {
                if (index > 0) {
                    setActivePhoto(allPhotos[index - 1])
                    const i = index - 1;
                    setActivePhoto(allPhotos[i])
                } else {
                    setActivePhoto(allPhotos[allPhotos.length - 1]);
                    const i = allPhotos.length - 1;
                    setActivePhoto(allPhotos[i]);
                }
            }, 0)
        };

        const handleRightClick = () => {
            const index = allPhotos.indexOf(activePhoto);
            setActivePhoto(
                {
                    imgUrl: '',
                    visited: false
                }
            )
            setTimeout(() => {
                if (index >= 0 && (index + 1 < allPhotos.length)) {
                    setActivePhoto(allPhotos[index + 1])
                    const i = index + 1;
                    setActivePhoto(allPhotos[i])
                } else {
                    setActivePhoto(allPhotos[0]);
                    return;
                }
            }, 0)
        };

        const loadAgent = async (artworkId) => {
            try {
                if(isLoggedIn) {
                    const agentResp = await fetchArtworkAgent(artworkId)
                    setAgentDetails(agentResp);
                    logger.debug('agentResp = ', agentResp)
                }
            } catch (e) {
                // logger.error('err = ', e)
            }
        }

        const getHeading = () => {
            if (details.artist && details.artist.data.name) {
                return `${details.artist.data.name}, ${details.title}`
            } else {
                return details.title;
            }
        };

        const getDimensionsInCM = () => {
            return `${parseFloat(details.height).toFixed(1)} x ${parseFloat(details.width).toFixed(1)} x ${parseFloat(details.depth).toFixed(1)} cm`
        }

        const getDimensionsInIN = () => {
            return `${(parseFloat(details.height) * 0.393701).toFixed(1)} x ${(parseFloat(details.width) * 0.393701).toFixed(1)} x ${(parseFloat(details.depth) * 0.393701).toFixed(1)} in`
        }

        useEffect(() => {
            const newPhotos = [];
            details && details.photos && details.photos.data && details.photos.data.map((photo) => {
                if (photo.data) {
                    const {CFURL, name, signature} = photo.data;
                    const image = {
                        imgUrl: CFURL + '1920_' + name + signature,
                        visited: false
                    }
                    newPhotos.push(image);
                }
            })
            let mainImg = "dummyItem";
            const allImages = [mainImg, ...newPhotos];
            setAllPhotos(allImages);
            setActivePhoto(mainImg);
        }, [details])

        const {id} = details;

        const handleEnquire = async () => {
            setShowEnqureSideBar(true);
            return;
            // if (isLoggedIn) {
            //     if (agentDetails && agentDetails.id) {
            //         setChat(true);
            //         const sb = new SendBirdAction();
            //         logger.debug('sb = ', sb)

            //         const channelName = `${agentDetails.name} - ${details.organisation.data.name}`;
            //         const imageUrl = details.type === "2d" ? constructImageUrl(details.image,"300",false): details.type === "3d" ? constructImageUrl(details.thumbnail,"300",false): constructImageUrl(details.videoThumbnail,"300",false);
            //         const systemMsg = `@${details.artist.data.name}!,!${details.title}!,!${details.yearCreated ? details.yearCreated.toString() : ''}!,!${details.description}!,!${imageUrl}!,!${details.id}@`
            //         logger.debug(systemMsg)

            //         let channel = await sb.createGroupChannel([agentDetails.id.toString(), profile.id.toString()], channelName);
            //         setEnquireMessage(systemMsg);
            //         setCurrentChannel(channel);

            //     } else {
            //         let resp = await enquireArtwork({artworkId: id});
            //         logger.debug('resp = ', resp)
            //         if (resp) {
            //             setEnquired(true)
            //         }
            //     }
            // } else {
            //     setRedirectPath(location.pathname)
            //     history.push(`/login?${location.pathname}/artwork?${id}`)
            // }
        }

        useEffect(() => {
            if (isLoggedIn && !agentDetails && details.id) {
                loadAgent(details.id);
            }
        }, [details.id]);

        useEffect(() => {
            return () => {
                logger.debug('UNMOUNTING ARTWORK DETAIL POPUP')
                setEnquireMessage(null);
            }
        }, [])

        useEffect(() => {
            if (isMouseOver === false) {
                setIsZoomHover(false);
            }
        }, [isMouseOver]);

        const checkZoom = () => {
            if (isImgFullyLoaded === false) {
                if (document.querySelector('.iiz__zoom-img') && document.querySelector('.iiz__zoom-img').classList && document.querySelector('.iiz__zoom-img').classList?.contains('iiz__zoom-img--visible')) {
                    setIsImgLoading(false)
                    clearInterval(interv);
                    clearInterval(interv2);
                    setIsImgFullyLoaded(true);
                } else {
                    setIsZoomHover(true);
                    setIsImgLoading(true);
                }
            }
        };

        useEffect(() => {
            if (isImgFullyLoaded === false) {
                if (isMouseOverImg === true && isZoomHover === true) {
                    checkZoom();
                    interv = setInterval(() => {
                        checkZoom();
                    }, 200)
                    if (artworkImgRef && artworkImgRef.current) {
                        artworkImgRef.current.addEventListener('mousemove', function () {
                            logger.debug('yeay', document.querySelector('.iiz__zoom-img')?.classList?.contains('iiz__zoom-img--visible'));
                            checkZoom();
                        });
                    }
                } else {
                    setIsImgLoading(false);
                    clearInterval(interv);
                    clearInterval(interv2);
                }
            }

        }, [isMouseOverImg, isZoomHover]);

        const handleZoomImgClick = () => {
            if (isImgFullyLoaded === false) {
                setIsZoomHover(false);
                interv2 = setInterval(() => {
                    checkZoom();
                }, 200)
            }
        }

        const handleImgMouseLeave = () => {
            if (isImgFullyLoaded === false) {
                setIsMouseOverImg(false);
                setIsZoomHover(false)
                clearInterval(interv);
                clearInterval(interv2);
                setIsImgFullyLoaded(true);
            }
        }


        useEffect(() => {
            updateCanvasCoordinates();
            setTimeout(() => {
                updateCanvasCoordinates();
            }, 500)
        }, [isChatOpen])

        const updateCanvasCoordinates = () => {
            const artworkImg = artworkImgRef.current;
            const canvas = document.querySelector('canvas');
            if(canvas){
                canvas.width = artworkImg?.clientWidth;
                canvas.style.width = `${artworkImg?.clientWidth}px`;
                canvas.height = artworkImg?.clientHeight;
                canvas.style.height = `${artworkImg?.clientHeight}px`;
            }
        }

        // useEffect(()=>{
        //     if(toggleLogin)
        //     {
        //          var divElement = document.querySelector("aside.curate-aside");
        //          divElement?.classList?.add('toggle-login')
        //     }
        //     else
        //     {
        //      var divElement = document.querySelector("aside.curate-aside");
        //      divElement?.classList?.remove('toggle-login')
        //     }
        //  },[toggleLogin])

        const openFullscreen = () => {
            if (videoRef.current.paused) {
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              videoRef.current.controls;
              videoRef.current.play();
              // setTimeout(() => {
              if (videoRef.current.requestFullscreen) {
                videoRef.current.requestFullscreen();
              } else if (videoRef.current.webkitRequestFullscreen) {
                /* Safari */
                videoRef.current.webkitRequestFullscreen();
              } else if (videoRef.current.msRequestFullscreen) {
                /* IE11 */
                videoRef.current.msRequestFullscreen();
              }
              // }, 0)
            } else {
              videoRef.current.pause();
            }
          };

        const renderDescription = (description) => {
            if(isHTML(description)) {
                return <p className="paragraph artwork-details-scrollbox" dangerouslySetInnerHTML={{ __html: description }} />
            }
            else {
                return <Paragraph className='artwork-details-scrollbox' value={description} />
            }
          }

        return (
            <div className={`artwork-modal  ${isChatOpen && window.innerWidth > 1050 ? 'artwork-modal--open-chat' : ''}`}>
                <div className="close_wrapper">
                    <IconBtn type="cross" onClick={onClose}/>
                </div>
                <div
                    className={`artworks-details  ${isChatOpen && window.innerWidth > 1050 ? 'artworks-details--open-chat' : ''}`}>
                    {
                        <div className="fade-in-artwork">
                            <div className={`artworks-details-content ${showEnqureSideBar ? "expand-sidebar": ""} ${details.type === "video" ? 'wide' : ''}`}>
                                <div className={`artwork-img-wrapper ${(isZoomHover && isMouseOver) ? 'cursor-zoom' : ''}`}
                                     onMouseEnter={() => setIsMouseOver(true)}
                                     onMouseLeave={() => setIsMouseOver(false)}>
                                    {
                                        !isLoading ?
                                            <>
                                                <div
                                                    className={`artwork-img  ${(isImgFullyLoaded === false && isImgLoading && isZoomHover) ? 'cursor-loader' : ''} ${(details && details && details.photos && details.photos.data && details.photos.data.length > 0) ? '' : 'no-pagination'}`}
                                                    ref={artworkImgRef}
                                                    onClick={() => handleZoomImgClick()}
                                                    onMouseEnter={() => setIsMouseOverImg(true)}
                                                    onMouseLeave={() => handleImgMouseLeave()}>

                                                    {activePhoto !== "dummyItem" ?
                                                        <>
                                                            <ImageZoom
                                                                className={'main2d-image '}
                                                                zoomType={(isZoomHover && isMouseOver) ? 'hover' : 'click'}
                                                                key={activePhoto.imgUrl}
                                                                src={activePhoto.imgUrl}
                                                                zoomSrc={activePhoto && activePhoto.imgUrl && activePhoto.imgUrl.replace('1920_', '1920_')}
                                                            />
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                details.type === "2d" && details.image && details.image.data &&
                                                                <>
                                                                  <ImageZoom
                                                                    className={'main2d-image '}
                                                                    zoomType={(isZoomHover && isMouseOver) ? 'hover' : 'click'}
                                                                    src={details.image && details.image.data && constructImageUrl(details.image,"1920",false)}
                                                                    zoomSrc={details.image && details.image.data && constructImageUrl(details.image,"1920",false)}
                                                                    />
                                                                </>
                                                                // TODO: Cloudfront images not loading here in SAFARI only
                                                            }
                                                            {
                                                                details.type === "3d" &&
                                                                <ModelViewer id={details.id} onFloor={details.onFloor}
                                                                             modelUrl={details && details.thumbnailUrl && details.thumbnailUrl.signedUrl}/>
                                                            }
                                                            {
                                                                details.type === "video" &&
                                                                <DisabledVideoDummy
                                                                    classNames={"thumbnail-view artwork-details-modal-video"}
                                                                    videoRef={videoRef}
                                                                    onClick={openFullscreen}
                                                                    poster={details && details.videoThumbnail && details.videoThumbnail.data && constructImageUrl(details.videoThumbnail,(isMobileDevice() ? "480" : "1080"))}
                                                                    src={
                                                                        IsSafari() || isMobileDevice()
                                                                        ? details.video.data.signedMobileMedia
                                                                        : details.video.data.signedDesktopMedia
                                                                    }
                                                                />
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </>
                                            : <Spinner/>
                                    }
                                    {(!isLoading && details && details && details.photos && details.photos.data && details.photos.data.length > 0) &&
                                    <Pagination items={[...allPhotos]} handlePrevious={handleLeftClick}
                                                handleNext={handleRightClick}
                                                handleDotClick={(val) => setActivePhoto(allPhotos[val])}
                                                active={allPhotos && allPhotos.indexOf(activePhoto)}/>
                                    }
                                </div>
                                    {
                                        !showEnqureSideBar ?
                                        <div className="details" style={{}}>
                                            {toggleLogin && !isLoggedIn &&
                                                <AuthenticationForm
                                                    type='login'
                                                    isLoggedIn={isLoggedIn}
                                                    onCloseForm={() => setToggleLogin(false)}
                                                    toggleLogin={toggleLogin}
                                                    setToggleLogin={setToggleLogin}
                                                    fromExhLoading={true}
                                                    artworkDetailsEnquire
                                                />
                                            }
                                            {
                                                !isLoading ?
                                                    <>
                                                        <div className="details__header-wrapper">
                                                            <div>
                                                                <Title2 value={getHeading()}/>
                                                                <Paragraph className="gray" value={details.medium}/>
                                                            </div>
                                                            <IconBtn type="cross" onClick={onClose}/>
                                                        </div>
                                                        <Timeline value={`${getDimensionsInCM()}  /  ${getDimensionsInIN()}`}/>
                                                        <div className='details-btns-wrapper action-icons'>
                                                            {details.price && Number(details.price) !== 0 && <><Button className='button__dark'
                                                                                        value={details.price + ' ' + (details.currency || '')}/></>}
                                                            {isTypeMuseum(details.organisation && details.organisation.data && details.organisation.data.type) &&
                                                            <Button
                                                            value={isEnquired ? 'Enquiry sent' : 'Enquire'}
                                                            className='button__bright'
                                                            iconType="next"
                                                            onClick={handleEnquire}
                                                            />}
                                                            <ShareBtn up chainLink={`https://app.vortic.io/artwork/${id}`} shareUrl={getShareURL('artwork', id)}/>
                                                            <IconBtn type={isFavourite ? "favouriteOn2" : "favourite2"}
                                                                    onClick={() => {
                                                                        isLoggedIn ?
                                                                        toggleFavourite(id)
                                                                        : setToggleLogin(true)
                                                                    }}
                                                            />
                                                        </div>

                                                        {!!details.description && !!details.description.trim() && renderDescription(details.description || '')}
                                                    </>
                                                    :
                                                    <Spinner/>
                                            }
                                        </div>
                                        :
                                        <CurateEnquireSideBar
                                            activeArtworkId={artworkId}
                                            // isArtworkDetailsPage
                                            galleryName={details.organisation && details.organisation.data && details.organisation.data.name}
                                            handleCancel={()=>{ setShowEnqureSideBar(false) }}
                                        />
                                    }

                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }

function DisabledVideoDummy({
    videoRef,
    src,
    onClick,
    poster,
    classNames = "",
  }) {
    useEffect(() => {
      const prefixes = ["", "moz", "webkit", "ms"];

      prefixes.forEach(function (prefix) {
        document.addEventListener(prefix + "fullscreenchange", function (e) {
          const fullscreenelement =
            document.fullscreenElement /* Standard syntax */ ||
            document[
              "webkitFullscreenElement"
            ] /* Chrome, Safari and Opera syntax */ ||
            document["mozFullScreenElement"] /* Firefox syntax */ ||
            document["msFullscreenElement"]; /* IE/Edge syntax */
          if (!fullscreenelement) {
            videoRef.current.pause();
          }
        });
      });

      return () => {};
    }, []);

    return (
      <div className={`disabled-video-dummy ${classNames} `} onClick={onClick}>
        <video
          controls={false}
          autoPlay={false}
          ref={videoRef}
          poster={poster}
          src={src}
        />
        <img className="poster" src={poster} alt="poster" />
        <div className="play-button">
          <img src={PlayButtonCircleLight} alt={"PlayButtonCircleLight"} />
        </div>
      </div>
    );
  }


export default ArtworksDetails;
