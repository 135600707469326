// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import AudioWrapper from "../../components/AudioWrapper";
import CurateArtworkDetails from "../../components/CurateArtworkDetails";
import rudderStackEvents from "../../services/rudderstack-events";
import { getShareURL, isMobileDevice } from "../../utils";
import logger from "../../utils/logger";
import { IArtworkDetails } from "./types";

const ArtworksAudioDetails: React.FC<IArtworkDetails> = ({
  // location,
  showEnquireSideBar,
  isForSale,
  isNotMuseum,
  yearCreated,
  price,
  artworkName,
  artistName,
  isFavourite,
  artworkId,
  exhibitiondId,
  dimensionsInCM,
  dimensionsInIN,
  description,
  saveToCollection,
  onClose,
  handleArtworkDescription,
  isLoggedIn,
  resizeCurateCanvas,
  setCurateAudio,
  isSidebar,
  artworkCatalogueNumber,
  audioDetailsPopup,
  setArtworkModal,
  isArtworkModal,
  isActive,
  setActive,
  getCurateHeight,
  currency,
  viewportWidth,
  excludedTax,
  priceUnavailable,
  isMuseumType,
  toggleModalAuth,
  listview,
  artworkMedium,
  isPlaying,
  togglePlaying,
  artworkPhotos,
  artworkAudioUrl,
  totalDuration,
  currentTime,
  setCurrentTime,
  isArt,
  showDescription, 
  setShowDescription
}) => {
  const [isEnquired, setEnquired] = useState(false);
  // const [showDescription, setShowDescription] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const artworkDescriptionRef = useRef(null);
  // const [isAnime, setIsAnime] = useState(false);
  // const [audioTotalTime, setTotalTime] = useState(0);
  // const [audioPlaying, setPlaying] = useState(false);
  // const [audioUrl, setAudioUrl] = useState('');
  // const [audioCurrentTime, setCurrentTime] = useState(0);
  // const [artworkPhotos, setArtworkPhotos] = useState([]);

  useEffect(() => {
    resizeCurateCanvas();
  }, [showDetails]);

  // I don't think this is of any use anymore
  // useEffect(() => {
  //   if (location.pathname && location.pathname.includes("curate")) {
  //     document.querySelector("body") &&
  //       document.querySelector("body").classList.add("scrollbar-hidden");
  //     logger.debug("MOUNTING AUDIO DETAILS");
  //   }

  //   return () => {
  //     logger.debug("UN MOUNTING AUDIO DETAILS");
  //     document.querySelector("body") &&
  //       document.querySelector("body").classList.remove("scrollbar-hidden");
  //   };
  // }, [location.pathname]);

  useEffect(() => {
    const descriptionBoxHeight =
      artworkDescriptionRef &&
      artworkDescriptionRef.current &&
      artworkDescriptionRef.current.clientHeight;
    handleArtworkDescription(showDescription, descriptionBoxHeight);
  }, [showDescription]);

  useEffect(() => {
    resetEverything();
  }, [artworkId]);

  const resetEverything = () => {
    setEnquired(false);
  };

  const handleEnquire = async () => {
    rudderStackEvents.onArtworkEnquiry(artworkId)
    showEnquireSideBar();
  };

  const serialize = (obj) => {
    var str = [];
    for (var p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    }
    return str.join("&");
  };

  const getCurateArtworkAddress = () => {
    const curateLink = window.location.href.slice(
      0,
      window.location.href.indexOf("?") + 1
    );
    // const curateLink = window.location.href;
    var hash = window.location.search;
    var array = hash.substring(1).split("&");
    var values,
      form_data = {};

    for (var i = 0; i < array.length; i += 1) {
      values = array[i].split("=");
      form_data[values[0]] = values[1];
    }

    delete form_data["eventId"];

    return `${curateLink}${serialize(form_data)}`;
  };

  return (
    <div className="artworks-audio-details">
      <CurateArtworkDetails
        artistName={artistName}
        artworkName={artworkName}
        yearCreated={yearCreated}
        isArtworkListedForSale={isForSale && isNotMuseum}
        priceUnavailable={priceUnavailable}
        isMuseumType={isMuseumType}
        setArtworkModal={setArtworkModal}
        isArtworkModal={isArtworkModal}
        artworkPhotos={artworkPhotos}
        dimensionsInIN={dimensionsInIN}
        dimensionsInCM={dimensionsInCM}
        onClose={onClose}
        isEnquired={isEnquired}
        isSidebar={isSidebar}
        isActive={isActive}
        handleEnquire={handleEnquire}
        audioDetailsPopup={audioDetailsPopup}
        description={description}
        playing={isPlaying}
        chainLink={getCurateArtworkAddress()}
        shareUrl={getShareURL("artwork", artworkId)}
        getCurateHeight={getCurateHeight}
        isFavourite={isFavourite}
        onFavourite={() => {
          isLoggedIn ? saveToCollection() : toggleModalAuth(true);
        }}
        price={price}
        currency={currency}
        artworkId={artworkId}
        artworkMedium={artworkMedium}
        artworkCatalogueNumber={artworkCatalogueNumber}
        viewportWidth={viewportWidth}
        excludedTax={excludedTax}
        listview={listview}
        // Audio stuff
        isPlaying={isPlaying}
        artworkAudioUrl={artworkAudioUrl}
        currentTime={currentTime}
        totalDuration={totalDuration}
        setCurrentTime={setCurrentTime}
        setIsPlaying={togglePlaying}
        isArt={isArt}
        showDescription={showDescription}
        setShowDescription={setShowDescription}
      />
    </div>
  );
};

export default ArtworksAudioDetails;
