import {
    GET_PROFILE_DATA,
    UPDATE_PROFILE_IMAGE,
    UPDATE_PROFILE_DATA_SUCCESS,
    GET_UPDATE_PROFILE_DATA_SUCCESS,
    GET_PROFILE_NOTIFICATION_DATA,
    GET_PROFILE_NOTIFICATION_DATA_SUCCESS,
    UPDATE_PROFILE_NOTIFICATION_SUCCESS
} from './actionTypes'

const initialState = {
    profileData: {},
    uploadMeta: {},
    profileNotificationData:{}
}

function GetProfile(state = initialState, action) {
    switch (action.type) {
        case GET_PROFILE_DATA:
            return {...state, profileData: action.payload.data, uploadMeta: action.payload.refs};
        case GET_PROFILE_NOTIFICATION_DATA_SUCCESS:
            return {...state, profileNotificationData: action.payload.data};
        case UPDATE_PROFILE_DATA_SUCCESS:
            return {...state, profileData: action.payload}
        case UPDATE_PROFILE_NOTIFICATION_SUCCESS:
            return {...state, profileNotificationData: action.payload}
        case GET_UPDATE_PROFILE_DATA_SUCCESS:
            return {
                ...state,
                profileData: action.payload.data,
                uploadMeta: action.payload.refs
            };
        case UPDATE_PROFILE_IMAGE:
            return {
                ...state,
                profileData: {
                    ...state.profileData,
                    image: action.payload
                }
            }

        default:
            return state
    }
}

export default GetProfile
