import React, { useRef, useEffect, useState } from "react";
import AboutVideo from "../AboutVideo";
import { IsSafari, isMobileDevice, constructImageUrl, constructVideoUrlForPoster } from "../../utils";
import "./style.scss";

const ListviewMedia = ({ video, image, imageLoaded, setImageLoaded, thumbnail, isVideoEnabled }) => {
  const videoRef = useRef(null);

  const openFullscreen = () => {
    if (videoRef.current.paused) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      videoRef.current.controls;
      videoRef.current.play();
      // setTimeout(() => {
      if (videoRef.current.requestFullscreen) {
        videoRef.current.requestFullscreen();
      } else if (videoRef.current.webkitRequestFullscreen) {
        /* Safari */
        videoRef.current.webkitRequestFullscreen();
      } else if (videoRef.current.msRequestFullscreen) {
        /* IE11 */
        videoRef.current.msRequestFullscreen();
      }
      // }, 0)
    } else {
      videoRef.current.pause();
    }
  };

  const handleListviewClick = (e) => {
    if(video?.data && document.fullscreenElement !== videoRef.current) {
      e.stopPropagation();
      openFullscreen();
    }
  }

  useEffect(() => {
    if(video?.data) {
      const videoWrapper = document.querySelector('.about-video');
      const videoElm = videoWrapper.querySelector('video');
      var img = new Image();
      img.src = videoElm.getAttribute('poster');
      img.onload = function(){
        setImageLoaded(true);
      }
    } else { 
      var img = new Image();
      img.src = constructImageUrl(image, "1080");
      img.onload = function() {
        setImageLoaded(true);
      }
    }
  }, [])

  const getVideoSource = (videoData) => {
    const isOnMobileDevice = IsSafari() || isMobileDevice() || window.innerWidth <= 768;
    if(isOnMobileDevice) {
      const mobileVideoSource = constructVideoUrlForPoster(videoData);
      return [
        mobileVideoSource
      ]
    }

    return [
      constructVideoUrlForPoster(videoData, '4000'),
      constructVideoUrlForPoster(videoData, '1080'),
      constructVideoUrlForPoster(videoData, '720'),
    ]
  }


  return (
    <div className={`listview-media ${imageLoaded ? 'listview-media--active' : ''} ${video?.data ? 'listview-media--video' : ''}`} onClick={(e) => handleListviewClick(e)}>
      <div className="listview-media-content">
        {(video?.data && isVideoEnabled) ? (
          <AboutVideo
            listview
            videoRef={videoRef}
            onClick={(e) => {}}
            poster={thumbnail ? constructImageUrl(thumbnail, (isMobileDevice() ? "1280" : "1920")) : video?.data?.signedVideoThumbnail}
            src={
              getVideoSource(video)
            }
          />
        ) : (
          image?.data && (
            <div className="listview-banner-img">
              <img src={(isVideoEnabled && thumbnail) ? constructImageUrl(thumbnail, (isMobileDevice() ? "1280" : "1920")) : constructImageUrl(image, (isMobileDevice() ? "1280" : "1920"))} alt="img" />
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ListviewMedia;
