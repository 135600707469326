import React, {useEffect, useState} from 'react'
import {Link, useNavigate, useLocation} from 'react-router-dom'
import Logo from '../../assets/icons/bright-logo2.svg';
import VLogoBright from '../../assets/icons/bright-v.svg';
import {IHeaderProps} from './types';
import ProfilePlaceholder from '../../assets/icons/profile-placeholder.svg';
import {P1, SubTitle} from '../Typography';
import MenuItem from '../MenuItem';
import Button from '../Button';
import {logout} from '../../utils'
import IconBtn from '../IconBtn';
// import ConversationIcon from "./ConversationIcon";
import Cross from '../../assets/icons/close-small.svg';
import Search from '../../assets/icons/small-search.svg';
import rudderstackEvents from "../../services/rudderstack-events";

const Header: React.FC<IHeaderProps> = ({isSidebar, mokeHeadersDelay, isLoggedIn,routeName,changeSearch,searchQuery,profileData ,isHome,isProfile,isAuthenticationPage,setChat,isSearchPage,isLoginForgot,isChat, isCollaborationsUntitled}) => {

    const navigate = useNavigate();
    const location = useLocation();
    const [nav, setNav] = useState(false);
    const [navBright, setNavBright] = useState(false);
    const [moveToTop, setMoveToTop] = useState(false)
    const [isSearchActive, setIsSearchActive] = useState(false)


    useEffect(() => {
        const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
        // window.addEventListener('scroll', () => {
        //     setMoveToTop(!(window.pageYOffset < vh));
        // })
    }, []);

    const handleSearchChange = (value) => {
        if (value.length > 2 && location.pathname !== "/search") {
            navigate('/search')
        }
        changeSearch(value);
        if(value.length > 2) {
            rudderstackEvents.onUseManNav({menuItem: 'search', searchItem: value})
        }
        if(value.length === 0) {
            navigate('/discover')
        }
    };

    const handleConversation = () => {
        if (isChat === false && isSidebar === false) {
            setChat(true);
        } else {
            setChat(false);
        }
        setNavBright(false);
    };

    const isLinkActive = (route) => {
        return routeName === route ? "active" : '';
    };

    const handleResize=()=>{
        if (!isHome && navBright&& window.innerWidth<980){
            document.querySelector('html').style.overflow='initial';
            document.getElementById('body').style.overflow='hidden';
            // let zoom =document.getElementsByClassName('zoom')
             let elms = document.getElementsByClassName("zoom");

            for(let i = 0; i < elms.length; i++) {
                elms[i].classList.add('display-none');
            }
        }else {
            document.querySelector('html').style.overflow='unset';
            document.getElementById('body').style.overflow='unset';
            let elms = document.getElementsByClassName("zoom");

            for(let i = 0; i < elms.length; i++) {
                if (elms[i].classList.contains('display-none')){
                    elms[i].classList.remove('display-none');
                }
            }
        }
    }
    window.addEventListener('resize', ()=>{
        handleResize()
    });


    useEffect(() => {
        handleResize()
    })
    const matchPathname = (pathname) => {
        const regex = /(exhibitions)+\/.+/gm;
        return !!pathname.match(regex);
    }

    const sendRudderStackEvent = (menuItem) => {
        rudderstackEvents.onUseManNav({menuItem})
    }

    return (
        <>
            <header
                className={`header ${location.pathname === '/' ? (mokeHeadersDelay ? '' : 'header--hidden') : ''} ${!isHome ? 'header--bright' : 'header--default'} ${isHome && nav ? 'active' : ''} ${!isHome && navBright ? 'active' : ''} ${isAuthenticationPage ? 'header--authentication' : ''} ${isSearchPage ? 'search-page' : ''} ${isLoginForgot ? 'login-forgot' : ''} ${isLoggedIn ? 'header--logged-in' : ''} ${location.pathname.includes('/artists/') ? 'header--artists-artwork' : ''} ${location.pathname === '/collaborations/vortic-curated' ? 'header--collaborations-new-page' : ''} ${isCollaborationsUntitled ? 'header--untitled-art' : ''}`}>
                {/* className={`header ${history.location.pathname === '/' ? (mokeHeadersDelay ? '' : 'header--hidden') : ''} ${!isHome ? 'header--bright' : 'header--default'} ${isHome && nav ? 'active' : ''} ${!isHome && navBright ? 'active' : ''} ${isProfile ? 'header--gray' : ''} ${isAuthenticationPage ? 'header--authentication' : ''} ${isSearchPage ? 'search-page' : ''} ${isLoginForgot ? 'login-forgot' : ''} ${isLoggedIn ? 'header--logged-in' : ''} ${history.location.pathname.includes('/artists/') ? 'header--artists-artwork' : ''} ${history.location.pathname === '/collaborations/vortic-curated' ? 'header--collaborations-new-page' : ''} ${isCollaborationsUntitled ? 'header--untitled-art' : ''}`}> */}

                {
                    isHome === true ? (
                        <>
                            <Link to='/' className='header__logo'>
                                <img src={Logo} alt={"Logo"} width={37} height={39}/>
                            </Link>
                            <div className="header--flex">
                                <nav className='header__nav'>
                                    <ul>
                                        <li onClick={() => sendRudderStackEvent('exhibitions')} className={isLinkActive('/exhibitions')}>
                                            <Link className="active" to="/exhibitions">
                                                <SubTitle className='nav-subtitle' value='Exhibitions'/>
                                            </Link>
                                        </li>
                                        <li onClick={() => sendRudderStackEvent('museums-&-public-galleries')} className={isLinkActive('/exhibitions')}>
                                            <Link to="/Museums & Public Galleries">
                                                <SubTitle className='nav-subtitle' value='Museums & Public Galleries'/>
                                            </Link>
                                        </li>
                                        <li onClick={() => sendRudderStackEvent('galleries')} className={isLinkActive('/exhibitions')}>
                                            <Link to="/galleries">
                                                <SubTitle className='nav-subtitle' value='Galleries'/>
                                            </Link>
                                        </li>
                                        {/* <li onClick={() => sendRudderStackEvent('collaborations')} className={isLinkActive('/exhibitions')}>
                                            <Link to="/collaborations/untitled">
                                                <SubTitle className='nav-subtitle' value='Collaborations'/>
                                            </Link>
                                        </li> */}
                                    </ul>
                                    {
                                        isLoggedIn === false && (
                                            <>
                                                <div className="vl">
                                                    <span/>
                                                </div>
                                                <ul className='btn'>
                                                    <li onClick={() => sendRudderStackEvent('login')} className={isLinkActive('/exhibitions')}>
                                                        <Link to="/login">
                                                            <SubTitle className='nav-subtitle' value='Log in'/>
                                                        </Link>
                                                    </li>
                                                    <li onClick={() => sendRudderStackEvent('signup')} className={isLinkActive('/exhibitions')}>
                                                        <Link to="/signup">
                                                            <SubTitle className='nav-subtitle' value='Sign up'/>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </>
                                        )
                                    }
                                </nav>

                                {isLoggedIn === true && location.pathname === '/signup' &&
                                <div className="profile-pic_wrapper">
                                  <div onClick={() => sendRudderStackEvent('profile')} className={`profile-pic ${profileData.image ? '' : ''}`}>
                                    <Link to="/profile">
                                      <img
                                        src={profileData.image ? profileData.image.data.signedUrl120 : ProfilePlaceholder}
                                        alt="Profile Pic"/>
                                    </Link>
                                  </div>
                                  <div className="logout-dropdown">
                                    <div onClick={() => logout(profileData?.id)}>
                                      <P1 value="Log out"/>
                                    </div>
                                  </div>
                                </div>
                                }
                            </div>

                        </>
                    ) : (
                        <>
                            {!isSearchActive && <div className="header--flex">
                                <Link to={'/'} className='header__logo'>
                                    <img src={VLogoBright} alt="Logo" height={14} onClick={() => setNav(false)}/>
                                </Link>
                                <label
                                    className={`search_bar ${searchQuery.length ? 'active' : ''}`}>
                                    <span className='menu-item'>Search</span>
                                    <input value={searchQuery} onChange={(e) => handleSearchChange(e.target.value)}
                                           autoFocus={searchQuery.length > 0}
                                           type="text"/>
                                </label>
                            </div>}
                            <div className={'header__middle' + (isSearchActive ? ' active' : '')}>
                                <button className='search' onClick={() => setIsSearchActive(true)}>
                                        <img src={Search} alt="search" />
                                </button>
                                <label
                                    className={`search_bar ${searchQuery.length ? 'active' : ''}`}>
                                    <MenuItem value="Search"/>
                                    <input value={searchQuery} onChange={(e) => handleSearchChange(e.target.value)}
                                        autoFocus={searchQuery.length > 0}
                                        type="text"/>
                                </label>
                                <button className='close' onClick={() => setIsSearchActive(false)}>
                                    <img src={Cross} alt="close" />
                                </button>
                            </div>
                            <div className="header--flex">
                                <nav className='header__nav'>
                                    <ul>
                                        <li/>
                                        <li onClick={() => {
                                            setNavBright(false);
                                            sendRudderStackEvent('discover')
                                        }}>
                                            <MenuItem value='Discover' to='/discover'
                                                      className={isLinkActive('/discover') || isLinkActive('/')}/>
                                        </li>
                                        <li onClick={() => {
                                            setNavBright(false);
                                            sendRudderStackEvent('exhibitions')
                                        }}>
                                            <MenuItem value='Exhibitions' to='/exhibitions'
                                                      className={isLinkActive('/exhibitions')}/>
                                        </li>
                                        <li onClick={() => {
                                            setNavBright(false);
                                            sendRudderStackEvent('museums-&-public-galleries')
                                        }}>
                                            <MenuItem value='Museums & Public Galleries' to='/museums-&-public-galleries'
                                                      className={isLinkActive('/museums-&-public-galleries')}/>
                                        </li>
                                        <li onClick={() => {
                                            setNavBright(false);
                                            sendRudderStackEvent('galleries')
                                        }}>
                                            <MenuItem value='Galleries' to='/galleries'
                                                      className={isLinkActive('/galleries')}/>
                                        </li>
                                        {/* <li onClick={() => {
                                            setNavBright(false);
                                            sendRudderStackEvent('collaborations')
                                        }}>
                                            <MenuItem value='Collaborations' to='/collaborations/untitled'
                                                      className={isLinkActive('/collaborations/vortic-curated') || isLinkActive('/collaborations/frieze') || isLinkActive('/collaborations/untitled')}
                                            />
                                        </li> */}
                                        <li onClick={() => {
                                            setNavBright(false);
                                            sendRudderStackEvent('profile')
                                        }}
                                            className={!isLoggedIn ? 'not-logged-in' : ''}>
                                            {
                                                // !isLoggedIn ?
                                                !isLoggedIn &&
                                                    // <Button onClick={() => sendRudderStackEvent('login')} value='Login' className='button__bright' to='/login'/> :
                                                    <IconBtn type="userProfile" to='/login'/>
                                                    // :
                                                    // <div className={`logged-in-chat ${isChat ? 'active' : ''}`}
                                                    //      onClick={() => handleConversation()}><ConversationIcon
                                                    //     isLoggedIn={isLoggedIn}
                                                    //     handleConversation={handleConversation}
                                                    // /></div>
                                            }
                                            {
                                                !isLoggedIn ?
                                                    <Button  onClick={() => sendRudderStackEvent('signup')} value='Sign Up' className='button__bright'
                                                            type='secondary'
                                                            to='/signup'/> :
                                                    <div className={`${isLinkActive('/profile')}`}>
                                                        <IconBtn type="userProfile" to='/profile'/>
                                                    </div>
                                            }
                                        </li>

                                    </ul>
                                </nav>

                                <div className="mobile-btn">
                                    <IconBtn onClick={() => setNavBright(!navBright)}
                                             noHover={true}
                                             type={navBright ? "cross" : 'menuLight'}/>
                                </div>
                            </div>
                        </>
                    )
                }
            </header>
        </>
    )
};

export default Header
