import React from 'react';
import IconBtn from '../../IconBtn';
import Button from '../../Button';
import "./ViewGalleryNav.scss";

const ViewGalleryNav = () => {
    return (
        <div className='island-view-gallery-nav'>
            <IconBtn type="returnIcon" large />
            <Button
                value='Visit exhibition'
                iconType='redirectDark'
                bright
                onClick={() => console.log('Clicked!')}
            />
        </div>
    );
}

export default ViewGalleryNav;