export const VORTIC_CURATED_GROUP_ID = 44;
export const VORTIC_FRIEZE_GROUP_ID = 48;

export const VORTIC_CURATED = 'vortic-curated';
export const FRIEZE = 'frieze';
export const UNTITLED = 'untitled';

export const UNTITLED_DESCRIPTION = "The 2022 Untitled Art Nest Sector exhibition has now ended. As Untitled Art 2022 Digital Partners, Vortic presented a special 3D and VR extension of Nest Sector galleries, furthering our commitment to increasing accessibility to the art world."
export const FRIEZE_DESCRIPTION = "Vortic partnered with Frieze for its New York, Seoul, London (2022) and Los Angeles (2023) editions to bring its Viewing Room to life. Thanks to Vortic cutting-edge technology, participating galleries were able to create immersive 3D and VR viewing experiences of their booths, available to anyone, anywhere in the world."

