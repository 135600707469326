import React from 'react'
import ListviewHead from '../ListviewHead';
import ListviewMedia from '../ListviewMedia';
import "./style.scss";
import Logo from "../../assets/icons/vortic-brigth-logo.svg";
import { Anchor } from '../Typography2';

const ListviewBanner = ({ galleryName, exhibitionName, handleExploreClick, image, video, imageLoaded, setImageLoaded, headeHeight, thumbnail, isVideoEnabled, isArtistVisible, artists }) => {
  return (
    <div className={`listview-banner ${imageLoaded ? 'listview-banner--active' : ''}`}>
      <Anchor to="/discover" isLocalLink className="logo-mobile">
        <img src={Logo} alt='logo' />
      </Anchor>
      <ListviewHead isArtistVisible={isArtistVisible} galleryName={galleryName} exhibitionName={exhibitionName} handleExploreClick={handleExploreClick} headeHeight={headeHeight} artists={artists} />
      <ListviewMedia thumbnail={thumbnail} image={image} video={video} imageLoaded={imageLoaded} setImageLoaded={setImageLoaded} isVideoEnabled={isVideoEnabled}/>
    </div>
  )
}

export default ListviewBanner;