import Galleries from './Galleries'
import { connect } from 'react-redux'
import { fetchGalleriesInit, updateSortBy, setGalleriesLoader, resetGalleries } from './redux/actions'
import { fetchCollectiveMetaInit } from '../Collectives/redux/actions'

const mapStatesToProps = ({gallery, global, collective}) => {
    return {
        gallery,
        globalProps: global,
        collectiveMeta: collective.meta
    };
};

const mapDispatchToProps = dispatch => ({
    fetchGalleriesInit: (page, filter, isGroup, type) => dispatch(fetchGalleriesInit(page, filter, isGroup, type)),
    updateSortBy: (sortBy) => dispatch(updateSortBy(sortBy)),
    fetchCollectiveMetaInit: () => dispatch(fetchCollectiveMetaInit()),
    setGalleriesLoader: (value) => dispatch(setGalleriesLoader(value)),
    resetGalleries: () => dispatch(resetGalleries()),
});

export default connect(mapStatesToProps, mapDispatchToProps)(Galleries);
