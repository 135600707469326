import React from 'react';
import "./style.scss";
import Logo from '../../assets/icons/wordmark-logo.svg';
import MobileLogo from '../../assets/icons/listview-mobile-logo.svg';
import { Anchor } from '../Typography2';

const ListviewFooter = () => {
  return (
    <footer className='listview__footer'>
        <Anchor to="/discover" isLocalLink>
            <img className='listview-desktop-logo' src={Logo} alt="logo" />
            <img className='listview-mobile-logo' src={MobileLogo} alt="logo" />
        </Anchor>
    </footer>
  )
}

export default ListviewFooter;