import { FETCH_COLLECTIVES_INIT, FETCH_COLLECTIVES_SUCCESS, FETCH_COLLECTIVES_META_INIT, FETCH_COLLECTIVES_META_SUCCESS } from './actionTypes'

export function fetchCollectiveInit (page) {
  return {
    type: FETCH_COLLECTIVES_INIT,
    payload: page
  }
}
export function fetchCollectiveSuccess (data) {
  return {
    type: FETCH_COLLECTIVES_SUCCESS,
    payload: data
  }
}

export function fetchCollectiveMetaInit () {
  return {
    type: FETCH_COLLECTIVES_META_INIT,
  }
}
export function fetchCollectiveMetaSuccess (data) {
  return {
    type: FETCH_COLLECTIVES_META_SUCCESS,
    payload: data
  }
}
