import React, { useState } from "react";
import { constructImageUrl, formatePrice, toInches, isMobileDevice, getArtworkStateStatusClass } from "../../utils";
import { Paragraph } from "../Typography2";
import { IArtworkCard } from './types';
import { isTypeMuseum, getArtworkStateStatus } from '../../utils';
import "./style.scss";

const ArtworkCard:React.FC<IArtworkCard> = ({
  handleArtworkClick,
  id,
  type,
  image,
  thumbnail,
  videoThumbnail,
  title,
  yearCreated,
                                              salesStatus,
  name,
  medium,
  price,
  currency,
  height,
  width,
  depth,
  excludedTax,
  galleryType,
  isForSale,
                                              isMuseumType
}) => {

  const [dimensionsUnit, setDimensionsUnit] = useState("cm");

  const dimensionsInCM = () => {
    return `${parseFloat(height).toFixed(1)} x ${parseFloat(width).toFixed(1)} x ${parseFloat(depth).toFixed(1)} cm`;
  };

  const dimensionsInIN = () => {
    return `${toInches(height)} x ${toInches(width)} x ${toInches(depth)} in`;
  };


  const handleDimensionsToggle = () => {
    if (dimensionsUnit === "cm") {
      setDimensionsUnit("in");
    } else {
      setDimensionsUnit("cm");
    }
  };

  const screenWidth = isMobileDevice() ? "480" : "1080";

  const getUrl = (type) => {
    switch(type) {
      case "2d":
        return constructImageUrl(image, screenWidth);
      case "3d":
        return constructImageUrl(thumbnail, screenWidth);
      default:
        return constructImageUrl(videoThumbnail, screenWidth)
    }
  }

  const dimensions = dimensionsUnit === "in" ? dimensionsInIN() : dimensionsInCM();

  const unit = dimensionsUnit === "in" ? "cm" : "in";

  // @ts-ignore
  const priceUnavailable = !isForSale || salesStatus === "price on application" || salesStatus === "sold" || salesStatus === "reserved";


  return (
    <div className="box box--secondary" key={id}>
      <div onClick={() => handleArtworkClick(id)} className="img">
        <img src={getUrl(type)} alt="artwork img" />
      </div>
      <div className="details">
        <Paragraph onClick={() => handleArtworkClick(id)} className="title ">
          <>
            {title}
            <span>{yearCreated}</span>
          </>
        </Paragraph>
        <Paragraph className="bold artist" value={name} />
        <div className="dimensions">
          <div className="property property--text">
            <Paragraph>
              <>
                {`${dimensions} / `}
                {
                  <button
                    className="gray unit-btn under-line-list-view"
                    onClick={handleDimensionsToggle}
                  >
                  {unit}
                  </button>
                }
              </>
            </Paragraph>
          </div>
          <div className="property property--text">
            <Paragraph value={medium} />
          </div>
        </div>
        {(!isMuseumType && isForSale) && (
          <div className="property price-status">
            <div className={`dot ${getArtworkStateStatusClass(salesStatus)}`} />
            <label>{salesStatus}</label>
            {!priceUnavailable && !!price && Number(price) !== 0 && <Paragraph value={Number(price) && formatePrice(price, currency)} />}
            {!priceUnavailable && !!price && Number(price) !== 0 && !!excludedTax && <Paragraph className='dark-gray' value="(ex tax)" />}
          </div>
        )}
      </div>
    </div>
  );
};

export default ArtworkCard;
