import React, {useEffect, useState} from 'react';
import ArtworkDetails from '../../components/ArtworkDetails'
import {favouriteArtworks, fetchArtworkDetails} from '../../services/api';
import { IArtworkDetailsProps } from './types';
import { useTrackingCode } from "react-hubspot-tracking-code-hook";

const ArtworkDetailsContainer: React.FC<IArtworkDetailsProps> = ({artworkId, onHide, isConversation, onLeftClick, onRightClick}) => {
    const [details, setDetails] = useState({});
    const [isFavourite, setIsFavorite] = useState({});
    const [isLoading, setLoading] = useState(true);
    const { setContentType } = useTrackingCode();

    useEffect(() => {
        setContentType('artwork-details-page');
        setLoading(true);
        loadArtwork();
    }, [artworkId]);


    const toggleFavourite = async (artworksId) => {
        const resp = await favouriteArtworks(artworksId);
        if (resp.data) {
            setIsFavorite(resp.data.isFavourite);
        }
    };

    const loadArtwork = async () => {
        try {
            if (artworkId) {
                const resp = await fetchArtworkDetails(artworkId);
                setIsFavorite(resp.data.isFavourite);
                setDetails(resp.data);
                setLoading(false)
            }
        } catch (e) {
            setLoading(false)
        }
    }

    return (
        <>
        {/*@ts-ignore*/}
            <ArtworkDetails artworkId={artworkId} onLeftClick={onLeftClick} onRightClick={onRightClick}  isChatOpen={isConversation} isLoading={isLoading} onClose={onHide} toggleFavourite={toggleFavourite} isFavourite={isFavourite} details={details}/>
        </>
    )
}

export default ArtworkDetailsContainer;
