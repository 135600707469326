import React, {useContext, useEffect, useState} from 'react'
import IslandLoadingNav from '../IslandLoadingNav/IslandLoadingNav'
import IslandGalleryNav from '../IslandGalleryNav/IslandGalleryNav'
import "../IslandNav";

const LoadingToGallery = ({ variant, progress, onClick, classes, isGalleryLoaded, setIsGalleryLoaded, isInstructionsOpen, isGalleryLoadingVariant = false }) => {

  const clickHandler = () =>{
    onClick()
  }

    useEffect(() => {
        return () => setIsGalleryLoaded(true);
    }, [])
    
    return (
        <div className={`island-nav ${`island-nav--${variant}`} ${ progress < 100 ? '' : 'loaded'} ${isGalleryLoaded ? 'island-nav--loading-to-gallery--loaded' : ''} ${classes}`} onClick={clickHandler}>
            <div className='island-loading-to-gallery-nav-content'>
                <IslandLoadingNav progress={progress} loading={progress < 100} isGalleryLoadingVariant={isGalleryLoadingVariant} />
                <IslandGalleryNav isInstructionsOpen={isInstructionsOpen} />
            </div>
        </div>
    )
}

export default LoadingToGallery;