import React, { useEffect, useState } from 'react';
import Exhibition from '../../components/Exhibition'
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/Spinner";
import ScrollAnimation from '../../components/ScrollAnimation/ScrollAnimation';
import { favouriteExhibition } from '../../services/api'
import { constructImageUrl, getIdFromSlug } from '../../utils'
import Dropdown from '../../components/Dropdown'
import useInfiniteScroll from 'react-infinite-scroll-hook';
import SvgHeader from "../../components/SvgHeader";
import { Paragraph } from '../../components/Typography2';
import logger from '../../utils/logger';
import rudderstackEvents from "../../services/rudderstack-events";
import SubHeader from '../../components/SubHeader';
import { useTrackingCode } from "react-hubspot-tracking-code-hook";

const Exhibitions = (props) => {
    const { collectiveMeta, updateFavourite, setExhibitionsLoader, fetchCollectiveMetaInit, exhibition: { sortBy, exhibitions, currentPage, canLoadMore, exhibitionsLoading }, globalProps, toggleFavouriteExhibition } = props;
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const navigate = useNavigate();
    const { setContentType } = useTrackingCode();

    useEffect(() => {
        if (exhibitions.length === 0) {
            loadData();
        }
    }, [exhibitions.length]);

    useEffect(() => {
        setContentType('exhibitions-listing-page');
        loadCollectives()
    }, []);

    const loadCollectives = () => {
        if (collectiveMeta.length === 0) {
            fetchCollectiveMetaInit();
        }
    }

    useEffect(() => {
        setIsLoadingMore(false)
    }, [exhibitions.length]);

    const loadData = async () => {
        if (canLoadMore && !isLoadingMore) {
            setIsLoadingMore(true);
            const nextPage = currentPage + 1;
            const isGroup = !['all', 'private', 'public'].includes(sortBy)
            await props.fetchExhibitions(nextPage, sortBy, isGroup);
        }
    };

    const toggleFavourite = async (id, galleryId, galleryName, exhibitionName, exhibitionUuid) => {
        if (globalProps.isLoggedIn) {
            rudderstackEvents.onExhibitionBookmark({ type: 'card', exhibitionId: id, galleryId, galleryName, exhibitionName, exhibitionUuid })
            const resp = await favouriteExhibition(id);
            logger.debug('resp = ', resp);
            if (resp.data) {
                updateFavourite();
                toggleFavouriteExhibition(id, resp.data.isFavourite);
            }
        } else {
            navigate(`/login?/exhibitions`)
        }
    };

    const handleExhibitionClick = (exhibitionId) => {
        navigate(`/exhibitions/${exhibitionId}`);
    }

    const filterOptions = () => {
        const options = [
            {
                value: 'all',
                label: 'All'
            }, {
                value: 'public',
                label: 'Public'
            }
        ]
        if (globalProps.isLoggedIn) {
            options.push({
                value: 'private',
                label: 'Private'
            })
        }
        return options.concat(collectiveMeta.map(c => {
            return {
                value: c.id,
                label: c.name
            }
        }))
    }

    const handleValueChange = async (value) => {
        setExhibitionsLoader(true)
        props.updateSortBy(value);
        const isGroup = !['all', 'private', 'public'].includes(value);
        await props.fetchExhibitions(1, value, isGroup);
    }

    const infiniteRef = useInfiniteScroll({
        loading: isLoadingMore,
        hasNextPage: canLoadMore,
        onLoadMore: loadData,
    });


    return (
        <div>
            <SubHeader title="Exhibitions" bottomGutter>
                <Dropdown options={filterOptions()} selected={sortBy} onChange={(val) => handleValueChange(val)} />
            </SubHeader>
            <ScrollAnimation className="bg-white">
                <div className={`container pt-0 ${globalProps.isConversation ? 'conversations' : ''}`}>
                    {/* <SvgHeader type="Exhibitions"/> */}
                    {
                        exhibitionsLoading ?
                            <Spinner type="cover" />
                            :
                            <div className="cards-container">
                                {/*// @ts-ignore*/}
                                {exhibitions.length > 0 ? <div ref={infiniteRef}>
                                    {
                                        exhibitions.map((exhibition: any) => {
                                            const { id, slug, name, image, visibilityType, organisation, exhibitionType, isFavourite, curator, uuid, isDateVisible } = exhibition.data;
                                            const { locations } = organisation.data;
                                            let { startedAt, endedAt } = exhibition.data;

                                            return (
                                                <div key={id} className={visibilityType === 'invite-only' ? 'exhibitions-wrapper private-exhibition-wrapper' : ''}>
                                                    <Exhibition
                                                        id={id}
                                                        slug={slug}
                                                        uuid={uuid}
                                                        noScroll={false}
                                                        isPrivate={visibilityType === 'invite only'}
                                                        exhibitionImg={image && [(image.data && image.data && constructImageUrl(image, "820")), (image.data && image.data && constructImageUrl(image, "820"))]}
                                                        title={name}
                                                        exhibitionType={exhibitionType}
                                                        isLoggedIn={globalProps.isLoggedIn}
                                                        onClick={handleExhibitionClick}
                                                        galleryName={organisation?.data?.type === "vortic" ? (curator?.data?.title && `Vortic Curated x ${curator?.data?.title}`) : organisation?.data?.name}
                                                        startedAt={startedAt}
                                                        endedAt={endedAt}
                                                        locations={locations}
                                                        isFavourite={isFavourite}
                                                        toggleFavourite={(id) => toggleFavourite(getIdFromSlug(id), organisation.data.id, organisation.data.name, name, uuid)}
                                                        isVorticCurate={organisation?.data?.type === "vortic"}
                                                        isDateVisible={isDateVisible}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        isLoadingMore &&
                                        <Spinner />
                                    }
                                </div> : (
                                    <>
                                        <br /><br /><br />
                                        <Paragraph value="No Exhibitions Found." />
                                    </>
                                )}
                            </div>
                    }
                </div>
            </ScrollAnimation>
        </div>
    )
};

export default Exhibitions;
