import React, { FC } from 'react';
import "./style.scss";
import Button from "../Button";
import ShareBtn from "../ShareBtn";
import { getShareURL } from "../../utils/index";
import { ISidePanelFooterProps } from "./types";

const SidePanelFooter:FC<ISidePanelFooterProps> = ({ handleHelp, handleFavourite, exhibitionSlug, uuid, isFavourite }) => {
  return (
    <div className='side-panel-footer'>
      <div className='side-panel-footer-controls'>
        {/* <Button onClick={handleHelp} value='Help' type='secondary' iconType='help-round' /> */}
        <div className='side-panel-footer__share-btn'>
          <Button type='secondary' iconType='share' value='Share' />
            <ShareBtn
                up
                shareUrl={getShareURL("exhibition", exhibitionSlug || uuid)}
            />
        </div>
        <Button onClick={handleFavourite} value='Bookmark' type='secondary' 
          iconType={isFavourite ? "favouriteOn" : "favourite"} 
        />
      </div>
    </div>
  )
}

export default SidePanelFooter;
