import React, {useEffect, useState, useRef, useContext} from 'react';
import "./CollaborationsNewPage.scss";
import {SideBar} from "../../components/SideBar/SideBar";
import IslandNav from '../../components/Nav/IslandNav';
import UntitledWelcome from '../../components/UntitledWelcome';
import {UntitledHeader} from "../../components/UntitledHeader/UntitledHeader";
import {fetchCollaborations} from "../../services/api";
import {GalleryItem} from "../../components/GalleryList/types";
import InfoIcon from '../../assets/icons/info-icon.svg'
import Dragger from "../../components/Dragger";
import ContextProvider, {CollaborationContext} from "./CollaborationsContext";
import ControlsToSearchAndFilter from "../../components/Nav/ControlsToSearchAndFilter";
import {useCurateTypes, CURATE_ELEMENT_ID} from './hooks/useCurate'
import {useCurateContext} from "./contexts/curateContext";
import formatExhibitions from './helpers/formatExhibitions'
interface InitialData {
  [key: string]: GalleryItem[]
}

const CollaborationsNewPage = ({initialCollaborationsData}) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(false);
  const [isPageReady, setIsPageReady] = useState(false);
  const [collaborationsData, setCollaborationData] = useState<InitialData>({})
  const collaborationsMainRef = useRef();
  const [windowWidth, setWindowWidth] = useState(null);
  const isMobile = windowWidth <= 767;
  const [parentCall, setParentCall] = useState("");
  const [toggle, setToggle] = useState<boolean>(false);
  const {loadingProgress, resizeLoop}: useCurateTypes = useCurateContext();

  const handleSideBar = () => {
    if (!isMobile) {
      setIsPanelOpen(!isPanelOpen)
      resizeLoop();
    }
  }

  const handleModal = (val) => {
    setIsWelcomeModalOpen(val)
    if (!val) {
      const pageReadyTimeout = setTimeout(() => {
        setIsPageReady(true);
        clearInterval(pageReadyTimeout);
      }, 1000)
    }
  }

  useEffect(() => {
    if(isPageReady){
      handleSideBar();
    }
  }, [isPageReady])

  const loadData = async () => {
    const groupedData: InitialData = {};
    initialCollaborationsData.forEach(obj => {
      if (obj.metadata) {
        const groupValue = JSON.parse(obj.metadata).grouping;
        const continent = JSON.parse(obj.metadata).continent
        const artists=obj.exhibitions[0].artists.map(artist=>artist.name).join(', ')
        if (!groupedData[groupValue]) {
          groupedData[groupValue] = [];
        }
        groupedData[groupValue].push({
          id: obj.id,
          title: obj.name,
          exhibitions: formatExhibitions(obj.exhibitions),
          groupId: groupValue,
          continent,
          selected: false,
          disabled: false,
          artistName:artists
        })
      }
    });
    const sortedGroupedData = {};
    Object.keys(groupedData).map((key) => {
      if(!sortedGroupedData[key]){
        sortedGroupedData[key] = [];
      }
      sortedGroupedData[key] = groupedData[key].sort((a, b) => a.title.localeCompare(b.title))
    })
    setCollaborationData(sortedGroupedData)
  }

  const addQuerySelectors=()=>{
    document.querySelector(".footer")?.classList.add("footer--untitled-art");
    document.querySelector("body")?.classList.add("overflow-controls");
    document.querySelector("html")?.classList.add("overflow-controls");
  }

  const removeQuerySelectors=()=>{
    document.querySelector("body")?.classList.remove("overflow-controls");
    document.querySelector("html")?.classList.remove("overflow-controls");
    document.querySelector(".footer")?.classList.remove("footer--untitled-art");
  }

  const resizeObserver = new ResizeObserver(() => {
    setWindowWidth(window.innerWidth)
  });

  useEffect(() => {
    loadData()
    addQuerySelectors()
    if (collaborationsMainRef.current){
      resizeObserver.observe(collaborationsMainRef.current);
    }

    const modalTimeout = setTimeout(() => {
      setIsWelcomeModalOpen(true);
    }, 1500)

    return () => {
     removeQuerySelectors()
      resizeObserver.disconnect()
      clearTimeout(modalTimeout);
    }
  }, [])

  return (
    <ContextProvider isPanelOpen={isPanelOpen} setIsPanelOpen={setIsPanelOpen}>
      {
        isMobile && (
          <div className='collaborations-dragger-wrapper'>
            <Dragger
              initialData={collaborationsData}
              setToggle={setToggle}
              setParentCall={setParentCall}
              toggle={toggle}
              isGalleyLoaded={loadingProgress === 100}
            />
          </div>
        )
      }

      <div
        className={`collaborations-new-page ${isPanelOpen ? 'collaborations-new-page--side-panel-open' : ''}`}
        ref={collaborationsMainRef}
      >
        <div className='collaborations-new-page__content'>
          <div className='collaborations-new-page__canvas'>
            <div className='collaborations-new-page__curate' id={CURATE_ELEMENT_ID} />
            <div className='collaborations-untitled-header'>
              <UntitledHeader
                variant='light'
                logoOnly={false}
                handleMenu={handleSideBar}
                handleInfoClick={() => handleModal(true)}
                gradientBackground
              />
            </div>
            <UntitledWelcome
              open={isWelcomeModalOpen}
              setOpen={handleModal}
              classes='loading-modal'
              progress={loadingProgress}
              isGalleryLoaded={loadingProgress === 100}
            />
            {isPageReady &&
              <IslandNavContainer
                handleModal={handleModal}
                setToggle={setToggle}
                parentCall={parentCall}
                handleSideBar={handleSideBar}
                windowWidth={windowWidth}
                toggle={toggle}
              />
            }
          </div>
          <div className='collaborations-new-page__side-panel'>
            <div className='collaborations-side-panel__content'>
              <SideBar
                initialData={collaborationsData}
              />
            </div>
          </div>
        </div>
      </div>
    </ContextProvider>
  )
}

const IslandPagination = ({mobileProgress, toggle}) => {
  const { currentGroup, totalNoOfGalleries}=useContext(CollaborationContext)
  return (<p className={`collaboration-new-page__footer__counter ${(mobileProgress > -1 || toggle) ? 'hide' : ''}`}>{totalNoOfGalleries===0?totalNoOfGalleries:currentGroup} / {totalNoOfGalleries}</p>)
}

const IslandNavContainer = ({handleSideBar, handleModal, setToggle, parentCall, windowWidth, toggle}) => {
  const {handleControlsLoading, setMobileProgress, mobileProgress, controlsLoading}=useContext(CollaborationContext)
  return (
    <>
      <div
        className='collaboration-new-page-fade-up collaborations-new-page__canvas__controls-wrapper'
      >
        <img
          className={`collaboration-new-page__footer__info-icon ${(mobileProgress > -1 || toggle) ? 'hide' : ''}`}
          src={InfoIcon} alt="Art Icon"
          onClick={() => handleModal(true)}/>
        <div className='mobile-version'>
          <ControlsToSearchAndFilter
            variant={'controls-to-search-and-filters'}
            handleMenu={() => setToggle(true)} parentCall={parentCall}
            progress={mobileProgress} setProgress={setMobileProgress}
            handleClose={() => setToggle(false)}
            handlePrev={() => handleControlsLoading(true)}
            handleNext={() => handleControlsLoading(true)}
          />
        </div>
        <div className='desktop-version'>
          <IslandNav
            variant={'controls-to-loading'}
            progress={controlsLoading}
            handlePrev={handleControlsLoading}
            handleMenu={() => handleSideBar()}
            isMobileControlsNav={windowWidth < 769}
            handleNext={handleControlsLoading}/>
        </div>
        <IslandPagination mobileProgress={mobileProgress} toggle={toggle} />
      </div>
    </>
  )
}



export default CollaborationsNewPage;
