import React, { useState, useRef, useEffect } from "react";
import { Paragraph, Label } from "../Typography2";
import { IArtworkCarouselProps } from "./types";
import CloseIcon from "../../assets/icons/cross.svg";
import ShareBtn from "../ShareBtn";
import Share from "../../assets/icons/share-dark-icon.svg";
import Favourite from "../../assets/icons/favourite-dark.svg";
import FavouriteOn from "../../assets/icons/favourite-dark-on.svg";
import "flickity-as-nav-for";
import Flickity from "react-flickity-component";
import "./styles.scss";
import ImageZoom from "../ImageZoom";
import logger from '../../utils/logger';
import { isMobileDevice } from "../../utils";
import Hammer from 'hammerjs';
import ImageZoomer from '../ImageZoomer';
import IconBtn from '../IconBtn';

const ArtworkCarousel: React.FC<IArtworkCarouselProps> = ({
  artworkPhotos,
  handleClose,
  artworkMedium,
  shareUrl,
  isFavourite,
  isSidebar,
  dimensionsInCM,
  dimensionsInIN,
  onFavourite,
  chainLink,
  selectedIndex,
  setArtwork2dModal
}) => {
  const [dimensionsUnit, setDimensionsUnit] = useState("cm");
  const [isZoomed, setIsZoomed] = useState(false);
  const [pinchState, setPinchState] = useState(null);
  const [scale, setScaling] = useState(1);
  const [isStable, setIsStable] = useState(true);
  const sliderRef = useRef(null)

  useEffect(() => {
    // if(isScaling) {
      // setTimeout(() => {
        if(scale <= 1){
          setTimeout(() => {
            setIsStable(true);
          }, 0)
        } else {
          setTimeout(() => {
            setIsStable(false);
          }, 0)
        }
      // }, 200)
    // }
  }, [scale])

  const handleDimensionsToggle = () => {
    if (dimensionsUnit === "cm") {
      setDimensionsUnit("in");
    } else {
      setDimensionsUnit("cm");
    }
  };

  const handlePinch = (e) => {
    const scale = Math.ceil(e.scale);
    // if(scale > 1) {
    //   // @ts-ignore
    //   document.querySelector('.carousel')?.click();
    // }
    // else if (scale <= 1) {
    //   // @ts-ignore
    //   document.querySelector('.iiz__close')?.click();
    // }
    if (e.scale < 1.0) {
        setPinchState('unzoom');
    } else if (e.scale > 1.0) {
        setPinchState('zoom');
    } else {
      setPinchState(null);
    }
  }

  const handleSwipeLeft = () => {
    let izZoomed = document.querySelector('.is-selected').querySelector('.zoom-image-wrapper')?.classList?.contains('active');
    if(!izZoomed && isMobileDevice()){
      const carousel = document.querySelector('.desktop-artwork-carousel');
      // @ts-ignore
      carousel.querySelector('.flickity-prev-next-button.next')?.click();
    }
  }

  const handleSwipeRight = () => {
    let izZoomed = document.querySelector('.is-selected').querySelector('.zoom-image-wrapper')?.classList?.contains('active');
    if(!izZoomed && isMobileDevice()){
      const carousel = document.querySelector('.desktop-artwork-carousel');
      // @ts-ignore
      carousel.querySelector('.flickity-prev-next-button.previous')?.click();
    }
  }

  const [touchStart, setTouchStart] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50 

  const onTouchStart = (e) => {
    setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX)
  }

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

  const onTouchEnd = () => {
    if(isStable) {
      if (!touchStart || !touchEnd) return
      const distance = touchStart - touchEnd
      const isLeftSwipe = distance > minSwipeDistance
      const isRightSwipe = distance < -minSwipeDistance
      if (isLeftSwipe || isRightSwipe) {
        if(isLeftSwipe) {
          handleSwipeLeft();
        }
        else {
          handleSwipeRight();
        }
      }
    }
    // add your conditional logic here
  }

  useEffect(() => {
    if(isMobileDevice()) {
      // const myElement = document.querySelector('.desktop-artwork-carousel');
      // var mc = new Hammer.Manager(myElement);

      // mc.add(new Hammer.Pan({ threshold: 0, pointers: 0 }));

      // mc.add(new Hammer.Swipe()).recognizeWith(mc.get('pan'));
      // mc.add(new Hammer.Rotate({ threshold: 0 })).recognizeWith(mc.get('pan'));
      // mc.add(new Hammer.Pinch({ threshold: 0 })).recognizeWith([mc.get('pan'), mc.get('rotate')]);

      // mc.add(new Hammer.Tap({ event: 'doubletap', taps: 2 }));
      // mc.add(new Hammer.Tap());

      // mc.on("pinchstart pinchmove",  handlePinch);
      // mc.on("swipeleft",  handleSwipeLeft);
      // mc.on("swiperight",  handleSwipeRight);


    }
  }, [])

  useEffect(() => {
    if(isZoomed) {
      setPinchState('zoom');
    } else {
      setPinchState('unzoom');
    }
  }, [isZoomed])

  useEffect(() => {
    if(isMobileDevice()) {
      if(pinchState === 'zoom') {
        // @ts-ignore
        // document.querySelector('.carousel')?.click();
        // const imagesWrapper = document.querySelector('.zoom-image-wrapper');
        const image = document.querySelector('.slider-wrapper.is-selected');
        // @ts-ignore
        image?.querySelector('.zoom-image-wrapper')?.classList.add('active');
        // @ts-ignore
        image?.querySelector('.iiz__img')?.click()
        setIsZoomed(true);
        // image.forEach(img => img?.click());
      } else if(pinchState === 'unzoom') {
        // @ts-ignore
        // @ts-ignore
        if(isZoomed && document.querySelector('.iiz__close')) {
          // @ts-ignore
          document.querySelector('.iiz__close').click();
          setIsZoomed(false);
        }
      }
    }
  }, [pinchState])


  const serialize = (obj) => {
    var str = [];
    for (var p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    }
    return str.join("&");
  };

  const getCurateArtworkAddress = () => {
    const curateLink = window.location.href.slice(
      0,
      window.location.href.indexOf("?") + 1
    );
    // const curateLink = window.location.href;
    var hash = window.location.search;
    var array = hash.substring(1).split("&");
    var values,
      form_data = {};

    for (var i = 0; i < array.length; i += 1) {
      values = array[i].split("=");
      form_data[values[0]] = values[1];
    }

    delete form_data["eventId"];

    return `${curateLink}${serialize(form_data)}`;
  };

  useEffect(() => {
    if (sliderRef.current) {
      // Check if Flickity instance is available
      sliderRef.current.on('ready', () => {
        // Once Flickity is ready, set the initial index
        sliderRef.current.select(selectedIndex);
      });
    }
  }, [selectedIndex]);

  return (
    <div className="artwork-carousel-wrapper">
      <div
        className="artwork-carousel__overlay"
        onClick={() => handleClose()}
      />
      <div className="artwork-carousel__content">
        {/* {
          isMobileDevice() ? (
          !isZoomed &&
          <button
          className="artwork-carousel__close artwork-carousel__close--mobile"
          onClick={() => handleClose()}
        >
          <img src={CloseIcon} alt="close" />
          </button>
          ) : (
            <button
            className="artwork-carousel__close"
            onClick={() => handleClose()}
          >
            <img src={CloseIcon} alt="close" />
            </button>  
          )
        } */}
        <IconBtn className="artwork-carousel__close" onClick={() => handleClose()} type="brightClose" />
        <div className="artwork-carousel__showcase" >
          <div className={`artwork-carousel__slider ${isZoomed ? 'is-zoomed': ''}`} onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
            {selectedIndex >= 0 && <Flickity
              className={"carousel carousel-main desktop-artwork-carousel"}
              elementType={"div"}
              flickityRef={(c) => (sliderRef.current = c)}
              options={{
                pauseAutoPlayOnHover: false,
                wrapAround: true,
                prevNextButtons: true,
                pageDots: (artworkPhotos?.length > 1 && isMobileDevice() && !isZoomed),
                // initialIndex: selectedIndex,
                draggable: false
              }}
            >
              {artworkPhotos?.length > 0 && 
                artworkPhotos.map((photo, index) => {
                  return (
                    <div className="slider-wrapper" key={index}>
                      {/* {false ? <ImageZoom
                          className={'main2d-image'}
                          zoomType={'click'}
                          src={photo?.large?.replace('1920_', '1080_')}
                          zoomSrc={photo?.large}
                        /> :  */}
                        <ImageZoomer 
                          // @ts-ignore
                          className="main2d-image"
                          src={photo?.thumbnail ? photo?.thumbnail : ((isMobileDevice() ? photo?.large?.replace('1920_', '1280_') : photo?.large))}
                          setScaling={setScaling}
                          scale={scale}
                        />
                        {/* } */}
                    </div>
                  );
                })}
            </Flickity>}
          </div>
        </div>
        {artworkPhotos?.length > 1 &&
          <section className={`artwork-carousel__nav ${(isMobileDevice() && isZoomed) ? 'is-zoomed' : ''}`}>
            {!isMobileDevice() && <Flickity
              className={"carousel artwork-carousel__list"}
              options={{
                asNavFor: ".carousel-main",
                contain: true,
                pageDots: false,
                prevNextButtons: false,
                initialIndex: selectedIndex,
                draggable: true
              }}
            >
              {
                artworkPhotos.map((photo, index) => {
                  return (
                    <div className="item" key={index}>
                      <img src={photo.small} alt="artwork" />
                    </div>
                  );
                })}
            </Flickity>}
          </section>
          }
        <div className="artwork-carousel__footer">
          <div className="curate-artwork-middle">
            <Paragraph>
              <>
                {`${
                  dimensionsUnit === "in" ? dimensionsInIN : dimensionsInCM
                } / `}
                {
                  <button
                    className="gray unit-btn"
                    onClick={handleDimensionsToggle}
                  >
                    {dimensionsUnit === "in" ? "cm" : "in"}
                  </button>
                }
              </>
            </Paragraph>
            {artworkMedium && artworkMedium !== "" && artworkMedium.trim() && (
              <Paragraph>
                <>
                  {`${artworkMedium}`}
                </>
              </Paragraph>
            )}
            <div
              className={`artwork-actions ${
                isSidebar ? "artwork-actions--sidebar-open" : ""
              }`}
            >
              <div className="action-btn share">
                <img src={Share} alt="share" />
                <Label value="Share Artwork" />
                <ShareBtn up shareUrl={shareUrl} chainLink={getCurateArtworkAddress()} />
              </div>
              <button className="action-btn" onClick={onFavourite}>
                <div style={{ width: '10px' }}>
                  <img
                    src={isFavourite ? FavouriteOn : Favourite}
                    alt="favourite"
                  />
                </div>
                <Label value="Bookmark Artwork" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArtworkCarousel;
