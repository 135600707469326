import React, {useEffect, useState, Fragment} from "react";
import {
    favouriteExhibition,
    favouriteGalleries,
    fetchGalleryArtists,
    fetchGalleryDetails,
    fetchGalleryExhibitions,
    fetchCuratorExhibitions,
    fetchCuratorDetails
} from "../../../services/api";
import {useNavigate, useParams} from "react-router";
import DetailsCard from "../../../components/DetailsCard";
import DetailsHeader from "../../../components/DetailsHeader";
import Card from "../../../components/Card";
import Exhibition from "../../../components/Exhibition";
import Spinner from "../../../components/Spinner";
import {IGalleryDetails} from './types'
import {Heading, Label, Paragraph, Anchor} from "../../../components/Typography2";
import {constructImageUrl, isMobileDevice} from '../../../utils'
import LazyLoad from 'react-lazy-load';
import ScrollAnimation from '../../../components/ScrollAnimation/ScrollAnimation';
import ArrowDownDark from "../../../assets/icons/down-dark.svg";
import Instagram from "../../../assets/icons/instagram.svg";
import Facebook from "../../../assets/icons/facebook-dark.svg";
import Linkedin from "../../../assets/icons/linkedin-dark.svg";
import TikTok from "../../../assets/icons/tiktok.svg";
import TwitterDark from "../../../assets/icons/twitter-dark.svg";
import HeadTags from "../../../components/Helmet/HeadTags";
import Button from "../../../components/Button";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";

const CuratorDetails = (props) => {
    const initalDetails = {
        id: null,
        image: {},
        about: null,
        description: null,
        overview: '',
        slug: '',
        title: '',
        location: '',
        postCode: '',
        apartmentNo: '',
        email: '',
        phoneNumber: '',
        streetNo: '',
        city: '',
        state: '',
        country: '',
        isFavourite: false,
        links: [],
        instagram: '',
        facebook: '',
        twitter: '',
        linkedIn: '',
        tikTok: '',
        website: '',
    }
    const [showAllDetails, toggleAllDetails] = useState(false);

    const { id: curatorId } = useParams();
    const navigate = useNavigate();
    const [details, setDetails] = useState<IGalleryDetails>(initalDetails)
    const [exhibitions, setExhibitions] = useState([])
    // const [artists, setArtists] = useState([])
    const [isLoadingDetails, setLoadingDetails] = useState(true)
    // const [isLoadingArtists, setLoadingArtists] = useState(true)
    const [isLoadingExhibitions, setLoadingExhibitions] = useState(true)
    const [currentExhibitions, setCurrentExhibitions] = useState(null);
    const [pastExhibitions, setPastExhibitions] = useState(null);
    //@ts-ignore
    // const curatorId = route && route.params && route.params.id;
    const { setContentType } = useTrackingCode();

    const loadCuratorDetails = async () => {
        return new Promise(async (resolve, reject) => {
            let galleryDetails;

            if(!props.preview) {
                galleryDetails = await fetchCuratorDetails(curatorId);
            } else {
                galleryDetails = await fetchCuratorDetails(curatorId, true);
            }

            if (galleryDetails.data) {
                setDetails(galleryDetails.data)
                setLoadingDetails(false);
                resolve(true)
            } else {
                resolve(true)
            }
        })
    };

    useEffect(() => {
        const tempExhibitions = [...exhibitions];
        const currentExhibs = [];
        const pastExhibs = [];
        tempExhibitions.map(curatorExhibition => {
            if (curatorExhibition.data?.exhibition?.data?.isLive) {
                currentExhibs.push(curatorExhibition);
            }
            else {
                pastExhibs.push(curatorExhibition);
            }
        })

        setCurrentExhibitions(currentExhibs);
        setPastExhibitions(pastExhibs);
    }, [exhibitions])

    const loadCuratorExhibitions = async () => {
        const exhibitionsResp = await fetchCuratorExhibitions(curatorId, props.preview);
        if (exhibitionsResp) {
            setExhibitions(exhibitionsResp);
            setLoadingExhibitions(false);
        }
    };

    // const loadGalleryArtists = async () => {
    //     return new Promise(async (resolve, reject) => {
    //         const artistsResp = await fetchGalleryArtists(getIdFromSlug(galleryId));
    //         if (artistsResp.data) {
    //             setArtists(artistsResp.data);
    //             setLoadingArtists(false);
    //             resolve(true)
    //         } else {
    //             resolve(true)
    //         }
    //     })
    // };

    useEffect(() => {
        setContentType('curator-details-page');
        window.scrollTo(0, 0)
        props.setRedirectPath('')
        loadData()

    }, [])

    const loadData = async () => {
        const detailsResp = await loadCuratorDetails();
        if (detailsResp) {
            // const artistsResp = await loadGalleryArtists();
            // if (artistsResp) {
                loadCuratorExhibitions();
            // }
        }
    }

    const handleArtistClick = (artistId) => {
        navigate(`/artists/${artistId}`)
    };

    const handleExhibitionClick = (id) => {
        navigate(`/exhibitions/${id}`)
    };

    const toggleFavourite = async (id) => {
        if (props.globalProps.isLoggedIn) {
            const resp = await favouriteGalleries(id);
            if (resp.data) {
                const updatedDetails = {
                    ...details,
                    isFavourite: resp.data.isFavourite
                };
                props.updateFavourite();
                setDetails(updatedDetails);
            }
        } else {
            navigate(`/login?/curators/${curatorId}`)
        }
    };

    const toggleExhibitionFavourite = async (exhibitionId) => {
        if (props.globalProps.isLoggedIn) {
            let resp = await favouriteExhibition(exhibitionId)
            if (resp.data) {
                let newExhibitions = [];
                exhibitions.map(exhibition => {
                    if (exhibition.data.id === exhibitionId) {
                        exhibition = {
                            ...exhibition,
                            data: {
                                ...exhibition.data,
                                isFavourite: resp.data.isFavourite
                            }
                        }
                    }
                    newExhibitions.push(exhibition)
                    return exhibition;
                })
                setExhibitions(newExhibitions)
            }
        } else {
            navigate(`/login?/curators/${curatorId}`)
        }
    }

    const formatAddress = () => {
        let address = '';
        if (details.streetNo) {
            address += details.streetNo + " "
        }
        if (details.apartmentNo) {
            address += details.apartmentNo + " "
        }
        if (details.city) {
            address += details.city + " "
        }
        if (details.state) {
            address += details.state + " "
        }
        if (details.postCode) {
            address += details.postCode + " "
        }
        if (details.country) {
            address += details.country + " "
        }
        return address;
    }
    const getSocialLinks = () => {
        // const initialLinks = {
        //     instagram: '',
        //     twitter: '',
        //     facebook: '',
        //     linkedin: '',
        // }
        // const links = {...initialLinks};
        // details.links && details.links.map(link => {
        //     if (link && link.data && link.data.linkType !== 'web') {
        //         links[link.data.linkType] = link.data.url
        //     }
        // })
        // if (JSON.stringify(links) === JSON.stringify(initialLinks)) {
        //     return null;
        // } else {
            return (
                <Fragment>
                    {
                        details.instagram &&
                        <a target="_blank"
                           href={details.instagram[0] === "@" ? `https://www.instagram.com/${details.instagram.slice(1)}` : `https://www.instagram.com/${details.instagram}`}><img
                            src={Instagram} alt="Instagram"/></a>
                    }
                    {
                        details.facebook &&
                        <a target={"_blank"} href={!!details.facebook.includes('https://www.')?details.facebook : 'https://www.'+details.facebook}><img src={Facebook} alt="Facebook"/></a>
                    }
                    {
                        details.linkedIn &&
                        <a target={"_blank"} href={!!details.linkedIn.includes('https://www.')?details.linkedIn : 'https://www.'+details.linkedIn}><img src={Linkedin} alt="Linkedin"/></a>
                    }
                    {
                        details.twitter &&
                        <a target={"_blank"} href={!!details.twitter.includes('https://www.')?details.twitter : 'https://www.twitter.com/'+details.linkedIn}><img src={TwitterDark} alt="Twitter"/></a>
                    }
                    {
                        details.tikTok &&
                        <a target={"_blank"} href={!!details.tikTok.includes('https://www.')?details.tikTok : 'https://www.tiktok.com/@'+details.tikTok}><img src={TikTok} alt="TikTok"/></a>
                    }
                </Fragment>
            )
        // }
    }
    const renderExhibition = (type, exhibitions) => (
        <ScrollAnimation>
            <>
                <DetailsHeader title={type}/>
            </>
            <div className="cards-container">
                {
                    exhibitions?.length > 0 ? exhibitions.map((curatorExhibition: any) => {
                        const {
                            id,
                            name,
                            startedAt,
                            endedAt,
                            image,
                            organisation,
                            isFavourite,
                            uuid,
                            slug,
                            isDateVisible
                        } = curatorExhibition?.data?.exhibition?.data;

                        // TODO: Need to make this favourites work here
                        return (
                            <div key={id}>
                                <Exhibition
                                    id={id}
                                    uuid={uuid}
                                    slug={slug}
                                    exhibitionImg={image && [constructImageUrl(image,"1920"), constructImageUrl(image,"1920")]}
                                    title={name}
                                    isFavourite={isFavourite}
                                    onClick={handleExhibitionClick}
                                    galleryName={organisation && organisation.data.name}
                                    startedAt={startedAt}
                                    endedAt={endedAt}
                                    noScroll={false}
                                    locations={organisation && organisation.data.locations}
                                    toggleFavourite={toggleExhibitionFavourite}
                                    isDateVisible={isDateVisible}
                                />
                            </div>
                        )
                    }) : (<>
                        <Paragraph value={`No ${type} Found.`}/>
                        {type === "Current Exhibitions" && exhibitions?.length > 0 && <>
                            <br/>
                            <br/>
                            <br/>
                        </>
                        }
                    </>)
                }
            </div>
        </ScrollAnimation>
    )
    return (
        (isLoadingDetails === false) ? (
            <div className='container gallery-details-wrapper curator-details-wrapper'>
                <HeadTags
                    title={details?.title}
                    description={details?.overview}
                    image={constructImageUrl(details.image,"350")}
                    type={'galleries'}
                    id={details?.id}
                />
                <div className="details__container">
                    <DetailsCard
                        id={details && details.id}
                        type="curator"
                        slug={details && details.slug}
                        title={details && details.title} location=""
                        address={null}
                        imgSrc={details.image && details.image.data && [constructImageUrl(details.image,"820"),constructImageUrl(details.image,"820")]}
                        isFavourite={details && details.isFavourite}
                        description={null}
                        style={{paddingBottom: 0, marginBottom: 0}}
                        toggleFavourite={(id) => toggleFavourite(id)}
                        curatorDetails={isMobileDevice() || window.innerWidth < 767}
                    />
                    <ScrollAnimation style={{position: 'relative', zIndex: '0'}}>
                            <div className="gallery-content-wrapper">
                                {details.about ? <div className='details__flex details__press details__media-wrapper fill-screen'>
                                    {details.about.data.text && <div className='text-section'>
                                        <Heading value={details.about.data.text}/>
                                    </div>}
                                    {
                                        details.about.data.elements &&
                                        details.about.data.elements.slice(0, showAllDetails ? details.about.data.elements.length : 1)
                                            .map((element, index) => {
                                                if (element.data.elementType === 'normal') {
                                                    return <div className='text-section' key={index}>
                                                        <Paragraph value={element.data.text}/>
                                                    </div>
                                                } else if (element.data.elementType === 'bold') {
                                                    return <div className='text-section' key={index}>
                                                        <Heading value={element.data.text}/>
                                                    </div>
                                                } else if (element.data.elementType === 'image') {
                                                    return <div className='media-section' key={index}>
                                                        <LazyLoad key={index}>
                                                            <img
                                                                className='details__press-preview'
                                                                src={element.data.media && element.data.media.data.signedUrl}
                                                                alt='Press'
                                                            />
                                                        </LazyLoad>
                                                    </div>
                                                }
                                            })
                                    }

                                    {
                                        (details.about && details.about.data.elements.length > 1) && (
                                            <div className='text-section'>
                                                <div onClick={() => toggleAllDetails(!showAllDetails)}
                                                     className='read-more'>
                                                    <Label value={showAllDetails ? 'Read Less' : 'Read More'}/>
                                                    <img src={ArrowDownDark}
                                                         style={showAllDetails ? {transform: 'rotate(180deg)'} : {}}
                                                         alt="arrow down"/>
                                                </div>
                                            </div>
                                        )
                                    }

                                </div> :
                                <div className='details__flex details__press details__media-wrapper fill-screen'>
                                    {details?.description && <Paragraph>{details.description}</Paragraph>}
                                    {details?.biography && <><br /><Paragraph>
                                        {details.biography?.slice(0, (details?.biography?.length < 300 && showAllDetails) ? details?.biography?.length : 300)?.concat((showAllDetails || details?.biography?.length < 300) ? details?.biography?.slice(300) : '...')}
                                        </Paragraph></>}
                                    {details?.biography?.length >= 300 &&
                                        <>
                                            <br />
                                            <div onClick={() => toggleAllDetails(!showAllDetails)}
                                                className='read-more curator-read-more'>
                                                <Label value={showAllDetails ? 'Read less' : 'Read more'}/>
                                                <img src={ArrowDownDark}
                                                    style={showAllDetails ? {transform: 'rotate(180deg)'} : {}}
                                                    alt="arrow down"/>
                                            </div>
                                        </>
                                    }
                                </div>
                                }
                                <div className="gallery-contact-info">
                                    {/* {
                                        details && formatAddress() &&
                                        <article>
                                            <Heading value="Address:"/>
                                            <div className="anchor-wrapper no-link">
                                                <Paragraph value={formatAddress()}/>
                                            </div>
                                        </article>
                                    } */}

                                    {/* {
                                        details.email &&
                                        <article>
                                            <Heading value="Email:"/>
                                            <div className="anchor-wrapper">
                                                <Anchor target={"_blank"} to={`mailto:${details.email}`}
                                                        value={details.email}/>
                                            </div>
                                        </article>
                                    }
                                    {
                                        details.phoneNumber &&
                                        <article>
                                            <Heading value="Telephone:"/>
                                            <div className="anchor-wrapper">
                                                <Anchor target={"_blank"} to={`tel:${details.phoneNumber}`}
                                                        value={details.phoneNumber}/>
                                            </div>
                                        </article>
                                    } */}

                                    {
                                        details.location &&
                                        <article>
                                            <Heading value="Location:"/>
                                            <div className="anchor-wrapper no-link">
                                                <Paragraph value={details.location}/>
                                            </div>
                                        </article>
                                    }

                                    {
                                        details.website &&
                                        <article>
                                            <Heading value="Online:"/>
                                            <div className="anchor-wrapper">
                                                <Anchor target={"_blank"} to={details.website.includes('http') ? details.website : `https://${details.website}` }
                                                        value={details.website}/>
                                            </div>
                                        </article>
                                    }

                                    {(details.instagram || details.facebook || details.linkedIn || details.twitter || details.tikTok) && <article>
                                        <Heading value="Social:"/>
                                        <div className="gallery-info-links">
                                            {
                                                getSocialLinks()
                                            }
                                        </div>
                                    </article>}
                                </div>
                            </div>
                        </ScrollAnimation>
                </div>

                <div className="details-page-divider" />

                {
                    isLoadingExhibitions === false &&
                    <>
                        {renderExhibition("Current Exhibitions",currentExhibitions)}
                        <div className="details-page-divider" />
                        {renderExhibition("Past Exhibitions", pastExhibitions)}
                    </>
                }
            </div>
        ) : <Spinner/>
    )
};

export default CuratorDetails;
