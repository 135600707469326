import React from 'react';
import Logo from '../../assets/icons/bright-logo2.svg';
import { Label, Heading, Timeline } from '../Typography2';
import SpinnerLoading from "../Loader";

const RoomLoader = ({ title, galleryName, roomName, timeLine, progress, border, isArtwork2dModal = false }) => {
    return (
        <div className={'room-loader-wrapper' + (border ? ' border' : '') + (isArtwork2dModal ? ' room-loader-wrapper--artwork-2d-carousel-open' : '')}>
            {/* <img className='logo' src={Logo} alt='logo' /> */}
            <div className="room-middle-content">
                {/* @ts-ignore */}
                <SpinnerLoading prog={progress} secondary={false} scrolled={false} />
                {/* <Label className='bright' value='Loading...' /> */}
                {/* <Heading className='bright' value={roomName} /> */}
                {/* <div className='room-loading'>
                    <span style={{width: `${progress}%`}} />
                </div> */}
            </div>
            <div className='room-bottom-content'>
                <Heading className='bright custom' value={`Moving to ${roomName}`} />
                {/* <Heading className='bright' value={"Moving to the Second Floor"} /> */}
                {/* <Heading className='bright' value={title} /> */}
                {/* <Label className='bright' value={galleryName} /> */}
                <Label className='bright' value={title} />
                {/* <Timeline value={timeLine} /> */}
            </div>
        </div>
    )
}

export default RoomLoader;
