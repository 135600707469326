import ForgotPassword from './ForgotPassword'
import { connect } from 'react-redux'

const mapStatesToProps = (state) => {
  return {
    ...state.global
  }
}

export default connect(mapStatesToProps)(ForgotPassword)
