// @ts-nocheck
import React  from 'react';
import {ITextProps, ISubtitleProps} from './types';
import {Link} from 'react-router-dom';

export const H1: React.FC<ITextProps> = ({value, className}) => {
    return (
        <h1 className={`h1 ${className || ''}`}>
            {value}
        </h1>
    )
};

export const H2: React.FC<ITextProps> = ({value, className}) => {
    return (
        <h2 className={`h2 ${className || ''}`}>
            {value}
        </h2>
    )
};

export const H3: React.FC<ITextProps> = ({value, className}) => {
    return (
        <h3 className={`h3 ${className || ''}`}>
            {value}
        </h3>
    )
};

export const H4: React.FC<ITextProps> = ({value, className}) => {
    return (
        <h4 className={`h4 ${className || ''}`}>
            {value}
        </h4>
    )
};

export const P1: React.FC<ITextProps> = ({value, className}) => {
    return (
        <p className={`p1 ${className || ''}`}>
            {value}
        </p>
    )
};

export const P2: React.FC<ITextProps> = ({value, className}) => {
    const italic = (value && value !== undefined && value.split && value.split('{'));
    return (
        <p className={`p2 ${className || ''}`}>
            {italic ? italic[0] : value} {italic && value.indexOf('{') !== -1 && <span><i>{italic[1]}</i></span>}
        </p>
    )
};

export const P3: React.FC<ITextProps> = ({value, className}) => {
    return (
        <p className={`p3 ${className || ''}`}>
            {value}
        </p>
    )
};

export const Detail: React.FC<ITextProps> = ({value, className}) => {
    return (
        <p className={`detail ${className || ''}`}>
            {value}
        </p>
    )
};

export const SubTitle: React.FC<ISubtitleProps> = ({onClick, value, className, link, to}) => {
    return (
        <p className={`subtitle ${className || ''}`} onClick={onClick}>
            {value} {link && <Link to={to}>{link}</Link>}
        </p>
    )
};

export const Tooltip: React.FC<ITextProps> = ({value, className}) => {
    return (
        <p className={`tooltip ${className || ''}`}>
            {value}
        </p>
    )
};

export const Navigation1: React.FC<ITextProps> = ({value, className}) => {
    return (
        <p className={`navigation1 ${className || ''}`}>
            {value}
        </p>
    )
};

export const Navigation2: React.FC<ITextProps> = ({value, className}) => {
    return (
        <p className={`navigation2 ${className || ''}`}>
            {value}
        </p>
    )
};

export const Label: React.FC<ITextProps> = ({value, className}) => {
    return (
        <p className={`label ${className || ''}`}>
            {value}
        </p>
    )
};
