import React, {useContext} from 'react';
import IconBtn from '../../IconBtn';
import "./IslandControlsNav.scss";
import {CollaborationContext} from "../../../containers/CollaborationsNewPage/CollaborationsContext";

interface IslandControlsNavProps  {
    handleNext?: () => void;
    handlePrev?: () => void;
    handleMenu?: () => void;
    isMobileControlsNav?: boolean,
}

const IslandControlsNav:React.FC<IslandControlsNavProps> = ({ handleNext, handlePrev, isMobileControlsNav, handleMenu }) => {
    const {currentGroup, moveToNextGroup, moveToPreviousGroup, totalNoOfGalleries}=useContext(CollaborationContext)

    const handleClickOnNext = () => {
        moveToNextGroup()
    }

    const handleClickOnPrevious = () => {
        moveToPreviousGroup()
    }

    return (
        <div className={`island-controls-nav`}>
            <IconBtn type="backArrowBright" large onClick={handleClickOnPrevious} />
            {isMobileControlsNav ? <IconBtn onClick={handleMenu} type="list" large bright /> : <p className='island-controls-nav--counter'>{totalNoOfGalleries===0?totalNoOfGalleries:currentGroup} / {totalNoOfGalleries}</p>}
            <IconBtn type="nextArrowBright" large onClick={handleClickOnNext}  />
        </div>
    );
}

export default IslandControlsNav;
