import GalleryDetails from './CuratorDetails'
import { connect } from 'react-redux'
import './style.scss'
import {setRedirectPath} from "../../Global/redux/actions";
import {updateFavourite} from "../../Favourites/redux/actions";

const mapStatesToProps = (state) => {
    return {
        globalProps: state.global,
    }
};

const mapDispatchToProps = dispatch => ({
    setRedirectPath: (path) => dispatch(setRedirectPath(path)),
    updateFavourite: () => dispatch(updateFavourite()),
});

export default connect(mapStatesToProps, mapDispatchToProps)(GalleryDetails)
