// @ts-nocheck
import {ofType} from 'redux-observable';
import {catchError, flatMap, map} from 'rxjs/operators';
import {
    UPDATE_PROFILE_DATA_INIT,
    GET_UPDATE_PROFILE_DATA_INIT,
    GET_PROFILE_NOTIFICATION_DATA_INIT, UPDATE_PROFILE_NOTIFICATION_DATA_INIT
} from './actionTypes'
import * as API from "../../../services/api"
import {
    updateProfileDataSuccess,
    getUpdatedProfileDataSuccess,
    getProfileNotificationDataSuccess, updateNotificationSuccess
} from './actions';
import {IResponseType} from "../../Galleries/types";
import logger from "../../../utils/logger";


const updateProfile = action$ =>
    action$.pipe(
        ofType(UPDATE_PROFILE_DATA_INIT),
        flatMap(({payload}) => API.updateProfile(payload)),
        map((resp: IResponseType) => {
            if (resp.data) {
                return updateProfileDataSuccess(resp.data);
            }
        }),
        catchError(error => {
            logger.error('error = ', error);
            return () => {
            }
        })
    );
const updateNotification = action$ =>
    action$.pipe(
        ofType(UPDATE_PROFILE_NOTIFICATION_DATA_INIT),
        flatMap(({payload}) => API.updateNotification(payload)),
        map((resp: IResponseType) => {
            if (resp.data) {
                return updateNotificationSuccess(resp.data);
            }
        }),
        catchError(error => {
            logger.error('error = ', error);
            return () => {
            }
        })
    );

const getUpdatedProfile = action$ =>
    action$.pipe(
        ofType(GET_UPDATE_PROFILE_DATA_INIT),
        flatMap(() => API.getUpdateProfile()),
        map((resp: IResponseType) => {
            if (resp.data) {
                return getUpdatedProfileDataSuccess(resp);
            }
        }),
        catchError(error => {
            logger.error('error = ', error);
            return () => {
            }
        })
    );
const getNotifications = action$ =>
    action$.pipe(
        ofType(GET_PROFILE_NOTIFICATION_DATA_INIT),
        flatMap(() => API.getProfileNotification()),
        map((resp: IResponseType) => {
            if (resp.data) {
                return getProfileNotificationDataSuccess(resp);
            }
        }),
        catchError(error => {
            logger.error('error = ', error);
            return () => {
            }
        })
    );

export default [
    updateProfile,
    getUpdatedProfile,
    getNotifications,
    updateNotification
];
