import './style.scss';
import Discover from './Discover';
import { connect } from 'react-redux';
import { getDiscoverDetails } from './redux/actions';
import {setArtworkId} from "../Global/redux/actions";
import {toggleFavouriteExhibition} from "../Exhibitions/redux/actions";
import {updateFavourite} from "../Favourites/redux/actions";

const mapStatesToProps = ({discover, global}) => {
    return {
        details : discover.details,
        isLoggedIn : global.isLoggedIn,
        isConversation: global.isConversation,
    };
};

const mapDispatchToProps = dispatch => ({
    getDetails: (details) => dispatch(getDiscoverDetails(details)),
    setArtworkId:(val)=>dispatch(setArtworkId(val)),
    toggleFavouriteExhibition: (exhibitionId, isFavourite) => dispatch(toggleFavouriteExhibition(exhibitionId, isFavourite)),
    updateFavourite: () => dispatch(updateFavourite()),
});

export default connect(mapStatesToProps, mapDispatchToProps)(Discover);