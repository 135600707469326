import React from 'react';
import { Paragraph, Title } from '../../Typography2';
import "./style.scss";
import { IStepsHeaderProps } from './types';

const StepsHeader:React.FC<IStepsHeaderProps> = ({ title, titleHighlight, paragraph }) => {
  return (
    <div className='steps-header'>
        <Title className="step-title">
            {title} {!!titleHighlight && <span>{titleHighlight}</span>}
        </Title>
        {!!paragraph && <Paragraph>{paragraph}</Paragraph>}
    </div>
  )
}

export default StepsHeader;