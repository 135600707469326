import {FETCH_COLLECTIVES_META_SUCCESS, FETCH_COLLECTIVES_SUCCESS} from './actionTypes'

const initialState = {
  collectives: [],
  meta: [],
  collectivesLoading: true,
  canLoadMore: true,
  currentPage: 0,
  pages: {},
}

function reducer (state = initialState, action) {
  switch (action.type) {
    case FETCH_COLLECTIVES_SUCCESS:
      return {
        ...state,
        collectives: action.payload.data,
        collectivesLoading: false,
        pages: action.payload.page,
        currentPage: action.payload.currentPage,
        canLoadMore: action.payload.currentPage < action.payload.page && action.payload.page.lastPage.index,
      }

    case FETCH_COLLECTIVES_META_SUCCESS:
      return {
        ...state,
        meta: action.payload
      }

    default:
      return state
  }
}

export default reducer
