import {combineEpics} from "redux-observable";
import exhibitionsEpics from "../containers/Exhibitions/redux/epics"
import galleriesEpics from "../containers/Galleries/redux/epics"
import collectivesEpics from "../containers/Collectives/redux/epics"
import searchEpics from "../containers/Search/redux/epics"
import profileEpics from "../containers/Profile/redux/epics"
import favouritesEpics from "../containers/Favourites/redux/epics"
import conversationEpics from "../containers/Conversation/redux/epics"

export default combineEpics(...exhibitionsEpics, ...galleriesEpics, ...collectivesEpics, ...searchEpics, ...profileEpics, ...favouritesEpics, ...conversationEpics);
