import Exhibitions from './Exhibitions'
import { connect } from 'react-redux'
import { fetchPrivateAndPublicExhibitionsInit, setExhibitionsLoader, resetExhibitions, updateSortBy, toggleFavouriteExhibition } from './redux/actions'
import { fetchCollectiveMetaInit } from '../Collectives/redux/actions'
import { updateFavourite } from '../Favourites/redux/actions'

import './style.scss'

const mapStatesToProps = ({exhibition, global, collective}) => {
  return {
    exhibition,
    globalProps: global,
    collectiveMeta: collective.meta
  };
};

const mapDispatchToProps = dispatch => ({
  fetchExhibitions: (page, filterType, isGroup) => dispatch(fetchPrivateAndPublicExhibitionsInit(page, filterType, isGroup)),
  updateSortBy: (value) => dispatch(updateSortBy(value)),
  setExhibitionsLoader: (value) => dispatch(setExhibitionsLoader(value)),
  fetchCollectiveMetaInit: () => dispatch(fetchCollectiveMetaInit()),
  updateFavourite: () => dispatch(updateFavourite()),
  resetExhibitions: () => dispatch(resetExhibitions()),
  toggleFavouriteExhibition: (exhibitionId, isFavourite) => dispatch(toggleFavouriteExhibition(exhibitionId, isFavourite)),
});

export default connect(mapStatesToProps, mapDispatchToProps)(Exhibitions)
