import { connect } from 'react-redux'
import CollectivesDetails from './CollectivesDetails'
import './style.scss'
import {setRedirectPath} from "../../Global/redux/actions";
import {updateFavourite} from "../../Favourites/redux/actions";

const mapStatesToProps = (state) => ({
    globalProps: state.global,
})

const mapDispatchToProps = dispatch => ({
    setRedirectPath: (path) => dispatch(setRedirectPath(path)),
    updateFavourite: () => dispatch(updateFavourite()),
});

export default connect(mapStatesToProps, mapDispatchToProps)(CollectivesDetails)
