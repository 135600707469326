/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-sequences */
// import SendBirdSyncManager from 'sendbird-syncmanager';
import rudderstackEvents from "../services/rudderstack-events";

export const formatDate = (date, isEndDate = false, isFullMonth = false) => {
    const newDate = new Date(date);
    var month = [];
    month[0] = !isFullMonth ? "Jan" : "January";
    month[1] = !isFullMonth ? "Feb" : "February";
    month[2] = !isFullMonth ? "Mar" : "March";
    month[3] = !isFullMonth ? "Apr" : "April";
    month[4] = !isFullMonth ? "May" : "May";
    month[5] = !isFullMonth ? "Jun" : "June";
    month[6] = !isFullMonth ? "Jul" : "July";
    month[7] = !isFullMonth ? "Aug" : "August";
    month[8] = !isFullMonth ? "Sep" : "September";
    month[9] = !isFullMonth ? "Oct" : "October";
    month[10] = !isFullMonth ? "Nov" : "November";
    month[11] = !isFullMonth ? "Dec" : "December";
    const monthName = month[newDate.getMonth()];
    const newdate = newDate.getDate();
    const endYear = newDate.getFullYear();
    return isEndDate ? `${newdate} ${monthName} ${endYear}` : `${newdate} ${monthName}`;
};

function formatAMPM(date, dotted = true) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? (dotted ? 'p.m.' : 'pm') : (dotted ? 'a.m.' : 'am');
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' expected to be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

export const formatDateTime = (date) => {
    const newDate = new Date(date);
    var month = [];
    month[0] = "January";
    month[1] = "February";
    month[2] = "March";
    month[3] = "April";
    month[4] = "May";
    month[5] = "June";
    month[6] = "July";
    month[7] = "August";
    month[8] = "September";
    month[9] = "October";
    month[10] = "November";
    month[11] = "December";
    const monthName = month[newDate.getMonth()];
    const newdate = newDate.getDate();
    const year = newDate.getFullYear();
    const getHour = formatAMPM(newDate);
    return `${monthName} ${newdate}, ${year} • ${getHour} GMT`;
};

export const formatMessageDateTime = (date) => {
    const newDate = new Date(date);
    var month = [];
    month[0] = "January";
    month[1] = "February";
    month[2] = "March";
    month[3] = "April";
    month[4] = "May";
    month[5] = "June";
    month[6] = "July";
    month[7] = "August";
    month[8] = "September";
    month[9] = "October";
    month[10] = "November";
    month[11] = "December";
    const monthName = month[newDate.getMonth()];
    const newdate = newDate.getDate();
    const year = newDate.getFullYear();
    const hours = newDate.getHours();
    const minutes = newDate.getMinutes();
    return `${hours}:${minutes <= 9 ? '0' + minutes : minutes}\xa0\xa0•\xa0\xa0${newdate} ${monthName} ${year}`;
}

export const logout = (userId) => {
    localStorage.clear();
    rudderstackEvents.onLogout(userId)
    setTimeout(() => {
        window.location.href = "/";
    }, 100)
    // SendBirdSyncManager && SendBirdSyncManager.getInstance() && SendBirdSyncManager.getInstance().clearCache();
}

export const getIdFromSlug = (slug) => {
    if (!slug) {
        return;
    }
    try {
        let splitted = slug && slug?.split("-");
        if (splitted && splitted.length > 1) {
            return splitted[splitted.length - 1]
        } else {
            return slug;
        }
    }
    catch(err) {
        return slug;
    }

}
export const isEmailValid = (mail) => {
    return /^\S+@\S+\.\S+$/.test(mail) === true;
};

export const getShareURL = (type, id) => {
    const SHARE_APP = 'https://app.vortic.io/'
    return `${SHARE_APP}${type}/${id}`
};
export const isMobileDevice = () => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
    } else {
        return false
    }
}

export const IsSafari = () => {
    // @ts-ignore
    return /constructor/i.test(window.HTMLElement) || (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
        // @ts-ignore
    })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

}

export const isAndroidDevice = () => {
    var ua = navigator.userAgent.toLowerCase();
    var isAndroid = ua.indexOf("android") > -1;
    return isAndroid;
}

const isEventFull = (attendees) => {
    return attendees && attendees > 35;
}

const isInViewport = (el) => {
    const rect = el?.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)

    );
}

export const secondsToHms = (d) => {
    let sec_num = parseInt(d, 10);
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    let seconds = sec_num - (hours * 3600) - (minutes * 60);

    // if (hours < 10) {
    //     // @ts-ignore
    //     hours = "0" + hours
    // }
    // if (minutes < 10) {
    //     // @ts-ignore
    //     minutes = "0" + minutes;
    // }
    if (seconds < 10) {
        // @ts-ignore
        seconds = "0" + seconds;
    }
    // @ts-ignore
    const smHours = hours ;
    //!== "00" ? hours + ':' : '';
    return smHours + minutes + ':' + seconds;
}

export const toInches = (value) => {
    return (parseFloat(value) * 0.393701).toFixed(1);
};


const getUniqueId = () => {
    return Math.random().toString(36).substring(3);
}

export const constructImageUrl = (image, size="480", isWebp = true) => {
    if(image && image.data) {
        return image.data.CFURL + (size === 'raw' ? '' : size + '_') + image.data.name + (isWebp ? '.webp' : '') + image.data.signature;
    }
    else return '';
}

export const constructCFUrl = (image) => {
    if(image && image.data) {
        return image.data.CFURL + image.data.name + image.data.signature;
    }
    else return '';
}

export const constructHDRCFUrl = (image) => {
    if(image && image.data) {
        return image.data.CFURL + image.data.name + '.jpeg' + image.data.signature;
    }
    else return '';
}

const convertWavAudioToMP3 = (videoName) => {
    if(videoName.includes('.wav')) {
        videoName = videoName.replace('.wav', '.mp3');
    }
    return videoName;
}

export const constructAudioUrl = (audio) => {
    if(audio && audio.data) {
        return audio.data.CFURL + convertWavAudioToMP3(audio.data.name) + audio.data.signature;
    }
    else return '';
}

const getVideoNameWithProperExtension = (isMobile, videoName) => {
    if(videoName.includes('mov')) {
        videoName = videoName.replace('mov', 'mp4');
    }
    return !isMobile ? videoName.replace('mp4', 'webm') : videoName;
}

export const constructVideoUrl = (video) => {
    const isMobile = isMobileDevice() || window.innerWidth <= 768; // if mobile variant
    const size = !isMobile ? 'DESKTOP_1080_' : 'MOBILE_720_';
    if(video && video.data) {
        const videoName = video?.data?.name;
        return video.data.CFURL + size + getVideoNameWithProperExtension(isMobile, videoName) + video.data.signature;
    }
    else return '';
}

export const constructVideoUrlForPoster = (video, resType = null) => {
    const PREFIX_RES = {
        '720': 'DESKTOP_',
        '1080': 'DESKTOP_1080_',
        '4000': 'DESKTOP_4000_',
    }
    const isMobile = isMobileDevice() || window.innerWidth <= 768; // if mobile variant
    const size = !isMobile ? resType ? PREFIX_RES[resType] : PREFIX_RES['720'] : 'MOBILE_';
    if(video && video.data) {
        const videoName = video?.data?.name;
        return video.data.CFURL + size + getVideoNameWithProperExtension(isMobile, videoName) + video.data.signature;
    }
    else return '';
}

const getCurrencyString = (currency) => {
    switch(true){
        case currency.includes('$'):
            return 'USD';
        case currency.includes('€'):
            return 'EUR'
        case currency.includes('£'):
            return 'GBP';
        default:
            return 'GBP';
    }
}

export const formatePrice = (price, currency) => {
    if(price !== "" && !!price){
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: getCurrencyString(currency),
            maximumFractionDigits: 0
        }).format(price);
    }
    else return "";
}

export const isHTML = (str) => {
    var a = document.createElement('div');
    a.innerHTML = str;

    for (var c = a.childNodes, i = c.length; i--; ) {
      if (c[i].nodeType == 1) return true;
    }

    return false;
}

export const getArtworkStateStatusClass = (status) => {
    let resultState;
    switch(status) {
        case "available":
        case "price on application":
            resultState = "available";
            break;
        case "on loan":
            resultState = "on-loan";
            break;
        default:
            resultState = status;
            break;
    }
    return resultState;
  }

export const isTypeMuseum = (galleryType) => {
    return galleryType !== "museum";
}

export const getArtworkStateStatus = (status, isForSale) => {
    return (status === "for sale" || isForSale) && (status === "available" || status === "price on application");
}

const scrollToElm = (container, elm, duration) => {
    var pos = getRelativePos(elm);
    // @ts-ignore
    scrollTo( container, pos.top , 2);  // duration in seconds
  }

  function getRelativePos(elm){
    var pPos = elm.parentNode?.getBoundingClientRect(), // parent pos
        cPos = elm?.getBoundingClientRect(), // target pos
        pos = {};

        // @ts-ignore
        pos.top = cPos.top - pPos.top + elm.parentNode.scrollTop,
        // @ts-ignore
        pos.right = cPos.right - pPos.right,
        // @ts-ignore
        pos.bottom = cPos.bottom - pPos.bottom,
        // @ts-ignore
        pos.left = cPos.left - pPos.left;

    return pos;
  }

  function scrollTo(element, to, duration, onDone) {
      var start = element.scrollTop,
          change = to - start,
          startTime = performance.now(),
          val, now, elapsed, t;

      function animateScroll(){
          now = performance.now();
          elapsed = (now - startTime)/1000;
          t = (elapsed/duration);

          element.scrollTop = start + change * easeInOutQuad(t);

          if( t < 1 )
              window.requestAnimationFrame(animateScroll);
          else
              onDone && onDone();
      };

      animateScroll();
  }

  function easeInOutQuad(t){ return t<.5 ? 2*t*t : -1+(4-2*t)*t };

  export const truncateText = (text, maxLength = 20, appendedChild) => {
    if (!text) return '';  // Return an empty string if text is undefined or null
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...' + appendedChild; 
    }
    return text;
  }