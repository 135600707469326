import React from "react"
import ArtIcon from '../../assets/icons/Untitled-art.svg'
import ArtDarkIcon from '../../assets/icons/Untitled-art-dark.svg'
import Logo from '../../assets/icons/vortic-brigth-logo.svg'
import DarkLogo from '../../assets/icons/vortic-logo.svg'
import InfoIcon from '../../assets/icons/info-icon.svg'
import ListIcon from '../../assets/icons/list.svg'
import {IProps} from './types'
import './style.scss'

export const UntitledHeader = ({variant = 'light', className = '', handleMenu, logoOnly = true, handleInfoClick = null, gradientBackground = false}: IProps) => {
  let artIcon = ArtIcon
  let logoIcon = Logo
  let lineBG = 'bg-white'

  if (variant === 'dark') {
    artIcon = ArtDarkIcon
    logoIcon = DarkLogo
    lineBG = 'bg-black'
  }

  return (
    <div className={`untitled-header ${!logoOnly && 'space-between' } ${gradientBackground ? 'untitled-header--gradient-background' : ''} ${className}`}>
      {!logoOnly && <div className='info-icon-container'><img className='info-icon' src={InfoIcon} alt="Art Icon" onClick={handleInfoClick} /></div>}
      <div className='content'>
        <img src={artIcon} alt="Art Icon" className='art-icon'/>
        <div className={`line ${lineBG}`}/>
        <img src={logoIcon} alt="vortic Icon" className='vortic-icon'/>
      </div>
      {
        !logoOnly && (
          <div className='circle-icon' onClick={handleMenu}>
            <img src={ListIcon} alt="Art Icon"/>
          </div>
        )
      }
    </div>
  )
}