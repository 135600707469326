// @ts-nocheck
import {ofType} from 'redux-observable';
import {flatMap, map, catchError} from 'rxjs/operators';

import {FETCH_SEARCH_INIT} from './actionTypes'
import * as API from "../../../services/api"
import {fetchSearchSuccess} from './actions';
import logger from '../../../utils/logger';

const fetchSearch = action$ =>
    action$.pipe(
        ofType(FETCH_SEARCH_INIT),
        flatMap(({payload}) => API.fetchSearch(payload.searchQuery, payload.page, payload.loadingMore)),
        // @ts-ignore
        map((resp: IResponseType) => {
            logger.debug('fetchSearch epics pipe map resp = ', resp);
            if  (resp) {
                return fetchSearchSuccess(resp);
            }
        }),
        // @ts-ignore
        catchError(error => {
            logger.error('error = ', error);
        })
    );


export default [
    fetchSearch,
]
