import React from "react";
import { Title, Paragraph } from "../Typography2";
import Button from "../Button";
import { IDiscoverCardProps } from "./types";

const DiscoverCard:React.FC<IDiscoverCardProps> = ({ variant, handleBtnClick, title, description, imgUrl, btnLabel, galleryName }) => {
  return (
    <>
      {
        imgUrl && (title || description) && 
        <div  className={"discover-card" + (variant === "primary" ? " discover-card--primary" : "")}>
          <div className="discover-card__image" onClick={handleBtnClick}>
            <img src={imgUrl} alt="discover" />
          </div>
          <div className="discover-card__content">
            <Title value={title} />
            {!!galleryName && <Paragraph className="gallery-name" value={galleryName} />}
            <Paragraph value={description} />
            <Button
              value={btnLabel}
              className="btn__dark"
              type="secondary"
              onClick={handleBtnClick}
            />
          </div>
        </div>
      }
    </>
  );
};

export default DiscoverCard;
