// @ts-nocheck
import React, { useState, useRef, useEffect } from "react";
import { Paragraph, Title, Heading } from "../../Typography2";
import Button from "../../Button";
import { EnterPin, generateAvatar, retrieveUserAvatar } from "../../../services/api";
import { useSelector } from 'react-redux'
import StepsHeader from "../StepsHeader";
import "./style.scss";
import { uploadToS3 } from "../../../services/s3Upload";
import {
  updateProfileImage,
  resetPassword,
  getProfileImageUploadURL,
} from "../../../services/api";
import avatar from "../../../assets/images/avatar-2.png";
import Avatar from "../../../assets/images/Avatar-1.png";
import Images from "../../../assets/images/Avatar-3.png";
import Rendered from "../../../assets/images/rendered-avatar.png";
import File from "../../../assets/icons/file-up.svg";
import AvatarCropper from "../AvatarCropper";
import Spinner from "../../../components/Spinner";
import ModelViewer from "../../3dArtworkViewer/Viewer";

const initialState = {
  firstNum: "",
  secondNum: "",
  thirdNum: "",
  forthNum: "",
};

const CreateAvatar = () => {
  const [isInvalid, setInvalid] = useState(false);
  const [code, setCode] = useState(initialState);
  const [success, setSuccess] = useState(false);
  const inputsNames = Object.keys(initialState);
  const inputRefs = useRef(new Array());
  const buttonRef = useRef<HTMLButtonElement>(null);
  const finalIndex = inputsNames.length - 1;
  const [step, setStep] = useState(1);
  const [errMsg, setErrMsg] = useState("");
  const [userId, setUserId] = useState("");
  const [img, setImg] = useState("");
  const [photo, setPhoto] = useState("");
  const [gender, setGender] = useState("");
  const [avatarUrl, setAvatarUrl] = useState("");
  const [avatarInProg, setAvatarInProg] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [croppedImg, setCroppedImg] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [userName, setUserName] = useState("");
  const [photoFile, setPhotoFile] = useState({
    type: "",
  });

  useEffect(() => {
    if(!!gender && !!img) {
      setIsFormValid(true);
    }
  }, [gender, img])

  const editorRef = useRef(null);
  const userData = useSelector((state) => state.profile);

  useEffect(() => {
    setUserId(userData?.profileData?.id);
    setUserName(userData?.profileData?.name);
  }, [userData])

  function dataURLtoFile(dataURL, filename) {
    // Split the data URL into the data and metadata parts
    const parts = dataURL.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);

    // Convert the raw binary data to a Uint8Array
    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    // Create a Blob object from the Uint8Array
    const blob = new Blob([uInt8Array], { type: contentType });

    // Create a File object from the Blob
    blob.lastModifiedDate = new Date();
    blob.name = filename;
    return blob;
  }

  const handleImageUpload =  async (e: any) => {
    setImg(e.target.files[0]);
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile =  e.target.files[0];
      const dot = selectedFile.name.lastIndexOf(".");
      const extension = selectedFile.name
        .substr(dot, selectedFile.name.length)
        .toLowerCase();
      if (
        extension === ".jpeg" ||
        extension === ".jpg" ||
        extension === ".png"
      ) {
        const newFile = croppedImg ? dataURLtoFile(croppedImg, selectedFile.name) : selectedFile
        setPhotoFile(newFile || selectedFile);
        const url = await URL.createObjectURL(newFile || selectedFile);
        setPhoto(url);
        setErrMsg("");
      } else {
        setErrMsg(
          "Only file with the extension of jpeg, jpg, png and gif are allowed!"
        );
        setTimeout(() => {
          setErrMsg("");
        }, 2000);
      }
    }
  };

  const updateImage = async () => {
    const newFile = croppedImg ? dataURLtoFile(croppedImg, img.name) : img;
    setPhotoFile(newFile || img);
    const url = await URL.createObjectURL(newFile || img);
    setPhoto(url);
  }

  useEffect(() => {
    if(croppedImg && img){
      updateImage();
    }
  }, [croppedImg, img])

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (value && value.trim() !== "") {
      const index = inputsNames.indexOf(name);
      setCode({
        ...code,
        [name]: value,
      });
      if (index < finalIndex) {
        inputRefs.current[index + 1].focus();
      } else {
        buttonRef.current.focus();
      }
    }
  };
  const handleSubmit = async (e) => {
    const pin = Object.values(code).join("");
    const resp = await EnterPin({ pin });
    if (resp) {
      setSuccess(true);
    } else {
      setInvalid(!isInvalid);
    }
    e.preventDefault();
  };

  const handleFocus = (event) => event.target.select();

  const handleRetrieveAvatar = async () => {
    const avatar = await retrieveUserAvatar();
    if(avatar){
      if(avatar.url) {
        setStep(4);
        setAvatarUrl(avatar.url);
        setLoading(false);
      } else if(avatar.avatarProgress < 100) {
        // setAvatarInProg(true);
        setStep(1);
        setAvatarInProg(true);
        setLoading(false);
      }
    } else {
      setStep(1);
      setLoading(false);
    }
  }

  const handleCreateAvatar = async (assetKey) => {
    if(userId) {
      const resp = await generateAvatar(userId, assetKey, gender);
    }
  }

  useEffect(() => {
    handleRetrieveAvatar();
  }, [])

  const handleUpdate = async (event) => {
    event.preventDefault();
    if(isFormValid) {
      if (img) {
        setStep(3);
        let fileType = photoFile.type;
        fileType = fileType.split('/');
        fileType = fileType[1];
        const fileName = `${Date.now()}.${fileType}`;

        const response = await getProfileImageUploadURL({ extension: fileType });
        if (response && response.url) {
          const uploadedResp = await uploadToS3(response.url, photoFile, fileType);
          if (uploadedResp) {
            handleCreateAvatar(response.key);
          }
        }
      }
      else {
        setStep(4);
      }
    }
  };

  return (
    <div className="create-avatar">
      {isLoading ?
      <Spinner />
      :
        <>
          {step === 1 ? (
            <StepsHeader title="Hello," titleHighlight={userName || ''} />
          ) : (
            <StepsHeader
              title="Create your avatar... "
              paragraph="Customise your look so others can recognise you in virtual reality."
            />
          )}

          {step === 1 && (
            <div className="step-card-wrapper">
              <div className="step-card-right">
                <Heading className="step-heading">
                  {" "}
                  <span>STEP 01:</span> {!avatarInProg ? 'Create your avatar' : 'Update your avatar'}
                </Heading>
                <Paragraph className="title-description">
                  {!avatarInProg ?
                  <>Before you enter VR, create a customised avatar that represents
                  how you look to other visitors in <span>Vortic VR. </span>{" "}</>
                  :
                  <>
                  <>Edit your customised avatar that represents how you look to other visitors in <span>Vortic VR. </span>{" "}</>
                  </>
                  }
                </Paragraph>
                <Button
                  value="Get Started"
                  className="btn-dark"
                  iconType="next-step"
                  type="secondary"
                  onClick={() => setStep(2)}
                />
              </div>
              <div className="step-card">
                <Heading className="step-heading">
                  {" "}
                  <span>STEP 02: </span> Log in to your Oculus headset
                </Heading>
                <Paragraph className="title-description">
                  To log into VR, enter the four-digit pin shown in the Vortic app
                  in your headset.
                </Paragraph>
                {!success ? (
                  <>
                    <div className="step-pin">
                      <input
                        type="text"
                        name="firstNum"
                        min="0"
                        max="9"
                        ref={(elm) => (inputRefs.current[0] = elm)}
                        value={code.firstNum}
                        onFocus={handleFocus}
                        onChange={handleInputChange}
                        autoFocus
                      />
                      <input
                        type="text"
                        name="secondNum"
                        min="0"
                        max="9"
                        ref={(elm) => (inputRefs.current[1] = elm)}
                        value={code.secondNum}
                        onFocus={handleFocus}
                        onChange={handleInputChange}
                      />
                      <input
                        type="text"
                        name="thirdNum"
                        min="0"
                        max="9"
                        ref={(elm) => (inputRefs.current[2] = elm)}
                        value={code.thirdNum}
                        onFocus={handleFocus}
                        onChange={handleInputChange}
                      />
                      <input
                        type="text"
                        name="forthNum"
                        min="0"
                        max="9"
                        ref={(elm) => (inputRefs.current[3] = elm)}
                        value={code.forthNum}
                        onFocus={handleFocus}
                        onChange={handleInputChange}
                      />
                    </div>
                    <Button
                      value="Pair Headset"
                      className="btn-dark"
                      iconType="next-step"
                      type="secondary"
                      onClick={handleSubmit}
                    />
                  </>
                ) : (
                  <div>
                    <Heading value="Please put on your headset to continue" />
                  </div>
                )}
              </div>
            </div>
          )}
          {/* step 2 */}
          {step === 2 && (
            <div>
              <div className="step-2">
                <div className="step-2-card">
                  <Heading className="step-heading">STEP 01:</Heading>
                  <Paragraph className="description">
                    <span>First things first:</span> You’ll need to upload a photo
                    of yourself against a white background, similar to a passport
                    photo.
                  </Paragraph>
                  {img &&
                    <AvatarCropper croppedImg={croppedImg} setCroppedImg={setCroppedImg} img={img} ref={editorRef} />
                  }
                  <div className="input-file">
                    <input
                      type="file"
                      id="file"
                      onChange={handleImageUpload}
                      accept="image/png, image/jpeg, image/jpg"
                    />
                    <label htmlFor="file">
                      <img src={File} alt="" />
                      {img ? "Upload New Photo" : "Upload Photo"}
                    </label>
                  </div>
                  <div>
                    <img src={Avatar} alt="" />
                    <img src={Images} alt="" />
                  </div>
                  <div>
                    <Heading className="tips">
                      Tips for a good avatar photo:
                    </Heading>
                    <ul>
                      <li>
                        <Paragraph className="tips-content">
                          {" "}
                          Try and use a plain white background where possible.
                        </Paragraph>
                      </li>
                      <li>
                        <Paragraph className="tips-content">
                          {" "}
                          Position yourself to look straight ahead at the camera.
                        </Paragraph>
                      </li>
                      <li>
                        <Paragraph className="tips-content">
                          {" "}
                          Place your head in the middle of the shot.
                        </Paragraph>
                      </li>
                    </ul>
                  </div>
                </div>
                <div id="step" className="step-2-card">
                  <Heading className="step-heading">STEP 02:</Heading>
                  <Paragraph className="description">
                    <span> Next up:</span> If you’re happy to, please let us know
                    how you identify.
                  </Paragraph>
                  <div className="custom-select">
                    <select value={gender} onChange={(e) => setGender(e.target.value)}>
                      <option value="" selected disabled hidden>Select</option>
                      <option value="non-binary">Non-binary</option>
                      <option value="female">Female</option>
                      <option value="male" selected={true}>Male</option>
                    </select>
                  </div>
                </div>
                <div className="step-2-card">
                  <Heading className="step-heading">STEP 03:</Heading>
                  <Paragraph className="description">
                    <span> Finally:</span> Hit the button, we’ll generate a custom
                    avatar based on your photo.
                  </Paragraph>
                  <Button
                    value="Generate Avatar"
                    className={`btn-dark ${isFormValid ? '' : 'btn-disabled'}`}
                    iconType="next-step"
                    type="secondary"
                    onClick={(e) => {
                      handleUpdate(e);
                      // setAvatarInProg(true);
                    }}
                    // setStep(3)
                  />
                </div>
              </div>
            </div>
          )}

          {/* step */}

          {step === 3 && (
            <div className="step-3">
              <div className="step-wrapper">
                <div className="step-3-wrapper">
                  <Heading className="step-heading">
                    <span>STEP 04:</span> Please wait, Your avatar is in processing ...
                  </Heading>
                </div>
                <div className="step-3-img">
                  <img src={Rendered} alt="rendered" />
                </div>
              </div>
            </div>
          )}

          {step === 4 && (
            <div className="step-3">
              <div className="step-wrapper">
                <div className="step-3-wrapper">
                  <Heading className="step-heading">
                    <span>STEP 05:</span> Review your avatar
                  </Heading>
                  <Paragraph className="step-content">
                    This is how you’ll appear in Vortic VR to other visitors.Try dragging your avatar left and right to take a spin.
                  </Paragraph>
                  <>
                    <Heading className="tips">Happy with how you look?</Heading>
                    <div className="btn">
                      <Button
                        value="Yes, let’s get in VR"
                        className="btn-dark"
                        iconType="next-step"
                        type="secondary"
                        onClick={() => setStep(1)} />
                      <button className="step_heading" onClick={() => setStep(2)}>Try a different photo</button>
                    </div>
                  </>
                </div>
                <div className="step-3-img">
                  {(avatarUrl && <ModelViewer id={''} modelUrl={avatarUrl} />)}
                </div>
              </div>
            </div>
          )}
        </>
      }
    </div>
  );
};

export default CreateAvatar;
