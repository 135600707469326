import React, {Fragment, useEffect, useState} from 'react';
import Spinner from "../../components/Spinner";
import {Title2, Paragraph} from '../../components/Typography2';
import SearchResult from "../../components/SearchResult";
import SearchIcon from "../../assets/icons/search.svg"
import useInfiniteScroll from "react-infinite-scroll-hook";
import { constructImageUrl } from '../../utils';
import { useTrackingCode } from "react-hubspot-tracking-code-hook";

const Search = ({
                    resetInput,
                    searchQuery,
                    resetSearchRecords,
                    fetchSearchInit,
                    recordsLoading,
                    currentPage,
                    records,
                    canLoadMore,
                    changeSearch,
                    recentSearch,
                    setRecentSearch
                }) => {
    const [isloadingMore, setIsLoadingMore] = useState(false);
    const [currentVal, setCurrentVal] = useState('');
    const { setContentType } = useTrackingCode();

    useEffect(() => {
    }, [currentVal])

    useEffect(() => {
        setIsLoadingMore(false)
        resetSearchRecords();
        if (searchQuery) {
            setRecentSearch(searchQuery);
        }
    }, [searchQuery]);

    useEffect(() => {
        if (recentSearch) {
            handleSearchChange(searchQuery);
        }
    }, [recentSearch]);

    const handleSearchChange = (value) => {
        changeSearch(value);
    };

    useEffect(() => {
        setContentType('search-page');
        if (searchQuery === "" && recentSearch) {
            handleSearchChange(recentSearch);
        }
        return () => {
            resetInput();
            resetSearchRecords();
        }
    }, [])

    useEffect(() => {
        setIsLoadingMore(false)
    }, [records?.galleries?.data?.length, records?.exhibitions?.data?.length, records?.artists?.data?.length , records?.curators?.length])

    const loadSearch = (_currentPage, loadingMore, _canLoadMore) => {
        if (searchQuery.length > 2 && !isloadingMore && _canLoadMore) {
            setIsLoadingMore(true)
            const nextPage = _currentPage + 1;
            fetchSearchInit(searchQuery, nextPage, loadingMore)
        }
    };

    const getResultsLength = () => {
        return (records?.galleries?.data?.length || 0) + (records?.exhibitions?.data?.length || 0) + (records?.artists?.data?.length || 0) + (records?.curators?.length || 0);
    }

    const infiniteRef = useInfiniteScroll({
        loading: isloadingMore,
        hasNextPage: canLoadMore,
        onLoadMore: () => loadSearch(currentPage, isloadingMore, canLoadMore),
    });

    return (
        <div className="card-wrapper">

            <label className="search_bar">
                <input value={searchQuery} onChange={(e) => {
                    setCurrentVal(e.target.value);
                }} type="text" placeholder="Search artists, artwork, galleries, exhibitions"/>
                <div className="icon">
                    <img src={SearchIcon} alt="Search"/>
                </div>
            </label>

            {
                !searchQuery || searchQuery.length < 3 ?
                    <div>
                        <Paragraph className="align-center" value={`Nothing to search.`}/>
                    </div>
                    :
                    getResultsLength() === 0 && !recordsLoading ?
                        <div>
                            <Paragraph className="align-center" value={`No records found.`}/>
                        </div>
                        :
                        <Fragment>
                            <div className=" ">
                                <div className="search-wrapper borderBottom ">
                                    <div className="searh-head container">
                                        <Title2 value={`Showing ${getResultsLength()} results for '${searchQuery}'`}/>
                                    </div>
                                </div>
                            </div>
                            <div className="card-description">
                                {/*@ts-ignore*/}
                                <div ref={infiniteRef} className="">
                                    {
                                        records?.galleries?.data?.map(record => {
                                            return (
                                                <SearchResult
                                                    imgSrc={record.data.image && [constructImageUrl(record.data.image,"300"), constructImageUrl(record.data.image,"300",false)]}
                                                    title={record.data.name}
                                                    subtitle={record.data.locations}
                                                    description={record.data.overview}
                                                    slug={record.data.slug}
                                                    id={record.data.id}
                                                    type="galleries"
                                                />)
                                        })
                                    }
                                    {
                                        records?.artists?.data?.map(record => {
                                            return (
                                                <SearchResult
                                                    imgSrc={record.data.image && [constructImageUrl(record.data.image,"300"), constructImageUrl(record.data.image,"300",false), record.data.image.data.signedUrl]}
                                                    title={record.data.name}
                                                    subtitle={record.data.organisation.data.name}
                                                    description={record.data.description}
                                                    id={record.data.id}
                                                    slug={record.data.slug}
                                                    uuid={record.data.uuid}
                                                    type="artists"
                                                />)
                                        })
                                    }
                                    {
                                        records?.exhibitions?.data?.map(record => {
                                            return (
                                                <SearchResult
                                                    imgSrc={record.data.image && [constructImageUrl(record.data.image,"300"), constructImageUrl(record.data.image,"300",false), record.data.image.data.signedUrl]}
                                                    title={record.data.name}
                                                    subtitle={ record.data?.organisation?.data?.type === "vortic" ? (record.data?.curator?.data?.title && `Vortic Curated x ${record.data?.curator?.data?.title}`) : (record.data.organisation && record.data.organisation.data.name)}
                                                    description={record.data.description}
                                                    id={record.data.id}
                                                    slug={record.data.slug}
                                                    uuid={record.data.uuid}
                                                    type="exhibitions"
                                                />)
                                        })
                                    }
                                    {
                                        records?.curators?.map(record => {
                                            return (
                                                <SearchResult
                                                    imgSrc={record.data.image && [constructImageUrl(record.data.image,"300"), constructImageUrl(record.data.image,"300",false), record.data.image.data.signedUrl]}
                                                    title={record.data.title}
                                                    subtitle={(record.data.organisation && record.data.organisation.data.name)}
                                                    description={record.data.description}
                                                    uuid={record.data.uuid}
                                                    id={record.data.id}
                                                    type="curators"
                                                />)
                                        })
                                    }
                                    {
                                        isloadingMore &&
                                        <Spinner/>
                                    }
                                </div>
                            </div>
                        </Fragment>
            }
        </div>
    )
};
export default Search;
