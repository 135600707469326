import React, {useEffect, useState} from 'react';
import {IExploreVorticProps} from './types';
import ImgItem from './ImgItem';

const ExploreVortic: React.FC<IExploreVorticProps> = ({exploreData}) => {
    // @ts-ignore
    const [data, setData] = useState([]);

    useEffect(() => {
        if (exploreData) {
            const dataArray = [];
            const {exhibitions, institutions, galleries, collectives} = exploreData;
            dataArray[0] = {data: exhibitions, title: 'exhibitions',};
            dataArray[1] = {data: institutions, title: 'institutions'};
            dataArray[2] = {data: galleries, title: 'galleries'};
            // TODO: made collaborations hidden
            // dataArray[3] = {data: collectives, title: 'collaborations'};
            setData(dataArray)
        }
    }, [exploreData])
    
    return (
        <div className={'explore-container'}>
            {
                !!exploreData &&
                <div>
                    <div className={'explore-boxes'}>
                        <ImgItem dataArray={data && data}/>
                    </div>
                </div>
            }
        </div>
    );
};

export default ExploreVortic;
