import React, { useEffect, useState } from 'react';
import IslandLoadingNav from '../IslandLoadingNav/IslandLoadingNav';
import IslandControlsNav from '../IslandControlsNav/IslandControlsNav';

const ControlsToLoading = ({ variant, progress = null, handleNext, handlePrev, isMobileControlsNav = false, handleMenu  }) => {

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let loadedTimeout;
        if(progress >= 0 && progress < 100) {
            setIsLoading(true);
        }
        else {
            loadedTimeout = setTimeout(() => {
                setIsLoading(false);
            }, 1500)
        }
        return () => {
            clearInterval(loadedTimeout);
        }
    }, [progress])

    return (
        <div className={`island-nav ${`island-nav--${variant}`} ${isLoading ? 'controls-loading' : ''} ${isMobileControlsNav ? 'island-nav--controls-mobile-view' : ''}`}>
            <div className={`island-controls-to-loading-nav-content ${progress < 100 ? '' : 'loaded'}`}>
                <IslandControlsNav  handleNext={handleNext} handlePrev={handlePrev} isMobileControlsNav={isMobileControlsNav} handleMenu={handleMenu} />
                <IslandLoadingNav progress={progress} loading={progress < 100} />
            </div>
        </div>
    );
}

export default ControlsToLoading;
