import {connect} from "react-redux";
import SignUp from "./SignUp";
import {
    getUpdatedProfileDataInit,
} from "../Profile/redux/actions";
import {setIsError} from "../Global/redux/actions";


const mapStatesToProps = ({ profile }) => {
    return {
        profileData: profile.profileData,
    }
}
const mapDispatchToProps = dispatch => ({
    setIsError: (val,msg) => {dispatch(setIsError(val,msg))},
    getUpdatedProfileDataInit: () => { dispatch(getUpdatedProfileDataInit()) },
})

export default connect(mapStatesToProps,mapDispatchToProps)(SignUp)
