import React from 'react';
import FeatureCard from '../FeatureCard';
import { IFeatureCardListProps } from './types';
import { constructImageUrl } from '../../utils'
import "./style.scss";
import { SubHeading } from '../Typography2/Typography2';

const FeatureCardList: React.FC<IFeatureCardListProps> = ({ title, card, cards, type, variant = 'primary' }) => {

  const handleClick = (id) => {
    window.open(`/${type}/${id}`, "_blank").focus();
  };  
  
  return (
    <div className="feature-card-list">
      <SubHeading className="feature-card-list__title" value={title} />
      <div className="feature-card-list-container">
        {card && (
            <FeatureCard
              title={card.name}
              content={card.description}
              image={card.image}
              slug={card.slug}
              id={card.id}
              location={card.location}
              handleClick={handleClick}
              variant={variant}
            />
        )}
        {cards?.map((card, index) => {
          if(type === "curators") {
            // @ts-ignore
            card = card?.data?.curator
          }
          return (
            <FeatureCard
              key={index}
              title={type === "curators" ? card.data.title : (card.data.name)}
              content={card.data.description}
              image={card.data.image}
              slug={card.data.slug}
              id={card.data.id}
              handleClick={handleClick}
              variant={variant}
            />
          )
        })}
      </div>
    </div>
  );
};

export default FeatureCardList;
