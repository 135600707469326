import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from "react-router";
import {
    favouriteArtists,
    fetchArtistArtworks,
    favouriteExhibition,
    fetchArtistDetails,
    fetchArtistExhibitions
} from "../../services/api";
import DetailsCard from "../../components/DetailsCard";
import { IArtistDetails } from "./types";
import Exhibition from "../../components/Exhibition";
import Spinner from "../../components/Spinner";
import Carousel from "../../components/Carousel";
import { constructImageUrl, getIdFromSlug } from "../../utils";
import { P1 } from "../../components/Typography";
import { Heading, Label, Paragraph } from "../../components/Typography2";
import DetailsHeader from "../../components/DetailsHeader";
import ScrollAnimation from '../../components/ScrollAnimation/ScrollAnimation'
import ArrowDownDark from "../../assets/icons/down-dark.svg";
import logger from "../../utils/logger";
import HeadTags from "../../components/Helmet/HeadTags";
import rudderstackEvents from "../../services/rudderstack-events";
import RenderHtml from "../../components/CollateralLayouts/RenderHtml";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";

const ArtistDetails = ({ updateFavourite, globalProps, setArtworkIds, setArtworkId, preview = false }) => {

    const initialDetails = {
        data: {
            id: null,
            biography: '',
            name: '',
            slug: '',
            description: '',
            descriptionHtml: '',
            isFavourite: false,
            image: {
                data: null
            },
            about: null,
            website: '',
            twitter: '',
            instagram: '',
            facebook: '',
        }
    };

    const { id: artistId } = useParams();
    const navigate = useNavigate();
    const [details, setDetails] = useState<IArtistDetails>(initialDetails);

    const [showAllDetails, toggleAllDetails] = useState(false);
    const [exhibitions, setExhibitions] = useState([])
    const [isLoadingDetails, setDetailsLoading] = useState(true)
    const [artworks, setArtworks] = useState([])
    const [isLoadingExhibitions, setExhibitionsLoading] = useState(true)
    const [isLoadingArtworks, setArtworksLoading] = useState(true)
    const carouselRef = useRef(null);
    const [isHover, setIsHover] = useState(true);
    const [carouselState, setCarouselState] = useState(0);
    const [currentExhibitions, setCurrentExhibitions] = useState(null);
    const [pastExhibitions, setPastExhibitions] = useState(null);
    const { setContentType } = useTrackingCode();


    useEffect(() => {
        const tempExhibitions = [...exhibitions];
        const currentExhibs = [];
        const pastExhibs = [];
        tempExhibitions.map(exhibition => {
            if (exhibition.data.isLive) {
                currentExhibs.push(exhibition);
            }
            else {
                pastExhibs.push(exhibition);
            }
        })

        setCurrentExhibitions(currentExhibs);
        setPastExhibitions(pastExhibs);
    }, [exhibitions])

    //@ts-ignore
    // const artistId = route && route.params && route.params.id;

    useEffect(() => {
        if (globalProps.artworkId) {
            var arr = [];
            artworks.map(item => arr.push(item.data.id));
            setArtworkIds([...arr]);
        }
    }, [globalProps.artworkId])

    const loadArtistDetails = async () => {
        return new Promise(async (resolve, reject) => {
            const artistDetails = await fetchArtistDetails(artistId, preview);
            // const artistDetails = await fetchArtistDetails(artistId);
            if (artistDetails.data) {
                setDetailsLoading(false)
                setDetails(artistDetails)
                // @ts-ignore
                rudderstackEvents.mount({galleryId: artistDetails.data?.organisation?.data?.id, galleryName: artistDetails.data?.organisation?.data?.name})
                resolve(true)
            }
            else {
                resolve(true)
            }
        })
    };

    const loadArtistArtworks = async () => {
        return new Promise(async (resolve, reject) => {
            const artworksResp = await fetchArtistArtworks(artistId, preview);
            // const artworksResp = await fetchArtistArtworks(getIdFromSlug(artistId));
            if (artworksResp.data) {
                setArtworksLoading(false);
                setArtworks(artworksResp.data);
                resolve(true)
            }
            else {
                resolve(true)
            }
        })
    };

    const loadArtistExhibitions = async () => {
        const exhibitionsResp = await fetchArtistExhibitions(artistId, preview);
        // const exhibitionsResp = await fetchArtistExhibitions(getIdFromSlug(artistId));
        if (exhibitionsResp.data) {
            setExhibitionsLoading(false);

            setExhibitions(exhibitionsResp.data);
        }
    };

    const fetchData = async () => {
        const detailsResp = await loadArtistDetails();
        if (detailsResp) {
            const exhibsResp = await loadArtistArtworks();
            if (exhibsResp) {
                loadArtistExhibitions();
            }
        }
    };

    useEffect(() => {
        setContentType('artist-details-page');
        fetchData();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const handleExhibitionClick = (id) => {
        navigate(`/exhibitions/${id}`)
    };

    const toggleFavourite = async () => {
        if (globalProps.isLoggedIn) {
            let resp = await favouriteArtists(details.data.id)
            if (resp.data) {
                updateFavourite();

                setDetails({
                    ...details,
                    data: {
                        ...details.data,
                        isFavourite: resp.data.isFavourite
                    }
                })
            }
        }
        else {
            navigate(`/login?/artists/${artistId}`)
        }
    }

    const toggleExhibitionFavourite = async (exhibitionId) => {
        logger.debug('globalProps = ', globalProps);
        if (globalProps.isLoggedIn) {
            let resp = await favouriteExhibition(exhibitionId)
            if (resp.data) {
                let newExhibitions = [];
                exhibitions.map(exhibition => {
                    if (exhibition.data.id === exhibitionId) {
                        exhibition = {
                            ...exhibition,
                            data: {
                                ...exhibition.data,
                                isFavourite: resp.data.isFavourite
                            }
                        }
                    }
                    newExhibitions.push(exhibition)
                    return exhibition;
                })
                setExhibitions(newExhibitions)
            }
        }
        else {
            navigate(`/login?/artists/${artistId}`)
        }
    }

    const handleArtworkClick = (artworkId, _data) => {
        setArtworkId(artworkId);
        rudderstackEvents.onArtworkClick(artworkId, _data?.data?.uuid)
    }

    return (
        <>
            {
                (isLoadingDetails === false) ? (
                    <div className="container details artists-details-wrapper">
                        <HeadTags
                            title={details.data.name}
                            description={details.data.description}
                            image={constructImageUrl(details.data.image,"350")}
                            type={'artist'}
                            id={details.data.id}
                        />
                        <div>
                            <div>
                                <DetailsCard
                                    id={details.data.id}
                                    type="artist"
                                    slug={details.data.slug}
                                    title={details.data.name}
                                    biography={details.data.biography}
                                    toggleFavourite={toggleFavourite}
                                    imgSrc={details.data.image && details.data.image.data && [constructImageUrl(details.data.image,"1024"), constructImageUrl(details.data.image,"1024",false)]}
                                    description={details.data.description}
                                    isFavourite={details && details.data.isFavourite}
                                    instagramLink={details?.data?.instagram}
                                    facebookLink={details?.data?.facebook}
                                    twitterLink={details?.data?.twitter}
                                    websiteLink={details?.data?.website}
                                />
                            </div>
                            <ScrollAnimation>
                                {details?.data?.descriptionHtml && <RenderHtml data={details?.data?.descriptionHtml} />}
                            </ScrollAnimation>
                            <br /><br /><br />
                        </div>

                        {
                            isLoadingArtworks === false && artworks.length > 0 &&
                            <ScrollAnimation>
                                <div className="">
                                    <DetailsHeader
                                        title="Artworks" position={artworks.length && artworks.length > 1 && `${carouselState} / ${artworks.length}`}
                                        handleLeftClick={() => { (carouselRef && carouselRef.current && carouselRef.current.moveCarousel("left")) }}
                                        handleRightClick={() => { (carouselRef && carouselRef.current && carouselRef.current.moveCarousel("right")) }}
                                        setIsHover={(val) => setIsHover(val)}
                                        isHover={isHover}
                                    />
                                    {/*// @ts-ignore*/}
                                    {artworks.length > 0 && <Carousel onClickItem={(id, data) => handleArtworkClick(id, data)} type="secondary" data={artworks} setCarouselState={setCarouselState} ref={carouselRef} />}
                                </div>
                            </ScrollAnimation>
                        }
                        {
                            isLoadingExhibitions === false &&
                            <>
                                <div>
                                    <DetailsHeader title={"Current Exhibitions"} />
                                    {
                                        currentExhibitions.length > 0 ? currentExhibitions.map((exhibition, index) => {
                                            const { id, name, image, description, startedAt, endedAt, organisation, isFavourite, curator, slug, uuid, isDateVisible } = exhibition.data;
                                            return (
                                                <div key={index}>
                                                    <Exhibition
                                                        id={id}
                                                        slug={slug}
                                                        uuid={uuid}
                                                        onClick={() => handleExhibitionClick(slug || uuid)}
                                                        exhibitionImg={image && [constructImageUrl(image,"1920"), constructImageUrl(image,"1920",false)]}
                                                        title={name}
                                                        galleryName={ organisation?.data?.type === "vortic" ? (curator?.data?.title && `Vortic Curated x ${curator?.data?.title}`) : organisation?.data?.name}
                                                        subTitle={description}
                                                        startedAt={startedAt}
                                                        endedAt={endedAt}
                                                        locations={organisation && organisation.data.locations}
                                                        isFavourite={isFavourite}
                                                        toggleFavourite={toggleExhibitionFavourite}
                                                        isVorticCurate={organisation?.data?.type === "vortic"}
                                                        isDateVisible={isDateVisible}
                                                    />
                                                </div>
                                            )
                                        }) : (
                                            <>
                                                <P1 value="No Current Exhibitions Found." />
                                                <br />
                                                <br />
                                                <br />
                                            </>
                                        )
                                    }
                                </div>
                                <div>
                                    <DetailsHeader title={"Past Exhibitions"} />
                                    {
                                        pastExhibitions.length > 0 ? pastExhibitions.map((exhibition, index) => {
                                            const { id, name, image, description, startedAt, endedAt, organisation, isFavourite, curator, slug, uuid, isDateVisible } = exhibition.data;
                                            return (
                                                <div key={index}>
                                                    <Exhibition
                                                        id={id}
                                                        slug={slug}
                                                        uuid={uuid}
                                                        onClick={() => handleExhibitionClick(slug || uuid)}
                                                        exhibitionImg={image && [constructImageUrl(image,"1920"), constructImageUrl(image,"1920",false)]}
                                                        title={name}
                                                        galleryName={ organisation?.data?.type === "vortic" ? (curator?.data?.title && `Vortic Curated x ${curator?.data?.title}`) : organisation?.data?.name}
                                                        subTitle={description}
                                                        startedAt={startedAt}
                                                        endedAt={endedAt}
                                                        locations={organisation && organisation.data.locations}
                                                        isFavourite={isFavourite}
                                                        toggleFavourite={toggleExhibitionFavourite}
                                                        isVorticCurate={organisation?.data?.type === "vortic"}
                                                        isDateVisible={isDateVisible}
                                                    />
                                                </div>
                                            )
                                        }) : (
                                            <P1 value="No Past Exhibitions Found." />
                                        )
                                    }
                                </div>
                            </>
                        }
                    </div>
                ) : (
                    <Spinner />
                )
            }

        </>
    )
};

export default ArtistDetails;
