import {
    GET_PROFILE_DATA,
    UPDATE_PROFILE_DATA_INIT,
    UPDATE_PROFILE_DATA_SUCCESS,
    GET_UPDATE_PROFILE_DATA_INIT,
    GET_UPDATE_PROFILE_DATA_SUCCESS,
    UPDATE_PROFILE_IMAGE,
    UPDATE_PROFILE_NOTIFICATION_DATA_INIT,
    GET_PROFILE_NOTIFICATION_DATA_SUCCESS,
    GET_PROFILE_NOTIFICATION_DATA_INIT, UPDATE_PROFILE_NOTIFICATION_SUCCESS
} from './actionTypes'

export function getProfileData(data) {
    return {
        type: GET_PROFILE_DATA,
        payload: data
    }
}
export function updateProfileNotificationInit(data) {
    return {
        type: UPDATE_PROFILE_NOTIFICATION_DATA_INIT,
        payload: data
    }
}

export function updateProfileDataInit(data) {
    return {
        type: UPDATE_PROFILE_DATA_INIT,
        payload: data
    }
}

export function updateProfileDataSuccess(data) {
    return {
        type: UPDATE_PROFILE_DATA_SUCCESS,
        payload: data
    }
}
export function updateNotificationSuccess(data) {
    return {
        type: UPDATE_PROFILE_NOTIFICATION_SUCCESS,
        payload: data
    }
}

export function getUpdatedProfileDataInit() {
    return {
        type: GET_UPDATE_PROFILE_DATA_INIT
    }
}
export function getProfileNotificationsInit() {
    return {
        type: GET_PROFILE_NOTIFICATION_DATA_INIT
    }
}

export function getUpdatedProfileDataSuccess(data) {
    return {
        type: GET_UPDATE_PROFILE_DATA_SUCCESS,
        payload: data
    }
}
export function getProfileNotificationDataSuccess(data) {
    return {
        type: GET_PROFILE_NOTIFICATION_DATA_SUCCESS,
        payload: data
    }
}

export function updateProfileImageData(data) {
    return {
        type: UPDATE_PROFILE_IMAGE,
        payload: data
    }
}
