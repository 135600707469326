import React from 'react'
import './styles.scss'
import {GalleryItemProps, IProps} from './types'

export const GalleryList = ({data, variant = "default", onSelectItem}: IProps) => {
  const variantClasses = {
    'default': 'bg-default',
    'visible': 'bg-visible'
  }
  return (
    <div className="untitled-gallery">
      <div className={`list ${variantClasses[variant]}`}>
        {
          data.map((item, index) => <Tile key={index} data={item} onSelectItem={onSelectItem}/>)
        }
      </div>
    </div>
  )
}

const Tile = ({data, onSelectItem}: GalleryItemProps) => {
  let tileInfo = ''
  let tileContinent = ''
  let tile = ''
  if (data.selected) {
    tileInfo = 'selected-tile-info'
  }
  if (data.disabled) {
    tileInfo = "disabled-tile-info"
    tileContinent = "disabled-tile-info"
    tile = "disabled-tile"
  }

  return (
    <div className='tile-container' id={`gallery-${data.groupId}-${data.id}`} onClick={() => onSelectItem(data)}>
      <div className={`tile ${tile}`}>
        <div className={`tile-info ${tileInfo}`}>{data.title}</div>
        <div className={`tile-continent ${tileContinent}`}>{data.continent}</div>
      </div>
      <div className='artist-name'>{data.artistName}</div>
    </div>
  )
}
