import logger from '../utils/logger';
import { RudderAnalytics } from '@rudderstack/analytics-js';
import isProductionWebAppUrl from "../utils/isProductionWebAppUrl";

var rudder;

const isProd = process.env.NODE_ENV === "production"

try {
    if (isProd && !!window?.localStorage && isProductionWebAppUrl()) {
        logger.debug('process.env = ', process.env.NODE_ENV)
        const RSK = "2DXKgZC6c2duApCdZ1zkaMwDiMG"
        const DATA_PLANE_URL = "https://vorticigahd.dataplane.rudderstack.com"

        // Create a new RudderStack instance
        rudder = new RudderAnalytics();
        rudder.load(RSK, DATA_PLANE_URL, {})
        // rudder.enable();
    }
  }
catch(err) {
    logger.error(err);
}

export default rudder;
