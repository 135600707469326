import React, {useState, useEffect, useRef} from 'react'
import {IExhibitionProps} from './types'
import Button from "../Button";
import {formatDate, getShareURL} from '../../utils'
import ImageLoader from "../ImageLoader";
import ShareBtn from "../ShareBtn";
import IconBtn from "../IconBtn";
import PrivateIcon from '../../assets/icons/private.svg';
import {Title2, Label, Timeline, Paragraph} from '../Typography2';
import PrivateHorizontal from '../../assets/images/private-horizontal.svg';
import PrivateVerticle from '../../assets/images/private-verticle.svg';
import ScrollAnimation from '../../components/ScrollAnimation/ScrollAnimation'

const Exhibition: React.FC<IExhibitionProps> = ({noScroll, id, slug, isPrivate, artistName , exhibitionImg,exhibitionType, startedAt, endedAt, locations, title, galleryName, onClick, isFavourite, toggleFavourite, className, featured, subTitle, isLoggedIn, onChainClick, discover, isVorticCurate, uuid, isDateVisible}) => {

    startedAt = formatDate(startedAt, true, true);
    endedAt = formatDate(endedAt, true, true);
    const [isIcon, setIsIcon] = useState(false);
    const [previewHeight, setPreviewHeight] = useState(0);
    const previewRef = useRef(null);
    const [xAxis, setXAxis] = useState(0);
    const [yAxis, setYAxis] = useState(0);
    const [scale, setScale] = useState(1);
    const [privateImg, setPrivateImg] = useState(null);
    const setImg = () => {
        if(window.innerWidth <= 767){
            setPrivateImg(PrivateVerticle);
        }
        else{
            setPrivateImg(PrivateHorizontal);
        }
    }

    function handleResize() {
        setPreviewHeight(previewRef && previewRef.current && previewRef.current.clientWidth / 2);
        setImg();
    }

    useEffect(() => {
        handleResize();
    })

    useEffect(() => {
        handleResize();
        setImg();
    }, [])
    useEffect(() => {

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, [previewRef && previewRef.current && previewRef.current.clientWidth]);

    const handleMouseMove = (e) => {
        setXAxis(((window.innerWidth / 2 - e.clientX) / 25)/1.5);
        setYAxis(((window.innerHeight / 2 - e.clientY) / 25)/1.5);
        setScale(1.07);
    }

    const handleMouseLeave = () => {
        setXAxis(0);
        setYAxis(0);
        setScale(1);
    }

    // const timeline = `${startedAt} - ${endedAt} ${(exhibitionType && ('\xa0\xa0•\xa0\xa0 ' + exhibitionType)) || ''}`;
    const timeline = `${startedAt} - ${endedAt}`;

    const enterExhibition = (id) => {
        if (isIcon === false) {
            onClick(id);
        }
    };

    const renderContent = () => {
        return (
            <section className={`exhibition ${className || ''} ${featured ? 'exhibition--featured' : ''}  ${discover ? 'exhibition--discover' : ''} ${isVorticCurate ? 'exhibition--vortic-curated' : ''}`}
                     onClick={() => !artistName&& enterExhibition(slug || uuid)}>
                     {/* onClick={() => !artistName&& enterExhibition(slug || id)}> */}
                {/* <div className='exhibition__preview' style={{minHeight: previewHeight, transform: `rotateY(${xAxis}deg) rotateX(${yAxis}deg)`}} ref={previewRef} onMouseMove={(e) => handleMouseMove(e)} onMouseLeave={() => handleMouseLeave()}> */}
                <div className='exhibition__preview' style={{ transform: `rotateY(${xAxis}deg) rotateX(${yAxis}deg)`}} ref={previewRef} onMouseMove={(e) => handleMouseMove(e)} onMouseLeave={() => handleMouseLeave()}>
                    {/*// @ts-ignore*/}
                    <ImageLoader imgUrls={exhibitionImg && Array.isArray(exhibitionImg) ? [...exhibitionImg] : [exhibitionImg]} style={{transform: `scale(${scale})`}} />
                </div>
                <div className="exhibition__info">
                    <div className={`exhibition__title-wrapper ${artistName?'no-gap':'' }`}>
                        <div>
                            <Title2 className='exhibition__title' value={`${artistName? artistName+', ':''} ${title}`}/>
                            {!featured && galleryName && <Label className="exhibition__label" value={galleryName}/>}
                            <div className='exhibition__details'>
                                <div>
                                    {featured &&
                                    <Paragraph className={'exhibition__description'} value={subTitle}/>}
                                    {isDateVisible && <Timeline className='exhibition__timeline' value={timeline}/>}
                                </div>
                            </div>
                        </div>
                        {!featured && <div className="icons action-icons" onMouseOver={() => setIsIcon(true)}
                                           onMouseLeave={() => setIsIcon(false)}>
                          <ShareBtn up shareUrl={getShareURL('exhibition', slug || uuid)} entityUuid={uuid} />
                          {!artistName && <>                          <IconBtn filterImg="filter-img" type={isFavourite ? "favouriteOn2" : "favourite2"}
                                   onClick={() => toggleFavourite(id)}/>
                          <Button
                            value='Visit exhibition'
                            className={`button__bright secondary`}
                            iconType='enter'
                            // @ts-ignore
                            onClick={() => onClick(slug || uuid)}
                          />
                          </>
                        }
                        </div>}
                    </div>
                    {!featured && <div className="exhibition__icons" onMouseOver={() => setIsIcon(true)}
                                       onMouseLeave={() => setIsIcon(false)}>
                      <Label className="exhibition__span exhibition__maria" value=''/>
                      <div className='action-icons'>
                        <ShareBtn up filterImg='filter-img' shareUrl={getShareURL('exhibition', slug || uuid)} entityUuid={uuid} />
                        <IconBtn  filterImg='filter-img' type={isFavourite ? "favouriteOn2" : "favourite2"}
                                 onClick={() => toggleFavourite(id)}/>
                      </div>
                    </div>}
                </div>
            </section>
        )
    }

    return (
        <>
            {
                (!isPrivate) ? (
                    (noScroll === false) ?
                        <ScrollAnimation id={id} isExhibition={true}>
                            {renderContent()}
                        </ScrollAnimation> : <>{renderContent()}</>
                ) : (
                    <section className={`exhibition exhibition--private private-exhibition`} onClick={() => {isLoggedIn && enterExhibition(slug || uuid)}}>
                    {/* <section className={`exhibition exhibition--private private-exhibition`} onClick={() => {isLoggedIn && enterExhibition(slug || id)}}> */}
                        <div className='mask-wrapper'>
                            <img src={privateImg} />
                        </div>
                        <div className='private-exhibition__label'>
                            <img alt='private' src={PrivateIcon} />
                            <Label value='Private Exhibition' />
                        </div>
                        <div className="exhibition__cover-image">
                            <img src={exhibitionImg && Array.isArray(exhibitionImg) ? exhibitionImg[0] : exhibitionImg[0]} alt='exhibition image'/>
                        </div>
                        <div className={'exhibition__content-wrapper'}>
                            <div className='preview-wrapper' />
                            <div className="exhibition__info">
                                <Title2 value={title} />
                                <Label value={galleryName} />
                                {isDateVisible && <Paragraph className='exhibition__timeline' value={timeline}/>}
                            </div>
                        </div>
                    </section>
                )
            }
        </>

    )
}

// @ts-ignore
export default Exhibition
