import React, {useEffect, useRef, useState} from 'react';
import IslandSearchFilterDesktop from '../IslandSearchFilterDesktop/IslandSearchFilterDesktop';
import Filter from '../../Filter';
import {GalleryItem} from "../../GalleryList/types";

interface IProps {
  variant: string;
  data: GalleryItem[],
  handleSearch?: (value: string) => void;
  handleClose?: () => void;
  handleRegion?: (regions: GalleryItem[]) => void;
  selectedRegions?:string[]
}

const IslandSearchAndFilters = ({variant, data = [], handleSearch=(value)=>{}, handleRegion=(value)=>{}, selectedRegions=[], handleClose}:IProps) => {
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [activeHeight, setActiveHeight] = useState(0);
  const filterRef = useRef(null);
  const handleFilter = () => {
    setIsFilterActive(true);
  }

  useEffect(() => {
    if (!filterRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      // will execute on elements size change
      setActiveHeight(filterRef.current.clientHeight);
    });
    resizeObserver.observe(filterRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  return (
    <div
      className={`island-nav island-nav--${variant} ${isFilterActive ? 'island-nav--filter-open' : 'island-nav--filter-close'}`}
      style={isFilterActive ? {height: `${activeHeight}px`} : {}}>
      <IslandSearchFilterDesktop handleSearch={handleSearch} handleFilter={handleFilter} handleClose={handleClose} />
      <div className='island-nav__filters-container' ref={filterRef}>
        <Filter data={data} handleSelect={handleRegion} isOpen={isFilterActive} selectedRegions={selectedRegions} handleClose={() => setIsFilterActive(false)}/>
      </div>
    </div>
  );
}

export default IslandSearchAndFilters;
