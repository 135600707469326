import React, { useState } from 'react';
import { FacebookShareButton, TwitterShareButton } from "react-share";
import Chain from '../../assets/icons/chain.svg';
import Facebook from '../../assets/icons/facebook.svg';
import Twitter from '../../assets/icons/twitter.svg';
import rudderstackEvents from "../../services/rudderstack-events";
import { isMobileDevice } from '../../utils';
import IconBtn from "../IconBtn";
import { IShareBtnProps } from './types';

const ShareBtn: React.FC<IShareBtnProps> = ({ chainLink, shareUrl, up, secondary, entityId = null, galleryId = null, galleryName = null, entityName = null, isGray = false, isFilled, entityUuid = null }) => {
    const [isCopied, setIsCopied] = useState(false);
    const [isHide, setIsHide] = useState(true);

    const serialize = (obj) => {
        var str = [];
        for (var p in obj) {
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        }
        return str.join("&");
    };

    const getUrl = (link) => {
        let url = link;
        let finalUrl = "";
        if (url && url.includes("exhibition")) {
            finalUrl = url.replace('exhibition', 'exhibitions')
        }
        else if (url && url.includes("gallery")) {
            finalUrl = url.replace('gallery', 'galleries')
        }
        else if (url && url.includes("organisation")) {
            finalUrl = url.replace('organisation', 'organisations')
        }
        else if (url && url.includes("collective")) {
            finalUrl = url.replace('collective', 'collectives')
        }
        else if (url && url.includes("curator")) {
            finalUrl = url.replace('curator', 'curators')
        }
        else if (url && url.includes("artist")) {
            finalUrl = url.replace('artist', 'artists')
        }
        return finalUrl;
    }

    const handleChainClick = async () => {
        let finalUrl = '';
        let hash = window.location.search;
        let array = hash.substring(1).split("&");
        let values,
            form_data = {};
        if (window.location.href.includes('/curate')) {
            finalUrl = window.location.href;
        }
        else {
            var arr = window.location.href.split("/");
            var result = arr[0] + "//" + arr[2];
            const isIo = shareUrl.indexOf('io/') !== -1;
            let url = result + shareUrl.slice(isIo ? (shareUrl.indexOf('io/') + 2) : (shareUrl.indexOf('art/') + 3));
            finalUrl = getUrl(url);
            for (let i = 0; i < array.length; i += 1) {
                values = array[i].split("=");
                form_data[values[0]] = values[1];
            }

            delete form_data["eventId"];
        }

        if (window.location.href.includes('?')) {
            finalUrl = `${finalUrl}?${serialize(form_data)}`;
        }

        navigator && navigator.clipboard && navigator.clipboard.writeText(chainLink || finalUrl || '');
        setIsCopied(true)
        setTimeout(
            () => setIsCopied(false),
            2000
        );
    }

    const sendRudderStackEvent = () => {
        if (shareUrl?.includes('exhibition')) {
            rudderstackEvents.onExhibitionShare({ type: 'in_exhibition', exhibitionId: entityId, galleryId: galleryId, exhibitionName: entityName, galleryName, exhibitionUuid: entityUuid })
        }
        else if (shareUrl?.includes('artwork')) {
            rudderstackEvents.onArtworkShare({ type: 'in_artwork', artworkId: entityId, artworkUuid: "" })
        }
    }

    return (
        <div onClick={() => {
            if (isMobileDevice()) {
                setIsHide(false)
                setTimeout(
                    () => setIsHide(true),
                    5000
                );
            };
            sendRudderStackEvent();
        }} className={`share-btn  ${up ? 'share-btn--up' : ''}`}
        >
            <IconBtn filterImg={`filter-img`} type={!isFilled ? "share2" : "shareFilled"} secondary={secondary} />
            <div className={`tooltip ${isGray ? 'gray' : ''} ${isMobileDevice() ? (isHide ? "tooltip-hide" : "tooltip-show") : ''}`}>
                <TwitterShareButton
                    url={shareUrl}
                    //  @ts-ignore
                    quote={shareUrl}
                    className="share-icons twitter-share-icon"
                >
                    <img alt='twitter' src={Twitter} />
                </TwitterShareButton>

                {/*// @ts-ignore*/}
                <FacebookShareButton
                    url={shareUrl}
                    // @ts-ignore
                    quote={shareUrl}
                    className="share-icons facebook-share-icon"
                >
                    <img alt='facebook' src={Facebook} />
                </FacebookShareButton>

                <button className="share-btn share-icons copy-icon link-share-icon">
                    <img alt='chain' src={Chain} onClick={handleChainClick} />
                    <div className={`tooltip  ${up ? '' : 'copied-link'}  ${isCopied ? 'active' : ''}`}>
                        Link Copied!
                    </div>
                </button>
            </div>
        </div>
    )
};

export default ShareBtn;
