import React from 'react';
import {Helmet} from "react-helmet";

const HeadTags = ({ title,description,image,id,type}) => {
    return (
        <Helmet>
            <title>{title}</title>
            {description && <meta name="description" content={description} />}
            <meta property="og:title" content={title}/>
            {description && <meta property="og:description" content={description}/>}
            {image && <meta property="image" content={image}/>}
            {image && <meta property="og:image" content={image}/>}
            {title&&<meta property="twitter:title" content={title}/>}
            {image&&<meta property="twitter:image" content={image}/>}
            <meta name="twitter:card" content="summary_large_image"/>
            {type&&id&&<meta name="apple-itunes-app" content={`app-id=1503616751, app-argument=vortic-collect://${type}/${id}`}/>}
        </Helmet>
    );
};

export default HeadTags;