import { connect } from 'react-redux'
import Collectives from './Collectives'
import { fetchCollectiveInit } from './redux/actions'

import './style.scss'

const mapStatesToProps = ({collective, global}) => {
    return {
        collective,
        globalProps: global
    };
};

const mapDispatchToProps = dispatch => ({
    fetchCollectives: (page) => dispatch(fetchCollectiveInit(page)),
});

export default connect(mapStatesToProps, mapDispatchToProps)(Collectives)
