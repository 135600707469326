import './style.scss'
import LoginPage from './Login'
import { login, setRedirectPath } from '../Global/redux/actions'
import {getProfileData, getUpdatedProfileDataInit} from '../Profile/redux/actions'
import {resetExhibitions,setAciveArtWork} from '../Exhibitions/redux/actions'
import { connect } from 'react-redux'

const mapDispatchToProps = dispatch => ({
  login: () => dispatch(login()),
  getLoginData: (data) => dispatch(getProfileData(data)),
  setRedirectPath: (data) => dispatch(setRedirectPath(data)),
  updateProfileData: () => {dispatch(getUpdatedProfileDataInit())},
  resetExhibitions: (forceUpdate) => {dispatch(resetExhibitions(forceUpdate))},
  setAciveArtWork:(id)=>{dispatch(setAciveArtWork(id))},
});

export default connect(null, mapDispatchToProps)(LoginPage)
