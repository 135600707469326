import React, { useState, useEffect, useRef } from 'react';
import ICurateEnquireSideBarProps from './types';
// import ReplyTo from '../ReplyTo';
import { Title, Heading, Paragraph } from '../Typography2';
import { useSelector } from 'react-redux'
import Logo from '../../assets/icons/logo-gray.svg';
import Next from '../../assets/icons/bold-next-dark.svg';
import Login from '../../containers/Login';
import Signup from '../../containers/SignUp';
import ForgotPassword from '../../containers/ForgotPassword';
import Spinner from '../Spinner';
import arrowDown from "../../assets/icons/arrow-down-bold.svg";
import logger from '../../utils/logger';
import ListHeader from '../ListHeader';
import IconBtn from '../IconBtn';
import CrossIcon from "../../assets/icons/cross.svg";
import Input from '../Input';
import Button from '../Button';
import CommentThanks from '../CommentThanks';
import { addEnquireMessage } from '../../services/api';
import {isEmailValid, isMobileDevice} from "../../utils";
const CurateEnquireSideBar: React.FC<ICurateEnquireSideBarProps> = ({ handleAuthModal, isArtworkDetailsPage, activeArtworkId, galleryName, handleCancel }) => {
    const [message, setMessage] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [receiveUpdates, setReceiveUpdates] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [messageError, setMessageError] = useState(false);
    // @ts-ignore
    const isLoggedIn = useSelector((state) => state?.global['isLoggedIn'])
    const [hasMessageSubmited, setHasMessageSubmited] = useState(false);
    
    const [isSignupComplete, setIsSignupComplete] = useState(false);
    const [isEmptyMessage, setIsEmptyMessage] = useState(false);
    const [hasCommentApproved, setHasCommentApproved] = useState(false);
    const [resetCommentControls, setResetCommentControls] = useState(false);
    const [isEventsLogin, setEventLogin] = useState(false);
    const [isEventsSignUp, setEventSignUp] = useState(false);
    const [isEventsForget, setEventForget] = useState(false);

    const [commentLoading, setCommentsLoading] = useState(true);
    const [isCheckboxError, setIsCheckboxError] = useState(false);
    const [isSignupForm , setIsSignupForm] = useState(false);


    const onLoginLinkClick = () => {
        setEventSignUp(false);
        setEventLogin(true);
        setEventForget(false);
    };

    useEffect(() => {
        if(receiveUpdates){
            setIsCheckboxError(false)
        }
    }, [receiveUpdates])

    const onSignupLinkClick = () => {
        // if(message && message.trim().length > 0){
            setEventSignUp(true);
            setEventLogin(false);
            setEventForget(false);
        // }
        // else{
        //     setIsEmptyMessage(true);
        //     setTimeout(() => {
        //         setIsEmptyMessage(false);
        //     }, 500);
        // }
    };
    const onAfterSubmitSignupLinkClick = () => {
        setEventSignUp(true);
        setEventLogin(false);
        setEventForget(false);
    };
    const onForgetLinkClick=()=>{
        setEventForget(true);  
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(isLoggedIn){
            if(message.trim().length > 0){
                setHasMessageSubmited(true);
                setMessageError(false)
                setIsEmptyMessage(false)
                // if(!receiveUpdates) {
                //     return setIsCheckboxError(true);
                // }
                const resp = await addEnquireMessage(activeArtworkId, {
                    message,
                });
                if(resp){
                    setHasMessageSubmited(true);
                    setEventSignUp(true);
                }
            }
            else{
                setHasMessageSubmited(true);
                setEventSignUp(false);
                setIsEmptyMessage(true)
            }
        } else{
            if(message.trim().length > 0 && email.trim().length > 0 && name.trim().length > 3 && receiveUpdates){
                if (isEmailValid(email)) {
                    setEmailError(false)
                    setNameError(false)
                    setIsEmptyMessage(false)
                    setIsCheckboxError(false)
                    const resp = await addEnquireMessage(activeArtworkId, {
                        message,
                        email,
                        name,
                        receiveUpdates,
                    });
                    if (resp) {
                        setHasMessageSubmited(true);
                        setEventSignUp(true);
                    }
                }else {
                    setEmailError(true)
                }
            } else{
                setHasMessageSubmited(true);
                setEventSignUp(false);
                if (name.trim().length < 3){
                    setNameError(true)
                }
                if(message.trim().length < 2 ){
                    setIsEmptyMessage(true)
                }
                if (!isEmailValid(email)){
                    setEmailError(true)
                }
                if (!receiveUpdates){
                    setIsCheckboxError(true)
                }
            }
        }
    }
    const handleOnMessageChange = (e) => {
        const {value}=e.target
        if (value.length>2){
            setIsEmptyMessage(false)
        }else {
            setIsEmptyMessage(true)
        }
        setMessage(value)
    }
    const handleOnSignupComplete = (e) => {
        setEventSignUp(false);
        setIsSignupComplete(true);
        setEventLogin(false);
    }

    const isFormValid = () => {
        const trimEmail = email.trim();
        return message.trim().length > 0 && trimEmail.length > 0 && isEmailValid(trimEmail) && name.trim().length > 3 && receiveUpdates
    }
    
    const handleOnOverlaySignupComplete =  async (token) => {
        if(!message || message.trim().length < 0){
            setEventSignUp(false);
            setEventLogin(false);
            return;
        }
        if (token ) {
            const config:any = {
                headers: { Authorization: `Bearer ${token.auth}` }
            };
            const resp = await addEnquireMessage(activeArtworkId, {
                message,
            }, config);
            if(resp){
                setHasMessageSubmited(true);
                setIsSignupComplete(true);
                setEventSignUp(false);
                setEventLogin(false);
            }
        }
    }

    // @ts-ignore
    return (
        <aside className="curate-enquire-aside-wrapper">
            
            <div className={`sidebar-header ${isArtworkDetailsPage ? "sidebar-header-artwork-detail" : ""}`}>
                <Heading value={"Enquire about artwork"}/>
                {/* {isLoggedIn && <IconBtn onClick={handleCancel} type="brightClose" />} */}
            </div>

            {
                <div className={`curate-enquire-sidebar ${isEventsSignUp ? "signup-form-thankyou-page":""} ${(isEventsLogin && !hasMessageSubmited && !isLoggedIn ? "" :  isEventsSignUp && !hasMessageSubmited && !isLoggedIn ? "reduceHeightSingup": "")}`}>
                    {
                        hasMessageSubmited &&
                        <>
                            {
                                (!isSignupComplete)  ? 
                                <Heading className={`thanks-heading ${!isLoggedIn && !isSignupComplete && "border-bottom"}`} value={<><b className='Maax-Bold'>Thank you. </b> Your message has been sent. The gallery will be in touch with you soon.</>} />
                                :
                                <Heading className="thanks-heading" value={<><b>Thank you.</b>  Please check your email and verify your email address.</>} />
                            }
                        </>
                    }
                    <div className={`sidebar-enuire-message-wrapper ${isLoggedIn?'full':''} ${!hasMessageSubmited ? "border-bottom" : ""}`}>
                            {
                                
                                hasMessageSubmited ?
                                    <>
                                        {        
                                            !isLoggedIn && !isSignupComplete &&
                                            <div className="curate-enquire-submited-login-wrapper">
                                                {/* {(isEventsLogin && !isEventsForget )? <Login
                                                 // @ts-ignore
                                                isCommentsForm 
                                                isEnquireForm 
                                                signupLinkClick={onAfterSubmitSignupLinkClick} 
                                                closeEventLogin={() => setEventLogin(false)}  
                                                handleCloseBtnEvent={() =>{ setEventLogin(false)}}
                                                forgetLinkClick={onForgetLinkClick}
                                                title="Please log in" /> :
                                                // @ts-ignore
                                                 <>{isEventsForget && <ForgotPassword 
                                                    // @ts-ignore
                                                    goToLogin={onLoginLinkClick}
                                                    isForgotPassword={true} 
                                                    isLoggedIn={isLoggedIn}
                                                    isEnquireForm={true}
                                                    handleCloseBtnEvent={()=>{setEventForget(false)}}
                                                    />}
                                                </>
                                                } */}
                                                {/*// @ts-ignore*/}
                                                {isEventsSignUp &&  <Signup isEnquireForm isEventsSignUp={isEventsSignUp} isCommentsForm onSignupComplete={handleOnSignupComplete} loginLinkClick={onLoginLinkClick} closeEventSignUp={() => {setEventSignUp(false);}} handleBackBtnEvent={() => {setEventSignUp(false); setEventLogin(true)}} title="Create a Vortic account to get the latest updates on new exhibitions and artworks. " />}
                                            </div>
                                        }
                                    </>
                                :
                                <>
                                    <div className={`sidebar-enquire-message-area ${isEmptyMessage && 'empty-message-error'}`}>
                                        {
                                            galleryName && 
                                            <Paragraph className='sender' value={`Send a message to ${galleryName?.trim()}:`}/>
                                        }
                                        <textarea
                                        name="message" 
                                        id="enquireMessage" 
                                        maxLength={2048}
                                        value={message}
                                        placeholder="Hi, I’m interested in this artwork" 
                                        cols={30} 
                                        onChange={handleOnMessageChange}
                                        rows={isEventsSignUp ? 1: ((!isMobileDevice() || window.innerWidth > 990) && 10)}></textarea>
                                    </div>
                                    {
                                        
                                        (isLoggedIn) &&
                                        <div className='enquire-aside-buttons-wrapper'>
                                            <button onClick={handleSubmit} className={`btn_new_submit ${message.length<3?'':'active-btn'}`}>
                                                <Paragraph value="Send enquiry"/>
                                            </button>
                                            <Button onClick={handleCancel} type="secondary" className="enquire-cancel-btn">
                                                <Paragraph value="Cancel"/>
                                            </Button>
                                        </div>

                                    }
                                
                                </>
                            }
                            {
                                (hasMessageSubmited && (!isLoggedIn ? !isEventsSignUp : isLoggedIn )) &&
                                <Button onClick={handleCancel} type="secondary" className="enquire-cancel-btn stick-to-bottom">
                                    <Paragraph value="Close"/>
                                </Button>
                            }
                    </div>
                    {
                        !isLoggedIn && !hasMessageSubmited && !isEventsLogin && !isEventsSignUp && 
                        <div className="sidebar-enuire-information-wrapper">
                            <div className="sidebar-enquire-information-area">
                                {/* <Paragraph className="your-info" value={"Your Information"}/> */}
                                <Heading className="your-info" value="Your Information" />
                                <button className="login-redirect-button-area" onClick={() => handleAuthModal(true)}>
                                    <Paragraph value={<>Have a Vortic account? <strong>Log in now.</strong></>}/>
                                </button>
                                <Paragraph className='sidebar-enquire-instruction' value={"Otherwise, send your enquiry as a guest by completing the fields below."}/>
                                
                                <div className="form form-page">
                                    <div className="form__container login">
                                    <Input
                                        value={name}
                                        label='Full Name'
                                        important
                                        type='text'
                                        // disabled={isLoggedIn}
                                        onFocus={() => logger.debug(document.querySelector('.name_Input').scrollIntoView(true))}
                                        placeholder=''
                                        name="name_input"
                                        className={`mg_btm input__dark ${nameError ? 'input__error' : ''} name_Input`}
                                        onChange={(e) => {
                                            const {value}=e.target
                                            setName(value)
                                            if (value.length>2){
                                                setNameError(false)
                                            }else {
                                                setNameError(true)
                                            }
                                        }}
                                    />
                                    <Input
                                        value={email}
                                        label='Email Address'
                                        type='email'
                                        important
                                        // disabled={isLoggedIn}
                                        onFocus={() => logger.debug(document.querySelector('.email_Input').scrollIntoView(true))}
                                        placeholder=''
                                        name="email_input_enquire"
                                        className={`input__dark ${emailError ? 'input__error' : ''} email_Input`}
                                        onChange={(e) => {
                                            const {value}=e.target
                                            setEmail(value)
                                            if (!isEmailValid(value)){
                                                setEmailError(true)
                                            }else {
                                                setEmailError(false)
                                            }
                                        }}
                                    />
                                    {isCheckboxError && <Paragraph className='invalid checkbox-error' value={"Please accept the terms to send enquiry"}/>}
                                    <label className='termsAgreement'>
                                        <input
                                            type='checkbox' checked={receiveUpdates}
                                            onChange={(e) => setReceiveUpdates(e.target.checked)}
                                        />
                                        <div>
                                            <p className='paragraph curate-checkbox'>Required: 
                                                <span className='gray'>
                                                This service is powered by Vortic on behalf of our gallery partners.
                                                Your message will be sent directly to the gallery. Please see the privacy
                                                policy of the relevant gallery for information on how your enquiry and personal
                                                data will be managed and to exercise your rights.</span>
                                            </p>
                                        </div>
                                    </label>
                                    <div className='enquire-aside-buttons-wrapper'>
                                        <button onClick={handleSubmit} className={`btn_new_submit ${isFormValid() ? 'active-btn' : ''}`}>
                                            <Paragraph value="Send enquiry"/>
                                        </button>
                                        <Button onClick={handleCancel} type="secondary" className="enquire-cancel-btn">
                                            <Paragraph value="Cancel"/>
                                        </Button>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }

            {
                
                !isLoggedIn && (isEventsSignUp || isEventsLogin) && !hasMessageSubmited &&
                <>
                    
                    <div className="curate-enquire-overlay-login-wrapper">
                    {(isEventsLogin && !isEventsForget )? <Login
                                                // @ts-ignore
                                                 isCommentsForm 
                                                 isEnquireForm 
                                                 signupLinkClick={onSignupLinkClick} 
                                                 closeEventLogin={() => setEventLogin(false)}  
                                                 handleCloseBtnEvent={() =>{ setEventLogin(false)}} 
                                                forgetLinkClick={onForgetLinkClick} title="Please log in" />
                                                :
                                                 // @ts-ignore
                                                 <>{isEventsForget && <ForgotPassword 
                                                    // @ts-ignore
                                                    goToLogin={onLoginLinkClick}
                                                    isForgotPassword={true} 
                                                    isLoggedIn={isLoggedIn}
                                                    isEnquireForm={true}
                                                    handleCloseBtnEvent={()=>{setEventForget(false)}}
                                                    />}
                                                </>
                                                }
                        {/*// @ts-ignore*/}
                        {isEventsSignUp && <Signup isEnquireForm isCommentsForm onSignupComplete={handleOnOverlaySignupComplete} loginLinkClick={onLoginLinkClick} closeEventSignUp={() => {setEventSignUp(false)}} handleLoginBtnEvent={() => {setEventSignUp(false); setEventLogin(true)}} handleBackBtnEvent={() => {setEventSignUp(false); setEventLogin(true)}}  title="Create a Vortic account to get the latest updates on new exhibitions and artworks." />}
                    </div>
                
                </>
            }
        </aside>
    );
};

export default CurateEnquireSideBar;
