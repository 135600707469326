import React, { useState, useEffect } from 'react';
import Login from '../../containers/Login';
import Signup from '../../containers/SignUp';
import ForgotPassword from '../../containers/ForgotPassword';
import { Iautentication } from './types';
import {useSelector, useDispatch} from 'react-redux'
import { setIsMobileForm } from '../../containers/Global/redux/actions';
import "./style.scss";

const AuthenticationForm: React.FC<Iautentication> = ({
                                                          type = 'login',
    isLoggedIn,
    onCloseForm,
    toggleLogin = false,
    setToggleLogin,
    fromExhLoading = false,
    artworkDetailsEnquire = false,
    className,
    closeIconType
}) => {
    const [formType, setFormType] = useState<string>(type);
    
    // @ts-ignore
    const isMobileForm = useSelector(state => state?.global.isMobileForm)
    const dispatch = useDispatch();

    useEffect(() => {
        if(!artworkDetailsEnquire) {
            if(!isMobileForm) {
                dispatch(setIsMobileForm(true));
            } 
            return () => {
                if(isMobileForm) {
                    dispatch(setIsMobileForm(false));
                }
            }
        }
    }, [])

    /** method to open login form */
    const onLoginLinkClick = () => {
        setFormType('login');
    };

    /** method to open signup form */
    const onSignupLinkClick = () => {
        setFormType('signup')
    };

    /** method to open forget password form */
    const onForgetLinkClick = () => {
        setFormType('forget')
    }

    return (<div className={`authentication-form__wrapper ${className || ''}`}>
        {formType === 'login' && <Login
            // @ts-ignore
            signupLinkClick={onSignupLinkClick}
            closeEventLogin={() => isLoggedIn ? onSignupLinkClick() : onCloseForm()}
            forgetLinkClick={onForgetLinkClick}
            fromAuthenticated={true}
            toggleLogin={toggleLogin}
            setToggleLogin={setToggleLogin}
            closeIconType={closeIconType}
        />}

        {/* @ts-ignore */}
        {formType === 'signup' && <Signup
            loginLinkClick={onLoginLinkClick}
            closeEventSignUp={onLoginLinkClick}
            isEventsSignUp={true}
            fromExhLoading={fromExhLoading}
            closeIconType={closeIconType}
        />}

        {/* @ts-ignore */}
        {formType === 'forget' && <ForgotPassword
            // @ts-ignore
            goToLogin={onLoginLinkClick}
            isForgotPassword={true}
            isLoggedIn={isLoggedIn}
            fromExhLoading={fromExhLoading}
            closeIconType={closeIconType}
        />}

    </div>)
};

export default AuthenticationForm;
