import React from "react";
import { Title, Paragraph } from "../Typography2";
import Button from "../Button";
import { IDiscoverHeaderProps } from "./types";
import ArrowLeft from '../../assets/icons/arrow-left-black.svg';
import ArrowRight from '../../assets/icons/arrow-right-black.svg';

const DiscoverHeader:React.FC<IDiscoverHeaderProps> = ({ title, description, arrowsCount, handleLeftArrowClick, handleRightArrowClick }) => {
  return (
    <div className={"discovers-header"}>
      <div className={"discovers-header__left"}>
        <Title value={title} />
        {description && <Paragraph value={description} />}
      </div>
      {arrowsCount && <div className={"discovers-header__right"}>
        <button><img onClick={handleLeftArrowClick} src={ArrowLeft} alt="left arrow" /></button>
          <Paragraph value={arrowsCount} />
        <button><img onClick={handleRightArrowClick} src={ArrowRight} alt="right arrow" /></button>
      </div>}
    </div>
  );
};

export default DiscoverHeader;
