import React, { Fragment } from 'react';
import {Title, Label, Paragraph, Heading, Anchor} from '../Typography2';
import {IDetailsCardProps} from './types';
import './style.scss';
import ImageLoader from "../ImageLoader";
import {getShareURL, isMobileDevice} from "../../utils";
import ScrollAnimation from "../ScrollAnimation/ScrollAnimation";
import ShareBtn from "../ShareBtn";
import IconBtn from "../IconBtn";
import Instagram from "../../assets/icons/instagram.svg";
import Facebook from "../../assets/icons/facebook-dark.svg";
import TwitterDark from "../../assets/icons/twitter-dark.svg";

const DetailsCard: React.FC<IDetailsCardProps> = ({biography, style, id, type, slug, title, location, address, imgSrc, description, toggleFavourite, isFavourite, instagramLink, facebookLink, twitterLink, websiteLink, curatorDetails}) => {

    const getSocialLinks = () => {
        return (
            <Fragment>
                {
                    instagramLink &&
                    <a target="_blank"
                       href={instagramLink?.includes('@') ? `https://instagram.com/${instagramLink.slice(1)}` : `https://instagram.com/${instagramLink}`}><img
                        src={Instagram} alt="Instagram"/></a>
                }
                {
                    !!facebookLink &&
                    <a target={"_blank"} href={facebookLink?.includes('http') ? facebookLink : `https://${facebookLink}`}><img src={Facebook} alt="Facebook"/></a>
                }
                {
                    !!twitterLink &&
                    <a target={"_blank"} href={instagramLink?.includes('@') ? 'https://twitter.com/'+twitterLink.slice(1) : `https://twitter.com/${twitterLink}`}><img src={TwitterDark} alt="Twitter"/></a>
                }
            </Fragment>
        )
    }

    const isSocialContent = () => !!instagramLink || !!facebookLink || !!twitterLink;

    return (
        <ScrollAnimation style={(type === "gallery" || type === "organisation" || type === "curator") ? {position: 'relative', zIndex: '1111111'} : {}}>

            <div className="details-card" style={style}>
                <div className="">
                    <div>
                        <Title value={title}/>
                    </div>
                    <div className="flex balance-text">
                        <div>
                            {location && <Label value={location}/>}
                            {address && <Label value={address}/>}
                        </div>
                        <div className='action-icons'>
                            <ShareBtn up shareUrl={getShareURL(type, slug || id)} isFilled={curatorDetails} />
                            <IconBtn  filterImg='filter-img' type={isFavourite ? "favouriteOn2" : (!curatorDetails ? "favourite2" : "favouriteFilled")} onClick={() => toggleFavourite(id)}/>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="img">
                        {/*// @ts-ignore*/}
                        <ImageLoader imgUrls={imgSrc && Array.isArray(imgSrc) ? [...imgSrc] : [imgSrc]}/>
                    </div>
                    <div className='details-content-wrapper'>
                        {(!!biography || !!description) && <div className='copy-content'>
                            {!!biography && <Paragraph  className="heading" value={biography || ''} />}
                            {!!description && <><Paragraph className="details-card__description" value={description || ''}/></>}
                        </div>}
                        {(!!websiteLink || isSocialContent()) && <div className="contact-info">
                            {
                                !!websiteLink && <article>
                                    <Heading value="Online:"/>
                                    <div className="anchor-wrapper">
                                        <Anchor target={"_blank"} to={websiteLink}
                                                value={websiteLink}/>
                                    </div>
                                </article>
                            }

                            {isSocialContent() && <article>
                                <Heading value="Social:"/>
                                <div className="gallery-info-links">
                                    {
                                        getSocialLinks()
                                    }
                                </div>
                            </article>}
                        </div>}
                    </div>
                </div>
            </div>
        </ScrollAnimation>
    )
};

export default DetailsCard;
