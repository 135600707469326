// @ts-nocheck
import {ofType} from 'redux-observable';
import {flatMap, map, catchError} from 'rxjs/operators';
import {FETCH_EXHIBITIONS_INIT, FETCH_PRIVATE_PUBLIC_EXHIBITIONS_INIT, GET_EXHIBITION_DETAILS_INIT} from './actionTypes'
import * as API from "../../../services/api"
import {fetchExhibitionDetailsSuccess, fetchExhibitionsSuccess} from './actions';
import {IResponseType} from "../../Galleries/types";
import logger from '../../../utils/logger';

const fetchExhibitions = action$ =>
    action$.pipe(
        ofType(FETCH_EXHIBITIONS_INIT),
        flatMap(({payload}) => {
            return API.fetchExhibitions(payload);
        }),
        map((resp: IResponseType) => {
            return fetchExhibitionsSuccess(resp);
        }),
        catchError(error => {
            logger.error('error = ', error);
            return () => {
            }
        })
    );

const fetchPrivatePublicExhibitions = action$ =>
    action$.pipe(
        ofType(FETCH_PRIVATE_PUBLIC_EXHIBITIONS_INIT),
        flatMap(({payload}) => {
            if (payload.isGroup) {
                return API.fetchCollectiveExhibitions(payload.filterType);
            } else {
                return API.fetchPrivateAndPublicExhibitions(payload);
            }
        }),
        map((resp: IResponseType) => {
            if (resp.currentPage) {
                return fetchExhibitionsSuccess(resp);
            } else {
                return fetchExhibitionsSuccess({...resp, currentPage: 1});
            }
        })
    );

const fetchExhibitionDetails = action$ =>
    action$.pipe(
        ofType(GET_EXHIBITION_DETAILS_INIT),
        flatMap(({payload}) => API.fetchExhibitionsDetails(payload)),
        map((resp: IResponseType) => {
            return fetchExhibitionDetailsSuccess(resp);
        }),
        catchError(error => {
            logger.error('error = ', error);
            return () => {
            }
        })
    );

export default [
    fetchExhibitions,
    fetchPrivatePublicExhibitions,
    fetchExhibitionDetails
]
