import ArtistDetails from './ArtistDetails'
import { connect } from 'react-redux'
import './style.scss'
import {updateFavourite} from "../Favourites/redux/actions";
import {setArtworkId, setArtworkIds} from "../Global/redux/actions";

const mapStatesToProps = (state) => {
    return {
        globalProps: state.global,
    }
};

const mapDispatchToProps = dispatch => ({
    updateFavourite: () => dispatch(updateFavourite()),
    setArtworkIds: (val) => dispatch(setArtworkIds(val)),
    setArtworkId:(id) => dispatch(setArtworkId(id))
});

export default connect(mapStatesToProps, mapDispatchToProps)(ArtistDetails)
