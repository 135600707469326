// @ts-nocheck
import {ofType} from 'redux-observable';
import {catchError, flatMap, map} from 'rxjs/operators';
import {FETCH_GALLERIES_INIT} from './actionTypes'
import * as API from "../../../services/api"
import {fetchGalleriesSuccess} from './actions';
import {IResponseType} from '../types'
import logger from "../../../utils/logger";

const fetchGalleries = action$ =>
    action$.pipe(
        ofType(FETCH_GALLERIES_INIT),
        flatMap(({payload}) => {
            logger.debug('payload = ', payload)
            if (payload.isGroup) {
                return API.fetchCollectiveGalleries(payload.filter);
            } else {
                return API.fetchGalleries(payload);
            }
        }),
        map((resp: IResponseType) => {
            logger.debug('resp = ', resp)
            if (resp.currentPage) {
                return fetchGalleriesSuccess(resp);
            } else {
                return fetchGalleriesSuccess({...resp, currentPage: 1});
            }
        }),
        catchError(error => {
            logger.error('error = ', error);
            return () => {
            }
        })
    );

export default [
    fetchGalleries,
]
