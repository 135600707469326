import './style.scss';
import {setRedirectPath} from "../../containers/Global/redux/actions";
import {setCurrentConversation, setEnquireMessage} from "../../containers/Conversation/redux/actions";
import {connect} from "react-redux";
import ArtworksAudioDetails from "./ArtworksAudioDetails";

const mapStatesToProps = ({ global, profile }) => {
    return {
        isLoggedIn: global.isLoggedIn,
        profile: profile.profileData
    };
};

const mapDispatchToProps = dispatch => ({
    setRedirectPath: (path) => dispatch(setRedirectPath(path)),
    setCurrentChannel: (conversation) => {dispatch(setCurrentConversation(conversation))},
    setEnquireMessage: (message) => {dispatch(setEnquireMessage(message))}
});

export default connect(mapStatesToProps, mapDispatchToProps)(ArtworksAudioDetails)
