import React from 'react';
import ArrowIcon from '../../../assets/icons/next.svg';
import './IslandGalleryNav.scss';

const IslandGalleryNav = ({ isInstructionsOpen = false }) => {
    return (
        <div className='island-gallery-nav'>
            <p className='island-nav__title'>
                {!isInstructionsOpen ? 'Continue' : 'Enter'}
            </p>
            <button className='island-nav__arrow'>
                <img src={ArrowIcon} alt="arrow" />
            </button>
        </div>
    );
}

export default IslandGalleryNav;