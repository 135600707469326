import React, {useEffect, useState} from 'react';
import WelcomeToVortic from '../../assets/font-svgs/welcome-to-vortic.svg';
import Exhibitions from '../../assets/font-svgs/exhibitions.svg';
import Galleries from '../../assets/font-svgs/galleries.svg';
import Institutions from '../../assets/font-svgs/institutions.svg';
import Collectives from '../../assets/font-svgs/collectives.svg';
import Opps from '../../assets/font-svgs/Opps.svg'
import Collaborations from '../../assets/font-svgs/collaborations.svg';
import {ISvgHeaderProps} from "./types";

const Svg = {
    WelcomeToVortic,
    Exhibitions,
    Galleries,
    Institutions,
    Collectives,
    Opps,
    Collaborations,
};

const SvgHeader:React.FC<ISvgHeaderProps> = ({type,noAnimate}) => {

    const [scrollY, setScrollY] = useState(0);

    const handleScroll = () => {
        if(window.pageYOffset < 400){
            setScrollY(window.pageYOffset/1000);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    return (
        <>
            <div className={`svg-header ${type === 'WelcomeToVortic' ? 'welcome-to-vortic' : ''} ${type==='Opps'?'error-opps':''} `} style={noAnimate?{}:{ transform: `scale(${(scrollY/1.5)+ 1})`, opacity: 1-(scrollY*4) }}>
                <img alt={type} src={Svg[type]} />
            </div>
        </>
    )
}

export default SvgHeader;
