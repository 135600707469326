export const GET_PROFILE_DATA = 'GET_PROFILE_DATA';
export const UPDATE_PROFILE_IMAGE = 'UPDATE_PROFILE_IMAGE';
export const UPDATE_PROFILE_DATA_INIT = 'UPDATE_PROFILE_DATA_INIT';
export const UPDATE_PROFILE_DATA_SUCCESS = 'UPDATE_PROFILE_DATA_SUCCESS';
export const GET_UPDATE_PROFILE_DATA_INIT = 'GET_UPDATE_PROFILE_DATA_INIT';
export const GET_UPDATE_PROFILE_DATA_SUCCESS = 'GET_UPDATE_PROFILE_DATA_SUCCESS';
export const GET_PROFILE_NOTIFICATION_DATA = 'GET_PROFILE_NOTIFICATION';
export const UPDATE_PROFILE_NOTIFICATION_SUCCESS = 'UPDATE_PROFILE_NOTIFICATION_SUCCESS';
export const GET_PROFILE_NOTIFICATION_DATA_SUCCESS = 'GET_PROFILE_NOTIFICATION_DATA_SUCCESS';
export const GET_PROFILE_NOTIFICATION_DATA_INIT = 'GET_PROFILE_NOTIFICATION_DATA_INIT';
export const UPDATE_PROFILE_NOTIFICATION_DATA_INIT = 'UPDATE_PROFILE_NOTIFICATION_DATA_INIT';
