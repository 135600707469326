import React from 'react';
import IconBtn from '../../IconBtn';

const IslandSearchFilterNav = () => {
    return (
        <div className='island-nav__search-filter'>
            <IconBtn type="searchBright" large />
            <IconBtn type="brightCloseSecondary" large bright />
            <IconBtn type="filterBright" large />
        </div>
    );
}

export default IslandSearchFilterNav;