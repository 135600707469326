import {FETCH_TOTAL_UNREAD_SUCCESS, SET_ENQUIRE_MESSAGE, SET_ALL_CONVERSATIONS, SEND_BIRD_SUCCESS, SET_CURRENT_CONVERSATION} from './actionTypes';
import {act} from "react-dom/test-utils";
import uniqBy from "lodash/uniqBy";

const initialState = {
    conversationList: [
        // {
        //     id: 123,
        //     title: 'Angela S.',
        //     galleyName: 'Frith Street Gallery',
        //     galleryTime: '8 mins ago',
        //     message: 'Most recent message preview text. Gets cut of the stuff.'
        // },
        // {
        //     id: 134,
        //     title: 'John Doe.',
        //     galleyName: 'Frith Street Gallery',
        //     galleryTime: '8 mins ago',
        //     message: 'Most recent message preview text. Gets cut of the stuff.'
        // },
        // {
        //     id: 338,
        //     title: 'William.',
        //     galleyName: 'Frith Street Gallery',
        //     galleryTime: '8 mins ago',
        //     message: 'Most recent message preview text. Gets cut of the stuff.'
        // },
        // {
        //     id: 782,
        //     title: 'ABD.',
        //     galleyName: 'Frith Street Gallery',
        //     galleryTime: '8 mins ago',
        //     message: 'Most recent message preview text. Gets cut of the stuff.'
        // },
        // {
        //     id: 222,
        //     title: 'London.',
        //     galleyName: 'Frith Street Gallery',
        //     galleryTime: '8 mins ago',
        //     message: 'Most recent message preview text. Gets cut of the stuff.'
        // },
        // {
        //     id: 322,
        //     title: 'Erica.',
        //     galleyName: 'Frith Street Gallery',
        //     galleryTime: '8 mins ago',
        //     message: 'Most recent message preview text. Gets cut of the stuff.'
        // },
        // {
        //     id: 263,
        //     title: 'Ahmed.',
        //     galleyName: 'Frith Street Gallery',
        //     galleryTime: '8 mins ago',
        //     message: 'Most recent message preview text. Gets cut of the stuff.'
        // }
    ],
    currentConversation: null,
    totalUnread: null,
    enquireMessage: null
};

function reducer (state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_CONVERSATION:
            return { ...state, currentConversation: action.payload };

        case FETCH_TOTAL_UNREAD_SUCCESS:
            return { ...state, totalUnread: action.payload };

        case SET_ALL_CONVERSATIONS:
            return {
                ...state,
                conversationList: uniqBy([...action.payload, ...state.conversationList], function (e) {
                    return e.url;
                })
             };

        case SEND_BIRD_SUCCESS:
            return { ...state, totalUnread: 2 };

        case SET_ENQUIRE_MESSAGE:
            return { ...state, enquireMessage: action.payload };

        default:
            return state
    }
}

export default reducer
