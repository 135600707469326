import React, { useEffect } from "react";
import PlayBtn from "../../assets/icons/listviewVideoPlay.svg";
import { Paragraph } from "../Typography2";
import Button from "../Button";
import { IAboutVideoProps } from "./types";
import "./style.scss";

const AboutVideo:React.FC<IAboutVideoProps> =({
  videoRef,
  src = [],
  onClick,
  poster,
  classNames = "",
  listview = false,
  secondary
}) => {

  useEffect(() => {
    const prefixes = ["", "moz", "webkit", "ms"];

    prefixes.forEach(function (prefix) {
      document.addEventListener(prefix + "fullscreenchange", function (e) {
        const fullscreenelement =
          document.fullscreenElement /* Standard syntax */ ||
          document[
            "webkitFullscreenElement"
          ] /* Chrome, Safari and Opera syntax */ ||
          document["mozFullScreenElement"] /* Firefox syntax */ ||
          document["msFullscreenElement"]; /* IE/Edge syntax */
        if (!fullscreenelement) {
          videoRef?.current.pause();
        }
      });
    });

    return () => {};
  }, []);

  return (
    <div className={`about-video ${listview ? 'about-video--listview' : ''} ${classNames}`}>
      <video
        controls={false}
        autoPlay={false}
        ref={videoRef || videoRef}
        poster={poster}
        style={secondary ? { height: 0, width: 0 } : {}}
      >
        {
            Array.isArray(src) ?
          src.map((source, index) => (
              <source src={source} key={index}/>
          ))
                :
                <source src={src} />
        }
      </video>
      <div className="custom-video-poster" onClick={onClick}>
          <img src={poster} alt="poster" />
      </div>
      {!secondary ? <div className="play-btn-wrapper" onClick={onClick}>
        <div className="play-button">
          <img src={PlayBtn} alt="Play" />
          {listview && <Paragraph>Watch video</Paragraph>}
        </div>
      </div>
      :
        <Button
          className="button__hollow full-width"
          value="Watch Video"
          iconType="PlayButtonCircleLight"
          onClick={onClick}
        />
      }
    </div>
  );
}

export default AboutVideo;
