import {
    FETCH_GALLERIES_INIT,
    FETCH_GALLERIES_SUCCESS,
    SET_GALLERIES_LOADER,
    UPDATE_SORT_BY,
    RESET_GALLERIES,
} from './actionTypes'

export function fetchGalleriesInit(page, filter, isGroup, type) {
    return {
        type: FETCH_GALLERIES_INIT,
        payload: {
            page, filter, isGroup, type
        }
    }
}

export function fetchGalleriesSuccess(data) {
    return {
        type: FETCH_GALLERIES_SUCCESS,
        payload: data
    }
}

export function setGalleriesLoader(data) {
    return {
        type: SET_GALLERIES_LOADER,
        payload: data
    }
}

export function updateSortBy(value) {
    return {
        type: UPDATE_SORT_BY,
        payload: value
    }
}

export function resetGalleries() {
    return {
        type: RESET_GALLERIES,
    }
}
