import { connect } from 'react-redux';
import GlobalPage from './Global'
import '../../assets/fonts/fonts.css'
import { getUpdatedProfileDataInit } from '../Profile/redux/actions';
import {setConversation, setViewHeight, setArtworkId, setIsError, setExhibitionId} from './redux/actions';
import {fetchSendBirdInit, setEnquireMessage, setCurrentConversation} from '../Conversation/redux/actions';
import {} from "../Conversation/redux/actions";

const mapStatesToProps = ({global, profile, exhibition, conversation}) => {
    return {
        isLoggedIn: global.isLoggedIn,
        vH: global.viewHeight,
        redirectPath: global.redirectPath,
        profile: profile.profileData,
        exhibitionsFirstLoad: exhibition.exhibitionsFirstLoad,
        isConversation: global.isConversation,
        isFirstLoad: global.isFirstLoad,
        artworkId: global.artworkId,
        exhibitionId: global.exhibitionsId,
        isError: global.isError,
        errorMessage: global.errorMessage,
        enquireMessage: conversation.enquireMessage,
        currentConversation: conversation.currentConversation,
        isMobileForm: global.isMobileForm
    }
};

const mapDispatchToProps = dispatch => {
    return {
        updateProfileData: () => {dispatch(getUpdatedProfileDataInit())},
        fetchSendBirdInit: (obj) => {dispatch(fetchSendBirdInit(obj))},
        setConversation: (val) => {dispatch(setConversation(val))},
        setChat: (val) => {dispatch(setConversation(val))},
        setVH: (val) => {dispatch(setViewHeight(val))},
        setArtworkId: (val) => {dispatch(setArtworkId(val))},
        setExhibitionId: (val) => {dispatch(setExhibitionId(val))},
        setIsError: (val,msg) => {dispatch(setIsError(val,msg))},
        setCurrentConversation: (conversation) => {dispatch(setCurrentConversation(conversation))},
        setEnquireMessage: (message) => {dispatch(setEnquireMessage(message))}
    }
};

export default connect(mapStatesToProps, mapDispatchToProps)(GlobalPage);
