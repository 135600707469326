import React, {useEffect, useState, Fragment} from "react";
import {
    favouriteExhibition,
    favouriteGalleries,
    fetchGalleryArtists,
    fetchGalleryDetails,
    fetchGalleryExhibitions
} from "../../../services/api";
import {useNavigate, useParams} from "react-router";
import DetailsCard from "../../../components/DetailsCard";
import DetailsHeader from "../../../components/DetailsHeader";
import Card from "../../../components/Card";
import Exhibition from "../../../components/Exhibition";
import Spinner from "../../../components/Spinner";
import {IGalleryDetails} from './types'
import {Heading, Label, Paragraph, Anchor} from "../../../components/Typography2";
import {constructImageUrl, getIdFromSlug} from '../../../utils'
import LazyLoad from 'react-lazy-load';
import ScrollAnimation from '../../../components/ScrollAnimation/ScrollAnimation';
import ArrowDownDark from "../../../assets/icons/down-dark.svg";
import Instagram from "../../../assets/icons/instagram.svg";
import Facebook from "../../../assets/icons/facebook-dark.svg";
import Linkedin from "../../../assets/icons/linkedin-dark.svg";
import TwitterDark from "../../../assets/icons/twitter-dark.svg";
import HeadTags from "../../../components/Helmet/HeadTags";
import RenderHtml from "../../../components/CollateralLayouts/RenderHtml";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";

const GalleryDetails = (props) => {
    const initalDetails = {
        id: null,
        image: {},
        about: null,
        overview: '',
        slug: '',
        name: '',
        locations: '',
        postCode: '',
        apartmentNo: '',
        email: '',
        phoneNumber: '',
        longDescriptionHtml: '',
        streetNo: '',
        city: '',
        state: '',
        country: '',
        isFavourite: false,
        links: [],
    }
    const [showAllDetails, toggleAllDetails] = useState(false);

    const { id: galleryId } = useParams();
    const navigate = useNavigate();
    const [details, setDetails] = useState<IGalleryDetails>(initalDetails)
    const [exhibitions, setExhibitions] = useState([])
    const [artists, setArtists] = useState([])
    const [isLoadingDetails, setLoadingDetails] = useState(true)
    const [isLoadingArtists, setLoadingArtists] = useState(true)
    const [isLoadingExhibitions, setLoadingExhibitions] = useState(true)
    const [currentExhibitions, setCurrentExhibitions] = useState(null);
    const [pastExhibitions, setPastExhibitions] = useState(null);
    const [socialLinks, setSocialLinks] = useState([]);
    const [webLink, setWebLink] = useState(null);
    //@ts-ignore
    // const galleryId = route && route.params && route.params.id;
    const { setContentType } = useTrackingCode();

    const loadGalleryDetails = async () => {
        return new Promise(async (resolve, reject) => {
            let galleryDetails = await fetchGalleryDetails(galleryId, !!props.preview);
            if (galleryDetails.data) {
                setDetails(galleryDetails.data)
                setSocialLinks(galleryDetails.links)
                setLoadingDetails(false);
                resolve(true)
            } else {
                resolve(true)
            }
        })
    };

    useEffect(() => {
        const tempExhibitions = [...exhibitions];
        const currentExhibs = [];
        const pastExhibs = [];
        tempExhibitions.map(exhibition => {
            if (exhibition.data.isLive) {
                currentExhibs.push(exhibition);
            }
            else {
                pastExhibs.push(exhibition);
            }
        })

        setCurrentExhibitions(currentExhibs);
        setPastExhibitions(pastExhibs);
    }, [exhibitions])
    const loadGalleryExhibitions = async () => {
        const exhibitionsResp = await fetchGalleryExhibitions(galleryId, !!props.preview);
        if (exhibitionsResp.data) {
            setExhibitions(exhibitionsResp.data);
            setLoadingExhibitions(false);
        }
    };

    const loadGalleryArtists = async () => {
        return new Promise(async (resolve, reject) => {
            const artistsResp = await fetchGalleryArtists(galleryId, !!props.preview);
            if (artistsResp.data) {
                setArtists(artistsResp.data);
                setLoadingArtists(false);
                resolve(true)
            } else {
                resolve(true)
            }
        })
    };

    useEffect(() => {
        setContentType('gallery-details-page');
        window.scrollTo(0, 0)
        props.setRedirectPath('')
        loadData()

    }, [])

    const loadData = async () => {
        const detailsResp = await loadGalleryDetails();
        if (detailsResp) {
            const artistsResp = await loadGalleryArtists();
            if (artistsResp) {
                loadGalleryExhibitions();
            }
        }
    }

    const handleArtistClick = (artistId) => {
        navigate(`/artists/${artistId}`)
    };

    const handleExhibitionClick = (id) => {
        navigate(`/exhibitions/${id}`)
    };

    const toggleFavourite = async (id) => {
        if (props.globalProps.isLoggedIn) {
            const resp = await favouriteGalleries(id);
            if (resp.data) {
                const updatedDetails = {
                    ...details,
                    isFavourite: resp.data.isFavourite
                };
                props.updateFavourite();
                setDetails(updatedDetails);
            }
        } else {
            navigate(`/login?/galleries/${galleryId}`)
        }
    };

    const toggleExhibitionFavourite = async (exhibitionId) => {
        if (props.globalProps.isLoggedIn) {
            let resp = await favouriteExhibition(exhibitionId)
            if (resp.data) {
                let newExhibitions = [];
                exhibitions.map(exhibition => {
                    if (exhibition.data.id === exhibitionId) {
                        exhibition = {
                            ...exhibition,
                            data: {
                                ...exhibition.data,
                                isFavourite: resp.data.isFavourite
                            }
                        }
                    }
                    newExhibitions.push(exhibition)
                    return exhibition;
                })
                setExhibitions(newExhibitions)
            }
        } else {
            navigate(`/login?/galleries/${galleryId}`)
        }
    }

    const formatAddress = () => {
        let address = '';
        if (details.streetNo) {
            address += details.streetNo + " "
        }
        if (details.apartmentNo) {
            address += details.apartmentNo + " "
        }
        if (details.city) {
            address += details.city + " "
        }
        if (details.state) {
            address += details.state + " "
        }
        if (details.postCode) {
            address += details.postCode + " "
        }
        if (details.country) {
            address += details.country + " "
        }
        return address;
    }

    const getWebsiteLink = () => {
        // const tempLinks = socialLinks ? socialLinks : details.links;
        const tempLinks = details.links;
        if(tempLinks){
            const website = tempLinks.filter(link => link?.linkType === "web");
            const final = website[website.length - 1];
            return final;
        }
    }

    useEffect(() => {
        if(details.links || socialLinks) {
            setWebLink(getWebsiteLink())
        }
    }, [socialLinks, details])

    const getSocialLinks = () => {
        const initialLinks = {
            instagram: '',
            twitter: '',
            facebook: '',
            linkedin: '',
        }
        const links = {...initialLinks};
        // const tempLinks = socialLinks?.length > 0 ? socialLinks : details.links;
        const tempLinks = details.links;
        tempLinks && tempLinks.map(link => {
            if (link && link.data && link.data.linkType !== 'web' && link.data.showOnProfile) {
                links[link.data.linkType] = link.data.url
            }
        })
        if (JSON.stringify(links) === JSON.stringify(initialLinks)) {
            return null;
        } else {
            return (
                <Fragment>
                    {
                        links.instagram &&
                        <a target="_blank"
                           href={links.instagram[0] === "@" ? `https://instagram.com/${links.instagram.slice(1)}` : `https://instagram.com/${links.instagram}`}><img
                            src={Instagram} alt="Instagram"/></a>
                    }
                    {
                        links.facebook &&
                        <a target={"_blank"} href={!!links.facebook.includes('https://')?links.facebook : 'https://'+links.facebook}><img src={Facebook} alt="Facebook"/></a>
                    }
                    {
                        links.linkedin &&
                        <a target={"_blank"} href={'https://'+links.linkedin}><img src={Linkedin} alt="Linkedin"/></a>
                    }
                    {
                        links.twitter &&
                        <a target={"_blank"} href={'https://twitter.com/'+links.twitter}><img src={TwitterDark} alt="Twitter"/></a>
                    }
                </Fragment>
            )
        }
    }
    const renderExhibition = (type, exhibitions) => (
        <ScrollAnimation>
            <>
                <DetailsHeader title={type}/>
            </>
            <div className="cards-container">
                {
                    exhibitions.length > 0 ? exhibitions.map((exhibition: any) => {
                        const {
                            id,
                            name,
                            startedAt,
                            endedAt,
                            image,
                            organisation,
                            isFavourite,
                            curator,
                            slug,
                            uuid,
                            isDateVisible
                        } = exhibition.data;

                        // TODO: Need to make this favourites work here
                        return (
                            <div key={id}>
                                <Exhibition
                                    id={id}
                                    slug={slug}
                                    exhibitionImg={image && [constructImageUrl(image,"1920"), constructImageUrl(image,"1920")]}
                                    title={name}
                                    isFavourite={isFavourite}
                                    onClick={handleExhibitionClick}
                                    galleryName={ organisation?.data?.type === "vortic" ? (curator?.data?.title && `Vortic Curated x ${curator?.data?.title}`) : organisation?.data?.name}
                                    startedAt={startedAt}
                                    endedAt={endedAt}
                                    noScroll={false}
                                    locations={organisation && organisation.data.locations}
                                    toggleFavourite={toggleExhibitionFavourite}
                                    isVorticCurate={organisation?.data?.type === "vortic"}
                                    uuid={props.preview ? (slug || id) : uuid}
                                    isDateVisible={isDateVisible}
                                />
                            </div>
                        )
                    }) : (<>
                        <Paragraph value={`No ${type} Found.`}/>
                        {type === "Current Exhibitions" && <>
                            <br/>
                            <br/>
                            <br/>
                        </>
                        }
                    </>)
                }
            </div>
        </ScrollAnimation>
    )
    return (
        (isLoadingDetails === false) ? (
            <div className='container gallery-details-wrapper'>
                <HeadTags
                    title={details?.name}
                    description={details?.overview}
                    image={constructImageUrl(details.image,"350")}
                    type={'galleries'}
                    id={details?.id}
                />
                <div className="details__container">
                    <DetailsCard
                        id={details && details.id}
                        type="gallery"
                        slug={details && details.slug}
                        title={details && details.name} location=""
                        address={details?.city && (`Located in ${details.city}${!!details.country ? `, ${details.country}` : ''}`)}
                        imgSrc={details.image && details.image.data && [constructImageUrl(details.image,"820"),constructImageUrl(details.image,"820")]}
                        isFavourite={details && details.isFavourite}
                        description={null}
                        style={{paddingBottom: 0, marginBottom: 0}}
                        toggleFavourite={(id) => toggleFavourite(id)}
                    />
                    {
                        (details?.longDescriptionHtml || details?.overview) &&
                        <ScrollAnimation style={{position: 'relative', zIndex: '0'}}>
                            <div className="gallery-content-wrapper">
                                <div className='details__flex details__press details__media-wrapper fill-screen'>

                                    {/* {details?.overview && <Paragraph value={details.overview}/>} */}
                                    {details?.longDescriptionHtml && <RenderHtml data={details?.longDescriptionHtml} />}


                                </div>
                                <div className="gallery-contact-info">
                                    {
                                        details && formatAddress() &&
                                        <article>
                                            <Heading value="Address:"/>
                                            <div className="anchor-wrapper no-link">
                                                <Paragraph value={formatAddress()}/>
                                            </div>
                                        </article>
                                    }

                                    {
                                        details.email &&
                                        <article>
                                            <Heading value="Email:"/>
                                            <div className="anchor-wrapper">
                                                <Anchor target={"_blank"} to={`mailto:${details.email}`}
                                                        value={details.email}/>
                                            </div>
                                        </article>
                                    }
                                    {
                                        details.phoneNumber &&
                                        <article>
                                            <Heading value="Telephone:"/>
                                            <div className="anchor-wrapper">
                                                <Anchor target={"_blank"} to={`tel:${details.phoneNumber}`}
                                                        value={details.phoneNumber}/>
                                            </div>
                                        </article>
                                    }
                                    {
                                        details.links && details.links.length > 0 ? details.links.map((link, index) => {
                                            if (link && link.data && link.data.linkType === "web" && link.data.showOnProfile) {
                                                return (
                                                    <article key={index}>
                                                        <Heading value="Online:"/>
                                                        <div className="anchor-wrapper">
                                                            <Anchor target={"_blank"} to={ link.data.url?.includes('http') ? link.data.url : 'https://'+link.data.url}
                                                                    value={link.data.url}/>
                                                        </div>
                                                    </article>
                                                )
                                            }
                                        }) : 
                                            !!webLink?.data?.url && 
                                            <article>
                                                <Heading value="Online:"/>
                                                <div className="anchor-wrapper">
                                                    <Anchor target={"_blank"} to={ webLink?.data?.url?.includes('http') ? webLink?.data?.url : 'https://'+webLink?.data?.url}
                                                            value={webLink?.data?.url}/>
                                                </div>
                                            </article>
                                    }

                                    {!!getSocialLinks() && <article>
                                        <Heading value="Social:"/>
                                        <div className="gallery-info-links">
                                            {
                                                getSocialLinks()
                                            }
                                        </div>
                                    </article>}
                                </div>
                            </div>
                        </ScrollAnimation>
                    }
                </div>

                {
                    isLoadingArtists === false && artists.length > 0 &&
                    <div className="gallery-details-artists">
                        <ScrollAnimation>
                            <DetailsHeader title={artists.length>1?"Artists":"Artist"}/>
                        </ScrollAnimation>
                        <div className="artists">
                            {
                                artists.map((artist, index) => {
                                    return (
                                        <Card
                                            key={index}
                                            imgSrc={artist.data.image && [artist.data && constructImageUrl(artist.data.image,"600"), constructImageUrl(artist.data.image,"600")]}
                                            title={artist.data.name}
                                            description={artist.data.description}
                                            onClick={() => handleArtistClick((artist?.data?.slug || artist?.data?.id))}
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                }

                {
                    isLoadingExhibitions === false &&
                    <>
                        {renderExhibition("Current Exhibitions",currentExhibitions)}
                        {renderExhibition("Past Exhibitions",pastExhibitions)}
                    </>
                }
            </div>
        ) : <Spinner/>
    )
};

export default GalleryDetails;
