import {IS_DEVELOPMENT} from '../components/Constants';

const logger = {
    debug: IS_DEVELOPMENT ? console.debug : () => {},
    info: IS_DEVELOPMENT ? console.info : () => {},
    log: IS_DEVELOPMENT ? console.log : () => {},
    error: IS_DEVELOPMENT ? console.error : () => {},
}

export default logger;
