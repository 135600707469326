const formatExhibitions = (exhibitions) => {
    return exhibitions.map(exhibition => {
        return {
            ...exhibition,
            scenes: formatSceneData(exhibition.scenes)
        }
    })
}

const formatSceneData = (scenes) => {
    return {
        ...scenes,
        data: {
            ...scenes.data,
            HDRProbe: {
                ...scenes.data.HDRProbe,
                data: {
                    ...scenes.data.HDRProbe.data,
                    signedUrl:  `${scenes.data.HDRProbe.data.CFURL}${scenes.data.HDRProbe.data.name}${scenes.data.HDRProbe.data.signature}`,
                    signedUrl2xHDR: `${scenes.data.HDRProbe.data.CFURL}${getFilename(scenes.data.HDRProbe.data.name, 'hdr')}${scenes.data.HDRProbe.data.signature}`,
                    signedUrl2xPNG: `${scenes.data.HDRProbe.data.CFURL}${getFilename(scenes.data.HDRProbe.data.name, 'png')}${scenes.data.HDRProbe.data.signature}`
                }
            }
        }
    }
}

function getFilename(originalFileName, prefix) {
    const numberMatch = originalFileName.match(/\d+/);
    if (!numberMatch) {
        return null;
    }
    const baseName = originalFileName.split('.')[0];
    const modifiedBaseName = baseName + '_2x';
    return modifiedBaseName + '.' + prefix;
}

export default formatExhibitions;
