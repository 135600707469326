import React from 'react';
import "./style.scss";
import { IFeatureCardProps } from './types';
import { Paragraph } from '../Typography2';
import { constructImageUrl } from '../../utils';
import ArrowUpRight from '../../assets/icons/arrow-up-right.svg';

const FeatureCard: React.FC<IFeatureCardProps> = ({ variant = 'primary', title, content, image, handleClick, slug, id, location }) => {
  let cardClassName = 'feature-card';
  const imageUrl = constructImageUrl(image, '720');

  // Adding variant-specific classes
  if (variant === 'primary') {
    cardClassName += ' feature-card--primary';
  } else if (variant === 'secondary') {
    cardClassName += ' feature-card--secondary';
  }

  return (
    <div className={cardClassName} onClick={() => handleClick(slug || id)}>
      {imageUrl && 
        <figure className="feature-card__image__wrapper">
          <img src={imageUrl} alt={title} className="feature-card__image" />
          <div className='feature-card__image__redirect-cover'>
            <img src={ArrowUpRight} alt="arrow-up-right" />
          </div>
        </figure>
      }
      <div className="feature-card__body">
        <h3 className="feature-card__body__title">{title}</h3>
        {location && <p className='feature-card__body__sub-title'>{location}</p>}
        <Paragraph className="feature-card__body__description" value={content} />
      </div>
    </div>
  );
};

export default FeatureCard;
