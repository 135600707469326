import React, {useState, useRef, useEffect} from 'react';
import { motion } from "framer-motion";
import "./dragger.scss"
import { InitialData} from "../SideBar/types";
import {SideBar} from "../SideBar/SideBar";
import {GalleryItem} from "../GalleryList/types";

const viewHeight = window.innerHeight;

interface IProps {
  setToggle: (value: boolean) => void,
  setParentCall: (value: string) => void,
  initialData:InitialData
  toggle:boolean,
  isGalleyLoaded:boolean,
}


const Dragger =({ initialData, toggle, setToggle, setParentCall, isGalleyLoaded }:IProps) => {
  const [dragDirection, setDragDirection] = useState(null);
  const [positionY, setPositionY] = useState(viewHeight);
  const [key, setKey] = useState(0);
  const draggableBoxRef = useRef(null);
  const [isActive, setIsActive] = useState(false);

  const [constraints, setConstraints] = useState({
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  })

  useEffect(() => {
    document.querySelector("html").classList.add("scrollbar-hidden");
    document.querySelector("body").classList.add("scrollbar-hidden");

    return () => {
      document.querySelector("html").classList.remove("scrollbar-hidden");
      document.querySelector("body").classList.remove("scrollbar-hidden");
    }

  }, [])

  const calculateHeigth = () => {
    if(draggableBoxRef.current){
      const draggableBoxHeight = draggableBoxRef.current.clientHeight;
      setConstraints({...constraints, top: viewHeight - draggableBoxHeight, bottom: viewHeight + draggableBoxHeight})
      setPositionY(viewHeight + draggableBoxHeight);
    }
  }

  // Set boundry of box height
  useEffect(() => {
    calculateHeigth();
  }, [draggableBoxRef.current])

  const handleDrag = (event, info) => {
    if (info.offset.y > 0) {
      setDragDirection("down");
    } else if (info.offset.y < 0) {
      setDragDirection("up");
    }
  };

  const handleDragEnd = () => {
    if(dragDirection === "down") {
      setPositionY(viewHeight);
      setIsActive(!isActive);
      setToggle(false);
    } else {
      setParentCall("");
    }
    setKey(key+1)
  };

  const handleToggle = () => {
    if(!toggle) {
      setPositionY(viewHeight);
      setParentCall("reset");
    } else {
      setPositionY(viewHeight - draggableBoxRef.current.clientHeight);
      setParentCall("");
    }
  }

  useEffect(()=>{
    handleToggle();
  },[toggle])


  return (
    <motion.div className={`dragger-test disable-dbl-tap-zoom ${!isGalleyLoaded ? 'hidden' : ''}`}>
      <motion.div
        onDrag={handleDrag}
        onDragEnd={handleDragEnd}
        className="draggable-box"
        drag={"y"}
        dragConstraints={constraints}
        animate={{ y: positionY }}
        transition={{ duration: 1 }}
        ref={draggableBoxRef}
      >
        <SideBar initialData={initialData} dragDirection={dragDirection} isMobileSlider getDraggerClientHeight={calculateHeigth} setToggle={setToggle} />
      </motion.div>
    </motion.div>
  )
}


export default Dragger;
