import React, { useEffect, useState } from 'react'
import "./NewsLetter.scss";
import {submitNewsLetter} from '../../services/api'
import IconBtn from '../IconBtn';
import Send from '../../assets/icons/send-white.svg';
import Input from "../Input";
import Button from "../Button";
import {Paragraph} from "../Typography2";
import rudderStackEvents from "../../services/rudderstack-events";
export default function NewsLetter({
    handleClose,
    setCookie,
    isNewsLetterSubmited
}) {
    const [isSubmit, setIsSubmit] = useState(false)
    const [nameErr, setNameErr] = useState(false)
    const [emailErr, setEmailErr] = useState(false)
    const [state, setState] = useState({
        name: "",
        email: "",
    })
    const [steps, setSteps] = useState({
        section1: false,
        section2: false,
    })
    const [isHiding, setIsHiding] = useState(false)
    const [isFooterIn, setIsFooterIn] = useState(false);

    useEffect(() => {
        if(window.innerWidth <= 768){
            setSteps({
                section1:true,
                section2:false,
            })
        }
        else{
            setSteps({
                section1:true,
                section2:true,
            })
        }

        let lastScroll = 0;
        let IntervalId = null;
        window.addEventListener('scroll', () => {
            const footerBounds = document.querySelector(".footer")?.getBoundingClientRect();
            const windowHegiht = Math.ceil(window.innerHeight);
            if((document.querySelector(".footer") && footerBounds.top < windowHegiht )){
                setIsFooterIn((document.querySelector(".footer") && footerBounds.top < windowHegiht ));
            }
            else{
                lastScroll++;
                if(IntervalId) clearInterval(IntervalId);
                IntervalId = setTimeout(()=>{setIsFooterIn(false)}, 100)
            }
            if(lastScroll >= 50){
                lastScroll = 0;
                setIsFooterIn(false);
            }
        })
    }, []);

    const handleSubmit = async (e) => {
        if(!state.name) {
            setNameErr(true)
        }
        else {
            setNameErr(false)
        }
        if(!state.email) {
            setEmailErr(true)
        }
        else if(!validateEmail(state.email)){
            setEmailErr(true)
        }
        else {
            setEmailErr(false)
        }
        if(!state.name || !state.email || !validateEmail(state.email)){
            return;
        }
        setIsSubmit(true);
        try {
            rudderStackEvents.onJoiningMailingList('join_mailing_list')
            const resp = await submitNewsLetter(state)
            if (resp.success) {
                setCookie();
                setTimeout(()=>{
                    setIsHiding(true);
                    setTimeout(()=>{
                        handleClose();
                    },1000)
                }, 10000)
            } else {
            }
            setIsSubmit(false);
        } catch (e) {
            setIsSubmit(false);
        }
    }

    const handleChange = ({target:{name, value}}) => {
        if(name === "name") {
            setNameErr(false)
        }
        else if(name === "email") {
            setEmailErr(false)
        }
        setState(prevState => ({
            ...prevState,
            [name]:value
        }))
    }

    function validateEmail(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    const handleSignupButtonClick = () => {
        setSteps((prevState)=>({
            ...prevState,
            section1:false,
            section2:true,
        }));
    }

    return (
        <div
            className={`newsLetter ${!!isFooterIn ? "noSticky" : ""} ${isNewsLetterSubmited?"small":""}`}
            style={
                {
                    ...(isNewsLetterSubmited ? { justifyContent: "space-between"} : {}),
                    ...(isHiding ? { opacity: 0} : {}),
                }
            }
        >
            <div className={'newsLetter-top'}>
                {
                    (steps.section1 || isNewsLetterSubmited) &&
                    <Paragraph
                    value={
                        isNewsLetterSubmited ?
                        `Thank you!`
                        :
                        "Join our mailing list to learn about new exhibitions and upcoming Vortic Sessions from the world’s best institutions."}
                    />
                }
                <div className="buttonContainer buttonContainer-mobile-view">
                    <IconBtn
                       filterImg='filter-img'
                        className="closeButton"
                        type="cross"
                        onClick={handleClose}
                    />
                </div>
            </div>



                    {(!isNewsLetterSubmited) &&
                    <div className={'input-container'}>
                        <>
                        {

                            steps.section2 &&
                            <>
                                <Input
                                    name="name"
                                    onChange={handleChange}
                                    placeholder="Full name"
                                    type="text"
                                    errorMsg={nameErr}
                                    active={true}
                                    className={''}
                                />
                                <Input
                                    name="email"
                                    errorMsg={emailErr}
                                    active={true}
                                    onChange={handleChange}
                                    placeholder="Email address"
                                    type="email"
                                    className={''}
                                />
                            </>
                        }
                        {
                            steps.section1 && window.innerWidth <= 768 &&
                            <Button
                                value={'Sign up'}
                                className={"newLetterButton"}
                                onClick={handleSignupButtonClick}
                            />
                        }
                        {
                            steps.section2 &&
                            <Button
                                value={'Submit'}
                                iconType={"submit"}
                                disable={!(!!state.name && !!state.email && validateEmail(state.email))}
                                className={"newLetterButton"}
                                onClick={handleSubmit}
                            />
                        }

                        <div className="buttonContainer">
                            <IconBtn
                                filterImg='filter-img'
                                className="closeButton"
                                type="cross"
                                onClick={handleClose}
                            />
                        </div>
                    </>
                    </div>
                    }
        </div>
    )
}
