import React, { useEffect, useRef, useState } from "react";
import smoothscroll from "smoothscroll-polyfill";
import { isMobileDevice } from "../../utils";
import Button from "../Button";
import { Label, Title } from "../Typography2";
import "./style.scss";

const ListviewHead = ({ galleryName, exhibitionName, handleExploreClick, headeHeight = 0, isArtistVisible, artists }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeSection, setActiveSection] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      const listviewWrapper = document.querySelector(
        ".curate-flexbox--listview"
      );
      if (listviewWrapper.scrollTop > window.innerHeight - 65) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    const listviewWrapper = document.querySelector(".curate-flexbox--listview");
    if (listviewWrapper) {
      listviewWrapper.addEventListener("scroll", handleScroll, {
        passive: true,
      });
    }
    return () => {
      if (listviewWrapper) {
        listviewWrapper.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const getScrolledGap = (elm) => {
    if (!(isMobileDevice() || window.innerWidth <= 640)) {
      return 0;
    }
    switch (elm) {
      case "#listview-artists":
        return 0;
      case "#listview-about":
        return 60;
      case "#listview-artworks":
        return 40;
    }
  };

  const handleNavClick = (elm: any) => {
    setActiveSection(elm);
    const listviewWrapper = document.querySelector(".curate-flexbox--listview");
    let positionY = 0;
    positionY =
      listviewWrapper.scrollTop +
      document.querySelector(elm)?.getBoundingClientRect().top -
      getScrolledGap(elm);
    listviewWrapper.scroll({ top: positionY, left: 0, behavior: "smooth" });
  };

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  return (
    <div
      className={`listview-header-wrapper`}
    >
      <div className="listview-header" style={headeHeight < 0 ? { top : headeHeight } : {}}>
        <div className="content">
          <Label className="gallery-label" value={galleryName} />
          <Title className="sub-title">{exhibitionName}</Title>
        </div>
        <div className="list">
          <Button
            onClick={() => handleNavClick("#listview-about")}
            className={`desktop ${activeSection === "#listview-about" ? "active" : ""}`}
            value="About the exhibition"
          />
          <Button
            onClick={() => handleNavClick("#listview-about")}
            className={`mobile ${activeSection === "#listview-about" ? "active" : ""}`}
            value="About"
          />
          <Button
            onClick={() => handleNavClick("#listview-artworks")}
            className={activeSection === "#listview-artworks" ? "active" : ""}
            value="Artworks"
          />
          {
            isArtistVisible && artists?.length > 0 &&
            <Button
              onClick={() => handleNavClick("#listview-artists")}
              className={activeSection === "#listview-artists" ? "active" : ""}
              value="Artist"
            />
          }
        </div>
        <Button
          value="Explore exhibition in 3D"
          type="secondary"
          iconType="union"
          onClick={handleExploreClick}
        />

        {/* List View Mobile Scroll back up */}
        <div
          className={`list list-mobile ${
            isScrolled ? "list-mobile--scrolled" : ""
          }`}
        >
          <Button
            onClick={() => handleNavClick("#listview-about")}
            className={`mobile ${activeSection === "#listview-about" ? "active" : ""}`}
            value="About"
          />
          <Button
            onClick={() => handleNavClick("#listview-artworks")}
            className={activeSection === "#listview-artworks" ? "active" : ""}
            value="Artworks"
          />
          {
            isArtistVisible && artists?.length > 0 &&
            <Button
              onClick={() => handleNavClick("#listview-artists")}
              className={activeSection === "#listview-artists" ? "active" : ""}
              value="Artist"
            />
          }
          <Button
            value="Explore in 3D"
            type="secondary"
            iconType="union"
            onClick={handleExploreClick}
          />
        </div>
      </div>
    </div>
  );
};

export default ListviewHead;
