import React, {useEffect, useRef, useState, useImperativeHandle} from 'react';
import {ICarouselProps} from './types';
import './style.scss';
import {Timeline, Paragraph} from "../Typography2";
import Button from "../Button";
import ScrollAnimation from "../ScrollAnimation/ScrollAnimation";
import smoothscroll from 'smoothscroll-polyfill';
import {constructImageUrl, isHTML} from "../../utils/index"

const Carousel = React.forwardRef((props, ref) => {
    // @ts-ignore
    const {data, onClickItem, type, setCarouselState, onMouseEnter, onMouseLeave}: ICarouselProps = props;
    const [activeItem, setActiveItem] = useState(1)
    const [activeItemData, setActiveItemData] = useState(data[0])
    const [nextEnabled, setNextEnabled] = useState(false)
    const [prevEnabled, setPrevEnabled] = useState(false)
    const ScrollRef = useRef(null)
    const ScrollItem = useRef(null)
    const ScrollItemImg = useRef(null)
    const actualImage = useRef(null)
    const [visibleData, setVisibleData] = useState([data[0]])

    useEffect(() => {
        smoothscroll.polyfill();
    }, []);

    const handlePrevChange = () => {
        if (activeItem > 1) {
            const scroller = ScrollRef.current
            if (window.innerWidth > 780) {
                scroller.scrollLeft -= (window.innerWidth <= 780 ? ScrollItemImg.current.clientWidth : 247)
                setActiveItem(activeItem - 1)
            } else {
                scroller.scroll({left: scroller.scrollLeft - ScrollItem.current.clientWidth, behavior: 'smooth'});
            }
            setPrevEnabled(true)
            if (window.innerWidth < 554) {
                setTimeout(() => {
                    setPrevEnabled(false)
                }, 140)
            } else {
                setTimeout(() => {
                    setPrevEnabled(false)
                }, 290)
            }
        }
    };

    const handleNextChange = () => {
        const tempVisible = [...visibleData];
        if(visibleData.length<data.length) {
            tempVisible.push(data[tempVisible.length]);
            setVisibleData(tempVisible);
        }
            setTimeout(() => {
                let scroller = ScrollRef.current
                if (activeItem < data.length) {
                    if (window.innerWidth > 780) {
                        scroller.scrollLeft += (window.innerWidth <= 780 ? ScrollItemImg.current.clientWidth : 247)
                        setActiveItem(activeItem + 1)
                    } else {
                        scroller.scroll({
                            left: scroller.scrollLeft + ScrollItem.current.clientWidth,
                            behavior: 'smooth'
                        });
                    }
                    setNextEnabled(true)
                    if (window.innerWidth < 554) {
                        setTimeout(() => {
                            setNextEnabled(false)
                        }, 140)
                    } else {
                        setTimeout(() => {
                            setNextEnabled(false)
                        }, 290)
                    }
                } else {
                    setNextEnabled(true);
                }
            }, 50)

    }


    const handleScroll = (e) => {
        if (window.innerWidth <= 780) {
            let val = (e.target.scrollLeft / ScrollItem.current.clientWidth).toFixed(0);
            return setActiveItem(parseInt(val) + 1);
        }
    };

    useEffect(() => {
        setActiveItemData(data[activeItem - 1]);
    }, [])

    useEffect(() => {
        setCarouselState(activeItem);
        setActiveItemData(data[activeItem - 1]);
    }, [activeItem]);

    const getDimensionsInCM = (details) => {
        return `${parseFloat(details.height).toFixed(1)} x ${parseFloat(details.width).toFixed(1)} x ${parseFloat(details.depth).toFixed(1)} cm`
    }

    const getDimensionsInIN = (details) => {
        return `${(parseFloat(details.height) * 0.393701).toFixed(1)} x ${(parseFloat(details.width) * 0.393701).toFixed(1)} x ${(parseFloat(details.depth) * 0.393701).toFixed(1)} in`
    }

    const onLeftArrowClick = () => {
        if (activeItem !== 1 && prevEnabled === false) {
            handlePrevChange();
        }
    }

    const onRightArrowClick = () => {
        if (activeItem !== data.length && nextEnabled === false) {
            handleNextChange();
        }
    }

    useImperativeHandle(
        ref,
        () => ({
            moveCarousel(val) {
                if (val === "left") {
                    onLeftArrowClick();
                } else if (val === "right") {
                    onRightArrowClick();
                }
            },
            resetAll() {
                setActiveItem(1);
                setActiveItemData(data[0]);
                setNextEnabled(false);
                setPrevEnabled(false);

                const scroller = ScrollRef.current;
                scroller.scroll({left: 0, behavior: 'smooth'});
            }
        }),
    );

    const renderDescription = (description) => {
        if(isHTML(description)) {
            return <p className="paragraph" dangerouslySetInnerHTML={{ __html: description }} />
        }
        else {
            return <Paragraph value={description} />
        }
      }


    const CarouselImg = (data) => (
        <div className={`carousel ${type === 'secondary' ? 'carousel-preview--secondary' : ''} `}
             onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <div className={`prev ${activeItem === 1 ? 'disabled' : ''}`}
                 onClick={prevEnabled === false && handlePrevChange}/>
            <div className={`carousel-preview`} ref={ScrollRef} onScroll={(e) => handleScroll(e)}>
                {
                    data.map((item, index) => {
                        
                        const Img = item && item.data && item.data.type === "2d" ? item.data && item.data.image && item.data.image.data && constructImageUrl(item.data.image,"1048") : item.data.type === "video" ? item.data.videoThumbnail && constructImageUrl(item.data.videoThumbnail,"1048") : item.data.thumbnail && constructImageUrl(item.data.thumbnail,"1048");
                        
                        return (
                            <>
                                <div
                                    className={`carousel-item ${index === activeItem - 1 ? 'active' : ''} ${actualImage && actualImage.current && actualImage.current.clientWidth > actualImage.current.clientHeight ? 'red' : 'blue'}`}
                                    key={item.data.id} ref={index === 0 ? ScrollItem : null}
                                >
                                    <div className='img' ref={ScrollItemImg}>
                                        <img onClick={() => onClickItem(activeItemData.data.id, activeItemData)}
                                             ref={actualImage} src={Img}
                                             alt='Preview'
                                             className={`actual-image ${index === activeItem - 1 ? 'img_' : ''}`}/>
                                    </div>
                                </div>
                            </>
                        )
                    })
                }
                <div className='emptyGap' />
            </div>
            <div className={`next ${activeItem === data.length ? 'disabled' : ''}`}
                 onClick={nextEnabled === false && handleNextChange}/>
        </div>
    )

    return (
        <ScrollAnimation>
            <div
                className={`carousel-wrapper ${(type === "secondary") ? 'carousel--secondary' : ''} ${type === "secondary" && window.innerWidth < 554 ? 'carousel--secondary-responsive' : ''}`}>
                <div className='flex carousel-content'>
                    {CarouselImg(visibleData)}
                    <div>
                        <div className="carousel-flex">
                            <span/>
                            <Button
                                value='About the work'
                                className='btn__dark button__bright'
                                iconType="next"
                                onClick={() => onClickItem(activeItemData.data.id, activeItemData)}
                            />
                        </div>
                        <p className="title sub-title">
                            {activeItemData.data && activeItemData.data.artist && activeItemData.data.artist.data && (activeItemData.data.artist.data.name + ((!!activeItemData.data.title || (activeItemData.data && activeItemData.data.yearCreated)) ? ', ' : ''))}
                            {activeItemData.data.title && <span>{activeItemData.data.title}</span>}
                            {activeItemData.data && activeItemData.data.yearCreated ? ((activeItemData.data.title ? ', ' : '') + activeItemData.data.yearCreated) : ''}
                        </p>
                        <Timeline value={`${getDimensionsInCM(activeItemData.data)} / ${getDimensionsInIN(activeItemData.data)}`}/>
                        {renderDescription(activeItemData?.data?.description || '')}
                    </div>
                </div>
                
            </div>
        </ScrollAnimation>
    )
});

export default Carousel;
