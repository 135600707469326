// @ts-nocheck
import {ofType} from 'redux-observable';
import {flatMap, map, catchError} from 'rxjs/operators';
import { FETCH_TOTAL_UNREAD_INIT, SEND_BIRD_INIT } from './actionTypes'
import * as API from "../../../services/api"
import { fetchTotalUnreadSuccess, fetchSendBirdSuccess  } from './actions';
import logger from "../../../utils/logger";

const initSendBird = action$ =>
    action$.pipe(
        ofType(SEND_BIRD_INIT),
        flatMap(({payload}) => {
            logger.debug('SEND_BIRD_INIT')
            return API.initSendbird(payload);
        }),
        map((resp) => {
            return fetchSendBirdSuccess(resp);
        }),
        catchError(error => {
            logger.error('error = ', error);
            return () => {}
        })
    );

const fetchTotalUrRead = action$ =>
    action$.pipe(
        ofType(FETCH_TOTAL_UNREAD_INIT),
        flatMap(({payload}) => {
            return API.fetchTotalUnread(payload);
        }),
        map((resp) => {
            return fetchTotalUnreadSuccess(2);
        }),
        catchError(error => {
            logger.error('error = ', error);
            return () => {}
        })
    );

export default [fetchTotalUrRead, initSendBird];
