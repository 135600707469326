import React, { useState, useEffect, useCallback } from 'react';
import IconBtn from '../IconBtn';
import "./style.scss";
import RoomsSlider from './RoomsSlider';
import ExitRoomModal from '../ExitRoomModal';

// @ts-ignore
const RoomSwitchModal = React.memo(({ onClose, rooms, selectedRoomId, handleExitExhibition, handleRoomSelection, roomSwitchModalVariant }) => {

  const [isVisible, setIsVisible] = useState(false);

  const handleExitExhibitionMemoized = useCallback(handleExitExhibition, []);
  const handleRoomSelectionMemoized = useCallback(handleRoomSelection, []);
  const onCloseMemoized = useCallback(() => {
    setIsVisible(false);
  }, []);

  const isSlider = rooms?.length > 1;
  const isExitVariant = roomSwitchModalVariant === 'exit-room';

  useEffect(() => {
    setIsVisible(true);
  }, []);

  useEffect(() => {
    if (!isVisible) {
      // Add a delay to allow the transition to take effect before hiding the component
      const timeoutId = setTimeout(() => onClose(), 450); // 450ms should match the transition duration
      return () => clearTimeout(timeoutId);
    }
  }, [isVisible, onClose]);

  return (
    <div className={`room-switch-modal ${!isSlider ? 'room-switch-modal--no-slider' : ''} ${isExitVariant ? 'room-switch-modal--exit-variant' : ''} ${isVisible ? 'room-switch-modal--fade-in' : 'room-switch-modal--fade-out'}`}>
      <IconBtn onClick={onCloseMemoized} type="brightCloseSmall" secondary className={`room-switch-modal-close-icon ${isVisible ? 'room-switch-modal-close-icon--slide-down' : 'room-switch-modal--slide-up'}`} />
      {/* @ts-ignore */}
      {isSlider && <RoomsSlider rooms={rooms} selectedRoomId={selectedRoomId} handleRoomSelection={handleRoomSelectionMemoized} handleCancel={onCloseMemoized} />}
      <ExitRoomModal handleExitExhibition={handleExitExhibitionMemoized} onCancel={onCloseMemoized} isRoomSwitch={roomSwitchModalVariant === 'room-switch'} />
    </div>
  );
});

export default RoomSwitchModal;