import {combineReducers} from 'redux'
import globalReducer from '../containers/Global/redux/reducer'
import exhibitionsReducer from '../containers/Exhibitions/redux/reducer'
import galleriesReducer from '../containers/Galleries/redux/reducer'
import collectivesReducer from '../containers/Collectives/redux/reducer'
import searchReducer from '../containers/Search/redux/reducer'
import profileReducer from '../containers/Profile/redux/reducer'
import favouritesReducer from '../containers/Favourites/redux/reducer'
import conversationReducer from '../containers/Conversation/redux/reducer'
import discoverReducers from '../containers/DiscoverLayout/redux/reducer'

export default combineReducers({
    global: globalReducer,
    exhibition: exhibitionsReducer,
    gallery: galleriesReducer,
    collective: collectivesReducer,
    search: searchReducer,
    profile: profileReducer,
    favourite: favouritesReducer,
    conversation: conversationReducer,
    discover: discoverReducers,
})
