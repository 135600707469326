import React, { useEffect, useState, useRef } from "react";
import { getShareURL } from "../../utils";
import Button from "../Button";
import RenderHtml from "../CollateralLayouts/RenderHtml";
import ShareBtn from "../ShareBtn";
import { Title } from "../Typography2";
import "./style.scss";

const ListviewAbout = ({
  about,
  imageLoaded,
  exhibitionSlug,
  exhibitionId,
  toggleFavourite,
  isFavourite,
  isLoggedIn,
  setToggleLogin,
  descriptionHtml,
  uuid,
                         description
}) => {
  const [showMore, setShowMore] = useState(false);
  const [details, setDeatils] = useState("");
  const [truncate, setTruncate] = useState(true);
  const descriptionHtmlRef = useRef(null);

  const makeAboutText = () => {
    // let data = about?.data;
    // if (data) {
    //   let temp = data.text || "";
    //
    //   data.elements.forEach((element) => {
    //     if (element.data.elementType === "normal") {
    //       if (!showMore) {
    //         if (temp.split(" ").length < 100) {
    //           temp = `${temp}\xa0\n\n${element.data.text}`;
    //         }
    //       } else {
    //         temp = `${temp}\xa0\n\n${element.data.text}`;
    //       }
    //     }
    //   });
    //   if(temp) {
    //     setDeatils(temp);
    //   } else {
    //     setDeatils(descriptionHtml);
    //   }
    // } else {
    // }
    // Use description HTML instead of abouts
    setDeatils(descriptionHtml);
  };

  const handleShowMore = () => {
    setShowMore(!showMore);
    setTruncate(!truncate);
  };

  useEffect(() => {
    makeAboutText();
  }, [about, showMore, descriptionHtml]);

  const showReadMoreBtn = details.split(" ").length >= 100;

  const text =
    !showMore && details.split(" ").length > 100
      ? `${details.split(" ").slice(0, 100).join(" ")}...`
      : details;

  const handleBookmark = () => {
    if (isLoggedIn) {
      toggleFavourite();
    } else {
      setToggleLogin(true);
    }
  };

  return (
    <div
      className={`listview-about ${
        imageLoaded ? "listview-about--active" : ""
      }`}
      id="listview-about"
    >
      <div className="list-view-about-container">
      {!!description && <h1 className={"title sub-title"}>{description}</h1>}
      {!!text && <h1 className={`title sub-title ${!truncate ? "truncate" : ""}`} dangerouslySetInnerHTML={{__html: text}}></h1>}
        <div className="btn-row">
          {showReadMoreBtn && (
            <Button
              className="btn read-more-btn"
              value={!showMore ? "Read More" : "Read Less"}
              iconType={!showMore ? "plus" : "minus"}
              onClick={handleShowMore}
            />
          )}
          <div className="btn-outer btn-outer--share">
            <ShareBtn
              up
              shareUrl={getShareURL(
                "exhibition",
                exhibitionSlug || uuid
              )}
              entityUuid={uuid}
            />
            <Button
              className="btn "
              value="Share"
              iconType="share"
            />
          </div>
          <div className="btn-outer">
            <Button
              className="btn "
              value="Bookmark"
              iconType={isFavourite ? "favouriteOn" : "favourite"}
              onClick={handleBookmark}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListviewAbout;
