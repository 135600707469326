import React, { useState, useCallback } from 'react';
import IconBtn from '../../IconBtn';
import crossIcon from '../../../assets/icons/cross-white.svg';
import debounce from 'lodash/debounce';

const IslandSearchFilterDesktop = ({handleSearch, handleFilter = () => {}, handleClose = null}) => {

    const [isSearchActive, setIsSearchActive] = useState(false);
    const [search, setSearch] = useState('');
    const isMobile = window.innerWidth <= 767;

    const debouncedSearch = useCallback(debounce(handleSearch, 500), [handleSearch]);

    const handleChange = (e) => {
        setSearch(e.target.value);
        debouncedSearch(e.target.value)
    }

    const clearSearch=(e)=>{
      if(search !=='') {
        handleSearch('')
      }
      setSearch('')
      setIsSearchActive(false)
      e.stopPropagation();
    }

    const renderInput = () => {
        return (
            <>
            <IconBtn type="searchBright" large />
            {isSearchActive && <input autoFocus placeholder='Search term' onChange={handleChange} value={search} />}
            <button onClick={clearSearch} >
                <img src={crossIcon} alt='search' />
            </button>
            </>
        )
    }

    const onFilterClick = (e) => {
        handleFilter();
        e.stopPropagation();
    }

    return (
        <div className={`island-nav__search-filter ${isSearchActive ? 'active' : ''}`} onClick={() => !isMobile ? setIsSearchActive(true) : {}}>
            <IconBtn type="searchBright" large onClick={() => isMobile ? setIsSearchActive(true) : {}}/>
            {handleClose && <IconBtn type="brightCloseSecondary" large bright onClick={handleClose} />}
            <IconBtn type="filterBright" large onClick={onFilterClick} />
            <div className='search-filter__input search-filter__input--desktop'>
                {renderInput()}
            </div>
            {isSearchActive && <div className='search-filter__input search-filter__input--mobile'>
                {renderInput()}
            </div>}
        </div>
    );
}

export default IslandSearchFilterDesktop;
