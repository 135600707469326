// @ts-nocheck


import { RenderView } from '@superbright/voritc-curate/build'

import {isMobileDevice, isAndroidDevice} from '../../utils';

interface ICollaborationsCurate {
  target_id: string;
  galleryFiles: Object;
  loadingProgress: number;
  toggleViewMode: () => void;
  userId: any;
  onVideoStateChange: () => void;
  closeDetails: () => void;
  onGuidedModeArtworkChange: () => void;
  onHoverArtworkChanged: () => void;
  onSeenArtworkChange?: () => void;
}

export default class CollaborationsCurate extends React.Component<ICollaborationsCurate> {
    constructor(target_id, galleryFiles = {}, loadingProgress, toggleViewMode, userId, onVideoStateChange, closeDetails, onGuidedModeArtworkChange, onHoverArtworkChanged, onSeenArtworkChanged = () => {}, onSelectExit, onLobbyGallerySelected, onDoneCurateLoading) {
    const config = {
      doubleClickForArtworkDetails: isMobileDevice(),
      useUnsignedByteForHDR: isAndroidDevice(),
      isDemoMode: false,
      animation_props: galleryFiles.animation_props,
      isLockedInDetailsMode: galleryFiles.isLockedInDetailsMode === 1,
    }
    this.renderView = new RenderView(target_id, galleryFiles, loadingProgress, document.getElementById('hiddenVideoElement'), config)
    this.renderView.onSelectArtwork = this.onSelectArtwork.bind(this)
    this.renderView.onToggleViewMode = this.onToggleViewMode.bind(this)
    this.renderView.onHoverArtworkChanged = onHoverArtworkChanged
    this.renderView.onSelectExit = onSelectExit
    this.renderView.onLobbyGallerySelected = onLobbyGallerySelected
    this.renderView.onSeenArtworkChanged = onSeenArtworkChanged
    this.renderView.onGuidedModeArtworkChange = onGuidedModeArtworkChange
    this.renderView.onVideoStateChange = onVideoStateChange
    this.renderView.onDoneLoading = onDoneCurateLoading
    this.toggleViewMode = toggleViewMode
    this.userId = userId
    this.renderView.closeDetails = closeDetails
    this.renderView.setSeenArtworkDetectionEnabled(isMobileDevice())

  }

  onToggleViewMode(artworkId) {
    //? Is this for putting 2D ui visible for detail mode
    this.toggleViewMode(artworkId)
  }

  onSelectArtwork(obj) {
    // this.renderView.focusOnArtwork(obj)
  }


  stop() {
    cancelAnimationFrame(this.frameId)
  }

  updateLobbyGalleries(data) {
    // console.log('updateLobbyGalleries =>', data)
    this.renderView && this.renderView.updateLobbyGalleries(data)
  }

  focusonLobbyGallery(gallery) {
    // console.log('focusonLobbyGallery =>', gallery)
    this.renderView && this.renderView.focusonLobbyGallery(gallery)
  }

  resize() {
    this.renderView && this.renderView.resize();
  }

  loadPlacementConfig(data) {
    this.renderView.loadPlacementConfig(data)
  }

  // onSelectExit() {
  //   this.renderView && this.renderView.onSelectExit();
  // }
}
