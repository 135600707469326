import React, { useState, useEffect } from "react";
import { Label } from "../Typography2";
import "./style.scss";
import IconBtn from "../IconBtn/IconBtn";

const SidePanelHeader = ({
  viewDesc,
  viewMap,
  viewExhibitionMap,
  viewExhibitionDesc,
  onClose,
  isArt
}) => {
  const [isBulletActive, setIsBulletActive] = useState(false);
  const [bullet, setBullet] = useState({
    position: 17,
    width: 40,
  });

  const handleExhibitionDesc = (e) => {
    viewExhibitionDesc();
    updateBullet(e);
  };

  const handleExhibitionMap = (e) => {
    viewExhibitionMap();
    updateBullet(e);
  };

  const handleReset = () => {
    setIsBulletActive(false);
    let item;
    if (viewMap) {
      item = document.querySelector(".view-map-btn");
    } else {
      item = document.querySelector(".view-desc-btn");
    }
    if (item) {
      setBullet({ position: item?.offsetLeft, width: item?.clientWidth });
    }
  };

  const updateBullet = (e) => {
    setBullet({ position: e.target.offsetLeft, width: e.target.offsetWidth });
  };

  useEffect(() => {
    handleReset();
  }, [viewDesc, viewMap]);

  useEffect(() => {
    handleReset();
  }, []);

  return (
    <div className="side-panel-header">
      <div
        className={"side-panel-header-tabs"}
        onMouseLeave={handleReset}
        onMouseEnter={() => setIsBulletActive(true)}
      >
        <button
          className={`button view-desc-btn ${viewDesc ? "dark" : "bright"}`}
          onClick={handleExhibitionDesc}
          onMouseEnter={updateBullet}
        >
          <Label className="header-btn-opacity" value="About" />
        </button>
        <button
          className={`button view-map-btn ${viewMap ? "dark" : "bright"}`}
          onClick={handleExhibitionMap}
          onMouseEnter={updateBullet}
        >
          <Label className="header-btn-opacity" value={isArt ? "Artworks" : "Objects"} />
        </button>

        {(viewDesc || viewMap || isBulletActive) && (
          <div
            className="slider-bullet"
            style={{ left: `${bullet.position}px`, width: `${bullet.width}px` }}
          />
        )}
      </div>
      <IconBtn onClick={onClose} type="brightClose" />
    </div>
  );
};

export default SidePanelHeader;
