import React from "react";
import crossDarkIcon from '../../assets/icons/cross-medium.svg';
import './styles.scss'

const FilterPill = ({isSelected, handlePillClick, label, id}) => {
  return (
    <button key={id} className={`filter-pill ${isSelected ? 'active' : ''}`} onClick={() => handlePillClick(id, true)}>
      {label}
      <img src={crossDarkIcon} alt="cross" onClick={(e) => {
        handlePillClick(id, false);
        e.stopPropagation();
      }}/>
    </button>
  );
}

export default FilterPill;
