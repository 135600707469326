import axios from "axios";
import {BACKEND_URL} from "../configs"
import {LOCAL_STORAGE_KEYS} from "../components/Constants"
import {debug} from "webpack";
let oldToken = '';
const instance = axios.create({
    baseURL: BACKEND_URL,
    responseType: 'json'
});
let refreshed = false;

instance.interceptors.request.use(
    config => {
        const token = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
        if (token) {
            const parsedToken = JSON.parse(token);
            config.headers['Authorization'] = 'Bearer ' + parsedToken.auth;
        }
        return config;
    },
    error => {
        Promise.reject(error);
    }
);

instance.interceptors.response.use(
    response => {
        return response;
    },
    async error => {
        if ((error.response.status === 400 || error.response.status === 401)) {
            if(!oldToken) {
                oldToken = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
            }
            return refreshToken(oldToken).then(async (refreshedToken) => {
                const retError = {...error};
                const token = refreshedToken && JSON.stringify(refreshedToken) || localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
                if (token) {
                    const parsedToken = JSON.parse(token);
                    const auth = parsedToken.auth;
                    const headers = {Authorization: `Bearer ${auth}`};
                    retError.config.headers = headers;
                    return axios.request(retError.config);
                } else {
                    if(window.location.pathname !== "/" && !window.location.pathname.includes("login")) {
                        // debugger;
                       // window.location.href = "/discover";
                    }
                }
            }).catch(e => {
                const retError = {...error};
                if(window.location.pathname !== "/" && !window.location.pathname.includes("login")) {
                    const headers = {Authorization: ''};
                    retError.config.headers = headers;
                    // return axios.request(retError.config);
                    // debugger;
                    // window.location.href = "/discover";
                    // window.location.reload();
                }
                // if(retError.config.method === "get") {
                //     console.log('REFRESH = ', retError.config)
                //     retError.config.headers = {};
                //     return axios.request(retError.config);
                // }
            });
        }
        return Promise.reject(error.response.data);
    }
);

async function refreshToken(token) {
    refreshed = true;
    if (token) {
        const parsedToken = JSON.parse(token);
        const refresh = parsedToken.refresh;
        const headers = {Authorization: `Bearer ${refresh}`};
        localStorage.removeItem(LOCAL_STORAGE_KEYS.TOKEN);
        return axios
            .get(BACKEND_URL + 'refresh', {headers})
            .catch((err: any) => {
                localStorage.clear();
                // window.location.href = "/login";
                if (err.response.status === 400) {
                    return Promise.reject(
                        new Error("Request failed with status code 400")
                    );
                }
                return Promise.reject(new Error(JSON.stringify(err.response.data)));
            })
            // @ts-ignore
            .then(async ({data: {token, aws}}, err) => {
                if (token.auth !== undefined && token.refresh !== undefined) {
                    oldToken = JSON.stringify(token);
                    await localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, JSON.stringify(token));
                    await localStorage.setItem(LOCAL_STORAGE_KEYS.AWS, JSON.stringify(aws));
                }
                return Promise.resolve(token);
            });
    }
}


export default instance;
