import React, { useEffect, useState, useImperativeHandle } from 'react';
import "./style.scss";
import { ISlideOutProps } from "./types";

const SlideOut:React.FC<ISlideOutProps> = React.forwardRef((props, ref) => {
  const { children, onClose, childClassName } = props;
  const [visibility, setVisibility] = useState("");
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    setTimeout(() => {
        setVisibility("show")
    }, 0)
    setTimeout(() => {
        setInProgress(true);
    }, 800)
  }, [])

  const handleClose = (val) => {
    setVisibility(val)
    setTimeout(() => {
      onClose();
    }, 800)
  }

  useImperativeHandle(
    ref,
    () => ({
        close: () => {
          handleClose("hide");
        }
    }),
  );

  return (
    <div className={`slide-out ${visibility} ${inProgress ? 'active' : ''}`}>
        <div className="slide-out__overlay" onClick={() => handleClose("hide")} />
        <div className={`slide-out__child ${childClassName || ''}`}>
            {children}
        </div>
    </div>
  )
})

export default SlideOut;