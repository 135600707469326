import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import SvgHeader from "../../components/SvgHeader";
import ScrollAnimation from '../../components/ScrollAnimation/ScrollAnimation';
import { constructImageUrl, isMobileDevice } from '../../utils';
import Exhibition from '../../components/Exhibition';
import { fetchCollectiveExhibitions, favouriteExhibition } from '../../services/api';
import { Title2, } from '../../components/Typography2';
import UntitledArtImg from '../../assets/images/untitled-art.png';
import FriezeArtImg from '../../assets/images/frieze-art-img.png';
// import FriezeRoom from '../../components/FriezeRoom';
import CollaborationCard from '../../components/CollaborationCard';
import { VORTIC_CURATED, FRIEZE, UNTITLED, UNTITLED_DESCRIPTION, FRIEZE_DESCRIPTION, VORTIC_CURATED_GROUP_ID } from "./const";
import SubHeader from '../../components/SubHeader';
import CollaborationsNewPage from '../CollaborationsNewPage';

const Collectives = (props) => {
    const { type: collaborationType } = useParams()
    const { collective: { collectives, currentPage, canLoadMore }, globalProps } = props
    const [isLoadingMore, setIsLoadingMore] = useState(false)
    const [curatedExhibitions, setCuratedExhibitions] = useState([]);
    // const [friezeExhibitions, setFriezeExhibitions] = useState([]);
    const [curatedExhibitionsLoading, setCuratedExhibitionsLoading] = useState(true);
    // const [friezeExhibitionsLoading, setFriezeExhibitionsLoading] = useState(true);
    // @ts-ignore
    // const collaborationType = route?.params?.type;
    const [activeType, setActiveType] = useState(collaborationType.toLowerCase());
    const navigate = useNavigate();

    useEffect(() => {
        setActiveType(collaborationType.toLowerCase());
    }, [collaborationType])

    const fetchExhibitions = async () => {
        const curatedResp = await fetchCollectiveExhibitions(VORTIC_CURATED_GROUP_ID);
        // const friezeResp = await fetchCollectiveExhibitions(VORTIC_FRIEZE_GROUP_ID);
        if (curatedResp) {
            const array = curatedResp.data;
            // @ts-ignore
            array.sort((a, b) => new Date(b.data.createdAt) - new Date(a.data.createdAt));
            setCuratedExhibitions(array);
            setCuratedExhibitionsLoading(false);
        }
        // if (friezeResp) {
        //     setFriezeExhibitions(friezeResp.data);
        //     setFriezeExhibitionsLoading(false);
        // }
    }

    useEffect(() => {
        fetchExhibitions();
    }, []);

    useEffect(() => {
        setIsLoadingMore(false)
    }, [collectives.length])

    const loadData = async () => {
        if (canLoadMore && !isLoadingMore) {
            setIsLoadingMore(true)
            const nextPage = currentPage + 1
            await props.fetchCollectives(nextPage)
        }
    }

    const handleCollectiveClick = (id) => {
        navigate(`/collectives/${id}`)
    }

    const handleExhibitionClick = (id) => {
        navigate(`/exhibitions/${id}`)
    };

    const toggleExhibitionFavourite = async (exhibitionId) => {
        if (props.globalProps.isLoggedIn) {
            let resp = await favouriteExhibition(exhibitionId)
            if (resp.data) {
                if (activeType === VORTIC_CURATED) {
                    let newExhibitions = [];
                    curatedExhibitions.map(exhibition => {
                        if (exhibition.data.id === exhibitionId) {
                            exhibition = {
                                ...exhibition,
                                data: {
                                    ...exhibition.data,
                                    isFavourite: resp.data.isFavourite
                                }
                            }
                        }
                        newExhibitions.push(exhibition)
                        return exhibition;
                    })
                    setCuratedExhibitions(newExhibitions)
                }
                // else if(activeType === FRIEZE) {
                //     let newExhibitions = [];
                //     friezeExhibitions.map(exhibition => {
                //         if (exhibition.data.id === exhibitionId) {
                //             exhibition = {
                //                 ...exhibition,
                //                 data: {
                //                     ...exhibition.data,
                //                     isFavourite: resp.data.isFavourite
                //                 }
                //             }
                //         }
                //         newExhibitions.push(exhibition)
                //         return exhibition;
                //     })
                //     setFriezeExhibitions(newExhibitions);
                // }
            }
        } else {
            navigate(`/login?/collaborations/${activeType}`)
        }
    }

    useEffect(() => {
        if (window.innerWidth <= 680) {
            handleScroll(activeType);
        }
    }, [activeType])

    const handleScroll = (val) => {
        // document.querySelector(`button.${val}`)?.scrollIntoView({
        //     behavior: 'smooth',
        //     block: 'center',
        //     inline: 'center'
        // })
    }

    const handleTabClick = (val) => {
        // setActiveType(val);
        // navigate(`/collaborations/${val}`)
        // if (isMobileDevice() || window.innerWidth <= 680) {
        //     handleScroll(val);
        // }
    }

    return (
        <div>
            {/* <SubHeader title='Collaborations' bottomGutter className='sub-header--untitled-art'>
                <div className='collaborations-tabs'>
                    <div className='collaborations-tabs__btns'>
                        <button className={`${activeType === VORTIC_CURATED ? 'active' : ''} ${VORTIC_CURATED}`} onClick={() => handleTabClick(VORTIC_CURATED)}>
                            Vortic Curated
                        </button>
                        <button className={`${activeType === FRIEZE ? 'active' : ''} ${FRIEZE}`} onClick={() => handleTabClick(FRIEZE)}>
                            Frieze Viewing Rooms
                        </button>
                        <button className={`${activeType === UNTITLED ? 'active' : ''} ${UNTITLED}`} onClick={() => handleTabClick(UNTITLED)}>
                            Untitled Art
                        </button>
                    </div>
                    <div className='collaborations-tabs__divider' />
                </div>
            </SubHeader> */}
            <div className='bg-white'>
                <div className='collaborations-untitled-art-wrapper'><CollaborationsNewPage  /></div>
                {/* <div>
                    {activeType === VORTIC_CURATED ? <ScrollAnimation className='container pt-0'>
                        {
                            curatedExhibitionsLoading ? <Spinner type='cover' /> :
                                <div className='cards-container'>
                                    {
                                        curatedExhibitions?.length > 0 ?
                                            curatedExhibitions.map((collective, index) => {
                                                const { id, image, name, startedAt, endedAt, organisation, isFavourite, curator, slug, uuid, isDateVisible } = collective.data;
                                                return (
                                                    <div key={index}>
                                                        <Exhibition
                                                            id={id}
                                                            slug={slug}
                                                            uuid={uuid}
                                                            onClick={handleExhibitionClick}
                                                            exhibitionImg={image && image.data && [constructImageUrl(image, "1080")]}
                                                            isFavourite={isFavourite}
                                                            title={name}
                                                            subTitle='Luca Bertolo, Clive Hodgson , Merlin James , Walter Swennen , Winifred Nicholson , Phoebe Unwin '
                                                            galleryName={organisation?.data?.type === "vortic" ? (curator?.data?.title && `Vortic Curated x ${curator?.data?.title}`) : organisation?.data?.name}
                                                            isLoggedIn={globalProps.isLoggedIn}
                                                            isVorticCurate={organisation?.data?.type === "vortic"}
                                                            startedAt={startedAt}
                                                            endedAt={endedAt}
                                                            locations={organisation && organisation.data.locations}
                                                            toggleFavourite={toggleExhibitionFavourite}
                                                            isDateVisible={isDateVisible}
                                                        />
                                                    </div>
                                                )
                                            }) :
                                            <Title2 value='No Exhibitions Found.' />
                                    }
                                    <br />
                                    <br />
                                </div>
                        }
                    </ScrollAnimation> :
                        <>
                            {
                                activeType === UNTITLED ?
                                    <div className='collaborations-untitled-art-wrapper'><CollaborationsNewPage  /></div> :
                                    <ScrollAnimation className='container pt-0'>
                                        <CollaborationCard imgUrl={FriezeArtImg} description={FRIEZE_DESCRIPTION} />
                                    </ScrollAnimation>
                            }
                        </>
                    }
                </div> */}
            </div>
        </div>
    )
}

export default Collectives
