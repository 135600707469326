import React, { useEffect, useRef, useState } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
// import DummyImage from "../../assets/images/doron-langberg-give-me-love.png";
import "./style.scss";
import Loader from '../Spinner';
import { isMobileDevice } from "../../utils";

const ImageZoomer = ({ src, setScaling, scale }) => {
  const [currentScale, setCurrentScale] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    // <div className={`wrapper ${isZoomed ? 'wrapper--zoomed' : ''}`}>
    <div className="wrapper">
      <TransformWrapper
        initialScale={1}
        minScale={1}
        maxScale={7}
        limitToBounds={true}
        centerOnInit={true}
        centerZoomedOut={false}
        minPositionX={0}
        minPositionY={0}
        onZoom={(e) => {
          if(e.state.scale >= 1) {
            setCurrentScale(e.state.scale);
            setScaling(e.state.scale);
          }
          else {
            setCurrentScale(1);
            setScaling(1);
          }
        }}
        panning={{
          disabled: currentScale <= 1,
        }}
        // onZoomStart={onZoomed}
      >
        {({ zoomIn, zoomOut, resetTransform, centerView, ...rest }) => {

          const handleOnLoad = () => {
            centerView();
            setIsLoaded(true);
          }

          return (
            <React.Fragment>
              {/* <div className="tools">
              <button onClick={() => zoomIn()}>+</button>
              <button onClick={() => zoomOut()}>-</button>
              <button onClick={() => resetTransform()}>x</button>
            </div> */}
              {/* <div style={containerStyle}> */}

              {isMobileDevice() && !isLoaded && 
                <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} className="image-zoom-loader-v">
                  <Loader />
                </div>
              }
              
              <TransformComponent>
                <div>
                  <img src={src} alt="test" onLoad={() => handleOnLoad()} />
                </div>
              </TransformComponent>
              {/* </div> */}
            </React.Fragment>
          );
        }}
      </TransformWrapper>
    </div>
  );
};

export default ImageZoomer;
