import React from 'react'
import './style.scss';

const Switch = ({ onChange, value }) => {

    const handleChange = (e) => {
        onChange(e.target.checked);
    };

  return (
    <label className={`switch-wrapper ${value ? 'active' : '' }`}>
        <div className='switch'>
          <p>{value ? 'in' : 'cm'}</p>
        </div>
        <input type='checkbox' onChange={(e) => handleChange(e)} />
        <span className='ball' />
    </label>
  )
}

export default Switch;