import React, { useState, useEffect } from "react";
import Login from "../../containers/Login";
import Signup from "../../containers/SignUp";
import ForgotPassword from "../../containers/ForgotPassword";
import { IAutentication } from "./types";
import { useSelector, useDispatch } from "react-redux";
import { setIsMobileForm } from "../../containers/Global/redux/actions";
import "./style.scss";

const AuthenticationForm: React.FC<IAutentication> = ({
  type = "login",
  isLoggedIn,
  onCloseForm,
  toggleLogin = false,
  setToggleLogin,
  fromExhLoading = false,
  artworkDetailsEnquire = false,
  className,
  closeIconType,
  handleCancel,
  isCurateAuthModal
}) => {
  const [formType, setFormType] = useState<string>(type);

  // @ts-ignore
  const isMobileForm = useSelector((state) => state?.global.isMobileForm);
  const dispatch = useDispatch();

  useEffect(() => {
    if(isCurateAuthModal && isLoggedIn)  {
      handleCancel();
    }
  }, [isLoggedIn, isCurateAuthModal])


  useEffect(() => {
    if (!artworkDetailsEnquire) {
      if (!isMobileForm) {
        dispatch(setIsMobileForm(true));
      }
      return () => {
        if (isMobileForm) {
          dispatch(setIsMobileForm(false));
        }
      };
    }
  }, []);

  /** method to open login form */
  const onLoginLinkClick = () => {
    setFormType("login");
  };

  /** method to open signup form */
  const onSignupLinkClick = () => {
    setFormType("signup");
  };

  /** method to open forget password form */
  const onForgetLinkClick = () => {
    setFormType("forget");
  };

  return (
    <div className={`auth-modal-form ${className || ""}`}>
      {formType === "login" && (
        <Login
          // @ts-ignore
          signupLinkClick={onSignupLinkClick}
          closeEventLogin={() =>
            isLoggedIn ? onSignupLinkClick() : onCloseForm()
          }
          forgetLinkClick={onForgetLinkClick}
          fromAuthenticated={true}
          modal={true}
          toggleLogin={toggleLogin}
          setToggleLogin={setToggleLogin}
          closeIconType={closeIconType}
          handleCancel={handleCancel}
          title="Please Log In"
        />
      )}

      {formType === "signup" && (
        <Signup
          loginLinkClick={onLoginLinkClick}
          closeEventSignUp={onLoginLinkClick}
          isEventsSignUp={true}
          modal={true}
          fromExhLoading={fromExhLoading}
          closeIconType={closeIconType} isLoggedIn={undefined} isEnquireForm={undefined} onSignupComplete={undefined} handleLoginBtnEvent={undefined} handleBackBtnEvent={undefined} title={undefined} isCommentsForm={undefined}        />
      )}

      {/* @ts-ignore */}
      {formType === "forget" && (
        <ForgotPassword
          // @ts-ignore
          goToLogin={onLoginLinkClick}
          isForgotPassword={true}
          modal={true}
          isLoggedIn={isLoggedIn}
          fromExhLoading={fromExhLoading}
          closeIconType={closeIconType} isCommentsForm={undefined} handleCloseBtnEvent={undefined} isEnquireForm={undefined} isForgotPage={undefined}        />
      )}
    </div>
  );
};

export default AuthenticationForm;