import React from 'react';

const RenderTextBlocks = ({ textDetails }) => {

    const getUnstyled = (inlineStyles) => {
        let classes = '';
        inlineStyles.length > 0 && inlineStyles.map((item) => {
            const className = item.style && item.style.toLowerCase();
            classes = classes + ' ' + className;
        });
        return classes;
    };

    const getClassName = (className) => {
        switch (className) {
            case 'header-onex2':
                return 'h1x2';
            case 'header-one':
                return 'h1';
            case 'header-two':
                return 'h2';
            case 'header-three':
                return 'h3';
            case 'unstyled':
                return 'p1';
            default:
                return className;
        }
    };

    return (
        <>
            {
                textDetails && textDetails.blocks && textDetails.blocks.length > 0 &&
                textDetails.blocks.map((item, index) => {
                    return (
                        <p key={index} className={`draft-text ${getClassName(item.type)} ${getUnstyled(item.inlineStyleRanges)}`}>{(item && item.text) || ''}</p>
                    )
                })
            }
        </>
    )
}

export default RenderTextBlocks;