import React from 'react';
import IconBtn from '../IconBtn';
import {  Title, Title2 } from '../Typography2';
import {INotifyProps} from "./types";

const Notification: React.FC<INotifyProps> = ({value,subTitle,onClose,className}) => {
    return (
        <div className={'notify-box'}>
            <div>
                <Title value={value}/>
                <Title2 value={subTitle}/>
            </div>
            <IconBtn onClick={()=>onClose()} type={'crossSecondary'}/>
        </div>
    );
};

export default Notification;
