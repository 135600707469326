import React from 'react';
import Button from '../Button';
import { Anchor } from '../Typography2';

const CookiePopup = ({description, btnLabel, onButtonClick}) => {
    return (
        <div className='cookie-popup'>
            <p className="paragraph">{description || ''} <Anchor inline bold target="_blank" to='https://vorticxr.com/privacy-notice' value='Privacy Notice' />.</p>
            <div className="cookie-btn-wrapper">
                <Button
                    value={btnLabel}
                    className='button__dark'
                    iconType="check"
                    onClick={onButtonClick}
                />
            </div>
        </div>
    )
}

export default CookiePopup;
