import React, {useEffect} from 'react';

const RenderHtml = ({data}) => {

    useEffect(() => {
        setTimeout(() => {
            document.querySelector('#html-content').innerHTML = data || "";
        }, 0)
    }, [data])
    
    return (
        <div className="main-text-wrapper draft-text-v2 html-text-wrapper" id="html-content"></div>
    )
}

export default RenderHtml;