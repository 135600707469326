import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import Spinner from '../Spinner';
import { useOnLoadImages } from "../ListviewArtworkGrid/hooks/useOnLoadImages";
import "./style.scss";

const ImageZoom = ({ src, zoomSrc, zoomType, className }) => {
    const ImageRef = useRef();
    const ImgWrapperRef = useRef<any>();
    const [isActive, setActive] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const imagesLoaded = useOnLoadImages(ImgWrapperRef);

    const handleImage = () => {
        if(ImgWrapperRef?.current) {
            ImgWrapperRef.current.querySelector('.iiz').addEventListener('mousedown', () => {
                ImgWrapperRef.current?.classList.add('active');
            })
        }
    }

    useLayoutEffect(() => {
        handleImage();
    }, []);

    useEffect(() => {
        if(imagesLoaded) {
            setIsLoaded(true);
        } else {
            setIsLoaded(false);
        }
    }, [imagesLoaded])

    return (
        <>
            <div 
                ref={ImgWrapperRef}
                key={src}
                className={
                    `zoom-image-wrapper ${(isActive) ? 'active' : ''} ${isLoaded ? 'show' : 'hide'}`
                }>
                    <InnerImageZoom moveType="drag" ref={ImageRef} src={src} className={className} zoomSrc={zoomSrc} zoomType={zoomType} zoomPreload={true} hasSpacer={true} />
                    {!isLoaded && <Spinner />}
            </div>
        </>
    )
};

export default ImageZoom;
