import React, {useState, useRef, useEffect} from 'react';
import {HeadTitle, Heading} from '../Typography2';
import {EnterPin} from '../../services/api';
import Button from '../Button';
import {useSelector} from 'react-redux';
import PairVrForm from './Form';
import CreateAvatar from './CreateAvatar';

const initialState = {
    firstNum: '',
    secondNum: '',
    thirdNum: '',
    forthNum: '',
}

const PairVr = (props) => {
    const [isInvalid, setInvalid] = useState(false);
    const [code, setCode] = useState(initialState);
    const [success, setSuccess] = useState(false);
    const inputsNames = Object.keys(initialState);
    const inputRefs = useRef(new Array());
    const buttonRef = useRef<HTMLButtonElement>(null);
    const finalIndex = inputsNames.length - 1;

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        if (value && value.trim() !== "") {
            const index = inputsNames.indexOf(name);
            setCode({
                ...code,
                [name]: value
            })
            if (index < finalIndex) {
                inputRefs.current[index + 1].focus();
            } else {
                buttonRef.current.focus();
            }
        }
    }

    const handleSubmit = async () => {
            const pin = Object.values(code).join('');
            const resp = await EnterPin({pin})
            if (resp) {
                setSuccess(true);
            } else {
                setInvalid(!isInvalid)
            }
    }

    const handleFocus = (event) => event.target.select();

    return (
        <section className="pairvr-wrapper">
            {props.isLoggedIn ? <div className="pairvr">
                {!success ? (
                        // <>
                        //     {(profileData.firstname || profileData.lastname) &&
                        //     <HeadTitle value={"Hello, " + profileData.firstname + " " + profileData.lastname}/>}
                        //     <div>
                        //         {isInvalid && <Heading className="invalid" value="Oops, looks like that didn’t work!"/>}
                        //         {!isLoggedIn && <Heading className="invalid" value="Please enter the pin from your headset. You'll then need to log in to finish the pairing process."/>}

                        //     </div>

                        //     <form className="pairvr__form" onSubmit={e => e.preventDefault()}>
                        //         <div className={"pairvr__form-inputs" + (isInvalid ? ' form-invalid' : '')}>
                        //             <input type="text" name="firstNum" ref={elm => inputRefs.current[0] = elm}
                        //                    value={code.firstNum} onFocus={handleFocus} onChange={handleInputChange}
                        //                    autoFocus/>
                        //             <input type="text" name="secondNum" ref={elm => inputRefs.current[1] = elm}
                        //                    value={code.secondNum} onFocus={handleFocus} onChange={handleInputChange}/>
                        //             <input type="text" name="thirdNum" ref={elm => inputRefs.current[2] = elm}
                        //                    value={code.thirdNum} onFocus={handleFocus} onChange={handleInputChange}/>
                        //             <input type="text" name="forthNum" ref={elm => inputRefs.current[3] = elm}
                        //                    value={code.forthNum} onFocus={handleFocus} onChange={handleInputChange}/>
                        //         </div>
                        //         <Button buttonRef={buttonRef} value={isLoggedIn ? "Submit" : "Log in"} iconType="next" onClick={handleSubmit} type="submit"/>
                        //     </form>
                        // </>
                        <>
                            <CreateAvatar />
                        </>
                    )
                    :
                    <>
                        <HeadTitle value="You’re good to go"/>
                        <Heading value="Please put on your headset to continue"/>
                    </>
                }
            </div> : 
            <>
                <PairVrForm />
            </>
            }
        </section>
    )
}

export default PairVr
