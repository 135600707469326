import React from "react";
import { Paragraph } from "../Typography2";
import { isHTML } from "../../utils";

interface IRenderFilteredHtml {
  response: string;
  style: object;
  onScroll: (e: any) => void;
  onMouseDown: (e: any) => void;
  onTouchStart: (e: any) => void;
  onMouseUp: () => void;
  onTouchEnd: () => void;
}

const RenderFilteredHtml: React.FC<IRenderFilteredHtml> = ({
  response,
  style,
  onScroll,
  onMouseDown,
  onTouchStart,
  onMouseUp,
  onTouchEnd,
}) => {

  const filterEmptyElements = (htmlString: string): string => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const nonEmptyElements: Element[] = [];

    const hasMeaningfulContent = (element: Element): boolean => {
      if (element.childNodes.length === 0) {
        return false;
      }

      // Check if the element has non-empty text content.
      // @ts-ignore
      for (const childNode of element.childNodes) {
        if (
          childNode.nodeType === Node.TEXT_NODE &&
          childNode.textContent?.trim() !== ""
        ) {
          return true;
        }
      }

      // Check if the element has any child elements with meaningful content.
      // @ts-ignore
      for (const childNode of element.children) {
        if (hasMeaningfulContent(childNode as Element)) {
          return true;
        }
      }

      return false;
    };

    doc.body.childNodes.forEach((node) => {
      if (
        node.nodeType === Node.ELEMENT_NODE &&
        hasMeaningfulContent(node as Element)
      ) {
        nonEmptyElements.push(node as Element);
      }
    });

    return nonEmptyElements.map((node) => node.outerHTML).join("");
  };

  const filteredContent = filterEmptyElements(response);

  const renderContent = () => {
    if (isHTML(response)) {
      return (
        <>
          <Paragraph className="bold">About the work</Paragraph>
          <p
            className="paragraph"
            dangerouslySetInnerHTML={{ __html: filteredContent }}
          />
        </>
      );
    } else {
      return (
        <>
          <Paragraph className="bold">About the work</Paragraph>
          <Paragraph value={response} />;
        </>
      );
    }
  };

  if (!!filteredContent) {
    return (
      <div
        className="details-wrap  description-scroll-wrapper"
        style={style}
        onScroll={onScroll}
        onMouseDown={onMouseDown}
        onTouchStart={onTouchStart}
        onMouseUp={onMouseUp}
        onTouchEnd={onTouchEnd}
      >
        {renderContent()}
      </div>
    );
  } else {
    return null;
  }
};

export default RenderFilteredHtml;
