import axios from 'axios';

export const uploadToS3 = (url, file, fileType = "png") => {
    const config = {
        method: 'put',
        url,
        headers: {
            'Content-Type': `image/${fileType}`
        },
        data : file
    };
    return new Promise((resolve, reject) => {
        // @ts-ignore
        axios(config)
            .then(function (response) {
                resolve(true)
            })
            .catch(function (error) {
                reject(error)
            });
    })

}
