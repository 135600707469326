import {ofType} from 'redux-observable';
import {catchError, flatMap, map} from 'rxjs/operators';
import {FETCH_COLLECTIVES_INIT, FETCH_COLLECTIVES_META_INIT} from './actionTypes'
import * as API from "../../../services/api"
import {fetchCollectiveSuccess, fetchCollectiveMetaSuccess} from './actions';
import {IResponseType} from "../../Galleries/types";
import logger from "../../../utils/logger";

const fetchCollective = action$ =>
    action$.pipe(
        ofType(FETCH_COLLECTIVES_INIT),
        flatMap(({payload}) => API.fetchCollectives(payload)),
        map((resp: IResponseType) => {
            if (resp.data) {
                logger.debug('resp = ', resp)
                return fetchCollectiveSuccess(resp);
            }
        }),
        //@ts-ignore
        catchError(error => {
            logger.error('error = ', error);
            return () => {
            }
        })
    );

const fetchCollectiveMeta = action$ =>
    action$.pipe(
        ofType(FETCH_COLLECTIVES_META_INIT),
        flatMap(() => API.fetchCollectivesMeta()),
        map((resp: IResponseType) => {
            if (!!resp) {
                logger.debug('resp = ', resp)
                return fetchCollectiveMetaSuccess(resp);
            }
        }),
        //@ts-ignore
        catchError(error => {
            logger.error('error = ', error);
            return () => {
            }
        })
    );


export default [
    fetchCollective,
    fetchCollectiveMeta
]
