import React, {  Fragment, useRef, useEffect, useState } from 'react'
import logger from '../../utils/logger';
import { Paragraph } from '../Typography2';
import {formatePrice, isMobileDevice, secondsToHms} from '../../utils'
import Pause from '../../assets/icons/pause.svg';
import Play from '../../assets/icons/play.svg';
import Audio from '../Audio';
import { IAudioWrapperProps } from './types';
import Close from '../../assets/icons/close-bright.svg'
import Button from '../Button';
import { Heading } from '../Typography2';
import IconBtn from '../IconBtn';

const AudioWrapper: React.FC<IAudioWrapperProps> = ({ artworkId ,isMuseumType, currency,  isArtwork2dModal,  setArtworkModal , artworkPhotos ,price , title,yearCreated,  isEnquired , handleEnquire ,  isArtworkListedForSale,isGuiding , onClose , exhibitiondId, setCurateAudio, playing, audioUrl, currentTime, totalTime, isAnime, setPlaying, setCurrentTime, setTotalTime, setIsAnime, secondary, isActive, setActive, getCurateHeight, excludedTax, priceUnavailable }) => {
    const [isAnimationEnded, setIsAnimationEnded] = useState(false);
    const childRef = useRef(null);
    const progressBar = useRef(null);
    const resetTimeOutRef = useRef<any>(null);
    const animationResetTimeOutRef = useRef<any>(null);

    useEffect(() => {
        if(isAnimationEnded) {
            animationResetTimeOutRef.current = setTimeout(() => {
                setIsAnimationEnded(false);
            }, 500)
        }
        return () => {
            if(animationResetTimeOutRef.current){
                clearTimeout(animationResetTimeOutRef.current);
            }
        }
    }, [isAnimationEnded]);

    useEffect(() => {
        if(currentTime === 0) {
            setIsAnimationEnded(true);
        }
    }, [currentTime])

    const togglePlaying = (val) => {
        childRef?.current?.togglePlay(val);
    }

    useEffect(() => {
        if (currentTime === Math.round(totalTime)) {
            resetTimeOutRef.current = setTimeout(() => {
                togglePlaying(false);
                setIsAnime(false);
                setCurrentTime(0);
            }, 1000)
        }
        return () => {
            if (resetTimeOutRef.current) {
                clearTimeout(resetTimeOutRef.current);
            }
        }
    }, [currentTime, artworkId, totalTime]);

    const handlePlay = (val) => {
        if (audioUrl) {
            if(progressBar.current){
                progressBar.current.classList.add('anime');
            }
            togglePlaying(val);
            setIsAnime(true);
            if(getCurateHeight){
                getCurateHeight();
            }
        }
        setTimeout(() => {
            if(progressBar.current){
                progressBar.current.classList.add('anime');
            }
            togglePlaying(val);
            setIsAnime(true);
            if(getCurateHeight){
                getCurateHeight();
            }

        }, 0)

    };
    const handlePause = () => {
        if (audioUrl) {
            if(progressBar.current){
                progressBar.current.classList.add('anime');
            }
            togglePlaying(false);
            setIsAnime(true);
        }
    };

    const resetEverything = () => {
        togglePlaying(false);
        setCurrentTime(0)
        setTotalTime(0)
        setIsAnime(false)
    }


    useEffect(() => {
        setActive(false);

        return () => {
            resetEverything();
            setActive(false);
        }

    }, []);


    useEffect(() => {
        handleClose();
        resetEverything();
    }, [artworkId])

    const handleStart = (e) => {
        setActive(true);
        if(!!audioUrl){
            handlePlay(true);
        }
        e.stopPropagation()
    }
    const handleClose = () => {
        handlePause();
        setActive(false);
        if(getCurateHeight){
            setTimeout(() => {
                getCurateHeight();
            }, 0)
        }
    };

    const handlePlayPauseBtn = () => {
        childRef.current.togglePlay(!playing);
    }

    const EnquireBtn = () => {
        return (
             <div className='artwork-expenses-main-wrapper'>
                {
                    !isMuseumType &&
                    <div className='artwork-expenses'>
                        {isArtworkListedForSale && price && Number(price) !== 0 && !priceUnavailable &&  <Button onClick={()=>{}} type="secondary" className="price-btn" spanClassName={(!isMobileDevice() && window.innerWidth> 990) && 'f-medium f-size-12px l-height-15-6px'}>
                            Price:&nbsp;<span>{formatePrice(price, currency)}</span> {!!excludedTax && <Paragraph className='dark-gray' value="(ex tax)" />}
                            </Button>
                        }
                        <Button className="enquire-btn" spanClassName={(!isMobileDevice() && window.innerWidth> 990) && 'f-medium f-size-12px l-height-15-6px'} onClick={handleEnquire} type="secondary" value={isEnquired ? 'Inquiry Sent' : 'Enquire'}></Button>
                    </div>
                }
                <IconBtn onClick={onClose} type="brightClose" />
            </div>)

    }

    return (

        <>
        {
            (isGuiding && (isMobileDevice() || window.innerWidth < 990)) ?
            <>
                <AudioComponent
                    isMuseumType={isMuseumType}
                    setCurrentTime={setCurrentTime}
                    yearCreated = {yearCreated}
                    onClose = {onClose}
                    artworkPhotos = {artworkPhotos}
                    setArtworkModal = {setArtworkModal}
                    isArtwork2dModal = {isArtwork2dModal}
                    isGuiding = {isGuiding}
                    isActive = {isActive}
                    playing = {playing}
                    handlePlay = {handlePlay}
                    audioUrl = {audioUrl}
                    secondary = {secondary}
                    handleClose = {handleClose}
                    isAnime = {isAnime}
                    currentTime = {currentTime}
                    totalTime = {totalTime}
                    progressBar = {progressBar}
                    title = {title}
                    handleStart = {handleStart}
                    EnquireBtn={EnquireBtn}
                    childRef={childRef}
                    setTotalTime={setTotalTime}
                    handlePlayPauseBtn={handlePlayPauseBtn}
                    setPlaying={setPlaying}
                    isAnimationEnded={isAnimationEnded}
                />
            </>
            :
            <>
                {
                    isArtwork2dModal ?
                    (!!audioUrl &&
                        <AudioComponent
                        setCurrentTime={setCurrentTime}
                        yearCreated = {yearCreated}
                        onClose = {onClose}
                        isMuseumType={isMuseumType}
                        artworkPhotos = {artworkPhotos}
                        setArtworkModal = {setArtworkModal}
                        isArtwork2dModal = {isArtwork2dModal}
                        isGuiding = {isGuiding}
                        isActive = {isActive}
                        playing = {playing}
                        handlePlay = {handlePlay}
                        audioUrl = {audioUrl}
                        secondary = {secondary}
                        handleClose = {handleClose}
                        isAnime = {isAnime}
                        currentTime = {currentTime}
                        totalTime = {totalTime}
                        progressBar = {progressBar}
                        title = {title}
                        handleStart = {handleStart}
                        EnquireBtn={EnquireBtn}
                        childRef={childRef}
                        setTotalTime={setTotalTime}
                        handlePlayPauseBtn={handlePlayPauseBtn}
                        setPlaying={setPlaying}
                        isAnimationEnded={isAnimationEnded}
                    />
                    )
                    :
                    <AudioComponent
                        setCurrentTime={setCurrentTime}
                        isMuseumType={isMuseumType}
                        yearCreated = {yearCreated}
                        onClose = {onClose}
                        artworkPhotos = {artworkPhotos}
                        setArtworkModal = {setArtworkModal}
                        isArtwork2dModal = {isArtwork2dModal}
                        isGuiding = {isGuiding}
                        isActive = {isActive}
                        playing = {playing}
                        handlePlay = {handlePlay}
                        audioUrl = {audioUrl}
                        secondary = {secondary}
                        handleClose = {handleClose}
                        isAnime = {isAnime}
                        currentTime = {currentTime}
                        totalTime = {totalTime}
                        progressBar = {progressBar}
                        title = {title}
                        handleStart = {handleStart}
                        EnquireBtn={EnquireBtn}
                        childRef={childRef}
                        setTotalTime={setTotalTime}
                        handlePlayPauseBtn={handlePlayPauseBtn}
                        setPlaying={setPlaying}
                        isAnimationEnded={isAnimationEnded}
                    />
                }
            </>
        }
        </>
    )
}

const AudioComponent = ({ isMuseumType, setCurrentTime, setTotalTime, yearCreated, onClose, artworkPhotos, setArtworkModal, isArtwork2dModal, isGuiding, isActive, playing, handlePlay, audioUrl, secondary, handleClose, isAnime, currentTime, totalTime, progressBar, title, handleStart, EnquireBtn, childRef, handlePlayPauseBtn, setPlaying, isAnimationEnded }) => {

    const percentage = (partialValue, totalValue) => {
        const percent = (100 * partialValue) / totalValue;
        if(percent === 99) {
            return 100;
        } else {
            return percent;
        }
    }

    return (
        <div className={`${isArtwork2dModal ? ' one-col-grid ' : ''}  ${!isMobileDevice()? ` audio-player-sec ` : ''} ${isGuiding ? ' margin-0 ': ''} ${!(!!audioUrl)  ?  'two-col-grid' : isActive ?  ` two-col-grid ` : ` three-col-grid`}`} >
                {!!audioUrl &&
                <div className={"audio-player-wrapper "  + (secondary ? " secondary" : "") + (isActive ? " active change-width" : "")} onClick={(e) => !isActive && handleStart(e)}>
                    <div className="progress-play-pause">
                        <div className={`progress-play-btn ${audioUrl ? '' : 'progress-no-audio'}  ${playing ? "active" : ""}`}
                            onClick={() => !!audioUrl ? handlePlayPauseBtn() : logger.debug('loading...')}>
                            {
                                <Fragment>
                                    {
                                        playing ?
                                            <img src={Pause} alt="Pause" /> :
                                            <img src={Play} alt="Play" />
                                    }
                                </Fragment>
                            }
                        </div>
                        <Paragraph className={`bright label ${isActive ? 'hide-elem': 'show-elem' } `} value="Play Audio" />
                        {
                            !!audioUrl &&
                            <div className={`paragraph bright time  ${isActive ? 'show-elem': 'hide-elem' }  ${playing ? "active" : ""}`}>
                                <div style={{ fontSize: '10px' }}>{currentTime === 0 ? <span>0:00</span> : <span>{secondsToHms(currentTime)}</span>} <span>/</span> {totalTime === 0 ? <span>0:00</span> : <span>{secondsToHms(totalTime)}</span>}</div>
                            </div>
                        }
                    </div>
                    <div className={`progress-bar ${isAnimationEnded ? 'progress-bar-no-transition' : ''} ${playing   ? 'active' : ''} ${isAnime ? 'anime' : ''}`} ref={progressBar}>
                        <span style={{ width:`${percentage(currentTime, totalTime)}%` }} />

                    </div>


                        <button className={`close-icon ${isActive ? 'show-close-icon' : ''} `} onClick={() => {handleClose()}}>
                            <img  src={Close} alt="close" />
                        </button>


                    {/* @ts-ignore */}
                    <Audio setPlaying={setPlaying} setActive={(val) => setActive(val)} ref={childRef} setTotalTime={(val) => setTotalTime(val)} setCurrentTime={(val) => setCurrentTime(val)}
                        url={audioUrl} play={playing} />
                </div>
                }

                {((!isMobileDevice() || window.innerWidth > 990) && !isGuiding) && !isActive &&
                <div>
                    <Heading>
                        {title}
                        <span>{yearCreated}</span>
                    </Heading>
                </div>
                }
                {
                window.innerWidth >= 990 &&  <div className={`right-sec-audio-wraper ${(artworkPhotos?.length > 0 && !isMuseumType) ? 'right-sec-audio-wraper--photos' : ''} ${isGuiding ? 'hide' : ''} ${isMobileDevice() ? 'hide' : ''}`} >
                    <div>
                        {artworkPhotos?.length > 0 && !isActive && <ul className='artworks-list'>
                                {
                                    artworkPhotos.map((photo, index) => (
                                        <li onClick={() => setArtworkModal(true, artworkPhotos, index)} key={index}><img src={photo.small} key={index} alt="artwork" /></li>
                                        ))
                                    }
                            </ul>}

                    </div>
                    <div>
                        {EnquireBtn()}
                        </div>

                    {/* <Button className={`artwork-toggle-btn button__bright close-button ${isArtwork2dModal  ? 'hide-elem' : ''}`}
                        spanClassName={(!isMobileDevice() && window.innerWidth> 990) && 'f-medium f-size-12px l-height-15-6px'}
                        type="secondary" onClick={onClose}
                                    value="Close artwork view"/> */}
                    </div>
                }
            </div>
    )
}

export default AudioWrapper;
