import ArtworkDetails from './ArtworkDetails';
import {connect} from 'react-redux'
import {setConversation, setRedirectPath, setArtworkId, setArtworkIds} from "../../containers/Global/redux/actions";
import {setCurrentConversation, setEnquireMessage} from "../../containers/Conversation/redux/actions";

const mapStatesToProps = ({global, profile}) => {
    return {
        isLoggedIn: global.isLoggedIn,
        profile: profile.profileData,
        artworkIds: global.artworkIds,
        exhibitionId: global.exhibitionId
    };
};

const mapDispatchToProps = dispatch => ({
    setRedirectPath: (path) => dispatch(setRedirectPath(path)),
    setChat: (val) => dispatch(setConversation(val)),
    setCurrentChannel: (conversation) => {
        dispatch(setCurrentConversation(conversation))
    },
    setArtworkId: (id) => {
        dispatch(setArtworkId(id))
    },
    setArtworkIds: (val) => {
        dispatch(setArtworkIds(val))
    },
    setEnquireMessage: (message) => {
        dispatch(setEnquireMessage(message))
    }
});


export default connect(mapStatesToProps, mapDispatchToProps)(ArtworkDetails)
