import React, { useEffect, useState, useRef } from "react";
import ReactAvatarEditor from "react-avatar-editor";

const AvatarCropper = React.forwardRef((props, ref) => {
  // @ts-ignore
  const { img, setCroppedImg, croppedImg } = props;

  const [state, setState] = useState({
    image: img || "",
    allowZoomOut: false,
    position: { x: 0.5, y: 0.5 },
    rotate: 0,
    borderRadius: 0,
    preview: null,
    width: 310,
    height: 207,
  });

  useEffect(() => {
    setState({ ...state, image: img });
  }, [img]);

  const [scale, setScale] = useState(1);
  const avatarEditorWrapperRef = useRef(null);

  const handlePositionChange = (position) => {
    setState({ ...state, position });
  };

  const handleScaling = (event) => {
    event.preventDefault();

    if (event.wheelDelta) {
      if (event.wheelDelta > 0) {
        setScale((scale) =>
          scale >= 1 && scale < 2.525 ? scale + 0.05 : scale
        );
      } else {
        setScale((scale) =>
          scale >= 1.05 && scale <= 2.55 ? scale - 0.05 : scale
        );
      }
    }
  };

  useEffect(() => {
    avatarEditorWrapperRef.current.addEventListener("wheel", handleScaling, {
      passive: false,
    });
    return () => {
      avatarEditorWrapperRef.current.removeEventListener("wheel", handleScaling, {
        passive: false,
      });
    };
  }, []);

  const setImageUrl = (editor) => {
    if(editor) {
      setCroppedImg(editor.getImageScaledToCanvas().toDataURL())
    }
  }

  return (
    <div>
      {img && (
        <div className="avatar-editor-wrapper">
          <div ref={avatarEditorWrapperRef}>
            <ReactAvatarEditor
              scale={scale}
              width={state.width}
              height={state.height}
              position={state.position}
              onPositionChange={handlePositionChange}
              rotate={state.rotate}
              borderRadius={state.width / (100 / state.borderRadius)}
              image={state.image}
              className="editor-canvas"
              disableBoundaryChecks={false}
              border={0}
              ref={ref => setImageUrl(ref)}
            />
          </div>
          {/* <div className="avatar-editor-wrapper__preview"><img alt="portrail" src={croppedImg}  /></div> */}
        </div>
      )}
    </div>
  );
});

export default AvatarCropper;
