import React from 'react';
import "./style.scss";

const FadeDown = ({ children, delay = '0s', className = '', variant = '', duration= '300ms' }) => {
  return (
    <div className={`fade-down-wrapper ${className} ${variant === 'fade-in' ? 'fade-down-wrapper--fade-in' : ''}`} style={{ animationDelay: delay, animationDuration: duration }}>
        {children}
    </div>
  )
}

export default FadeDown;