import logger from '../utils/logger';
import rudderStack from "./rudder-stack";

const EVENTS = {
  PUBLIC_ACCOUNT_CREATE: 'public_account_create',
  PUBLIC_ACCOUNT_LOGIN: 'public_account_login',
  PUBLIC_ACCOUNT_LOGOUT: 'public_account_logout',
  PUBLIC_ACCOUNT_UPDATE: 'public_account_update',
  PUBLIC_ACCOUNT_NOTIFICATION: 'public_account_notification',
  PUBLIC_MAILING_LIST_SIGN_UP: 'public_mailing_list_sign_up',


  EXHIBITION_ENTER: 'public_exhibition_enter',
  EXHIBITION_BOOKMARK: 'public_exhibition_bookmark',
  EXHIBITION_SHARE: 'public_exhibition_share',
  EXHIBITION_ACTION: 'public_exhibition_action',

  ARTWORK_CLICK: 'public_artwork_click',
  ARTWORK_SHARE: 'public_artwork_share',
  ARTWORK_ENQUIRY: 'public_artwork_enquiry',
  ARTWORK_BOOKMARK: 'public_artwork_bookmark',

  ARTWORK_DETAIL: 'public_artwork_detail',
  START_TOUR: 'public_exhibition_start_tour',
  REFUSE_TOUR: 'public_exhibition_refuse_tour',
  EXHIBITION_IMPRESSION: 'public_exhibition_impression',

  USE_MAIN_NAV: 'use_main_nav',
  USE_FOOTER_NAV: 'use_footer_nav',
  ENTER_UNTITLED: 'enter_untitled',
}

class RudderStackEvents {
  constructor() {
    this.exhibition = '';
    this.exhibitionName = '';
    this.exhibitionUuid = '';
    this.gallery = '';
    this.galleryName = '';
    this.pagePath = '';
    this.userId = '';
  }

  setPagePath = (pagePath) => {
    this.pagePath = pagePath
  }

  setUserId = (userId) => {
    this.userId = userId
    logger.debug('setUserId = ', this.userId)
  }

  mount = ({exhibitionId, exhibitionName, galleryId, galleryName, exhibitionUuid}) => {
    this.exhibition = exhibitionId;
    this.exhibitionUuid = exhibitionUuid;
    this.exhibitionName = exhibitionName;
    this.gallery = galleryId;
    this.galleryName = galleryName;
    logger.debug('mount ', exhibitionId, exhibitionUuid, galleryId, galleryName, exhibitionName);
  }

  track = (event, data) => {
    if (rudderStack) {
      rudderStack.track(event, data)
    }
  }

  onAccountCreate = () => {
    this.track(EVENTS.PUBLIC_ACCOUNT_CREATE, {page_path: this.pagePath})
  }

  onUntitledPageLoad = () => {
    this.track(EVENTS.ENTER_UNTITLED, {page_path: this.pagePath})
  }

  onLogin = (user_id) => {
    this.track(EVENTS.PUBLIC_ACCOUNT_LOGIN, {page_path: this.pagePath, user_id})
  }

  onLogout = (user_id) => {
    this.track(EVENTS.PUBLIC_ACCOUNT_LOGOUT, {page_path: this.pagePath, user_id})
  }

  onProfileUpdate = (user_id) => {
    this.track(EVENTS.PUBLIC_ACCOUNT_LOGOUT, {page_path: this.pagePath, user_id})
  }


  onExhibitionEnter = () => {
    this.track(EVENTS.EXHIBITION_ENTER, {
      exhibition_id: this.exhibition,
      exhibition_uuid: this.exhibitionUuid,
      gallery_id: this.gallery,
      exhibition_name: this.exhibitionName,
      gallery_name: this.galleryName,
      page_url: this.pagePath,
    })
  }

  onExhibitionBookmark = ({type, exhibitionId = null, galleryId = null, exhibitionName = null, galleryName = null, exhibitionUuid = null}) => {
    this.track(EVENTS.EXHIBITION_BOOKMARK, {
      exhibition_id: exhibitionId || this.exhibition,
      exhibition_uuid: exhibitionUuid || this.exhibitionUuid,
      gallery_id: galleryId || this.gallery,
      exhibition_name: exhibitionName || this.exhibitionName,
      gallery_name: galleryName || this.galleryName,
      page_url: this.pagePath,
      type
    })
  }

  onExhibitionShare = ({type, exhibitionId = null, galleryId = null, exhibitionName = null, galleryName = null, exhibitionUuid = null}) => {
    this.track(EVENTS.EXHIBITION_SHARE, {
      exhibition_id: exhibitionId || this.exhibition,
      exhibition_uuid: exhibitionUuid || this.exhibitionUuid,
      gallery_id: galleryId || this.gallery,
      exhibition_name: exhibitionName || this.exhibitionName,
      gallery_name: galleryName || this.galleryName,
      type
    })
  }

  onExhibitionAction = () => {

  }

  onArtworkClick = (artworkId, artworkUuid) => {
    this.track(EVENTS.ARTWORK_CLICK, {
      artwork_id: artworkId,
      artwork_uuid: artworkUuid,
      exhibition_id: this.exhibition,
      exhibition_uuid: this.exhibitionUuid,
      gallery_id: this.gallery,
      exhibition_name: this.exhibitionName,
      gallery_name: this.galleryName,
    })
  }

  onArtworkShare = ({artworkId, type, artworkUuid}) => {
    this.track(EVENTS.ARTWORK_SHARE, {
      artwork_id: artworkId,
      artwork_uuid: artworkUuid,
      exhibition_id: this.exhibition,
      exhibition_uuid: this.exhibitionUuid,
      gallery_id: this.gallery,
      type,
    })
  }

  onArtworkEnquiry = (artworkId, artworkUuid) => {
    this.track(EVENTS.ARTWORK_ENQUIRY, {
      artwork_id: artworkId,
      artwork_uuid: artworkUuid,
      exhibition_id: this.exhibition,
      exhibition_uuid: this.exhibitionUuid,
      gallery_id: this.gallery,
      exhibition_name: this.exhibitionName,
      gallery_name: this.galleryName,
    })
  }

  onArtworkDetail = (artworkId, action, artworkUuid) => {
    this.track(EVENTS.ARTWORK_DETAIL, {
      artwork_id: artworkId,
      artwork_uuid: artworkUuid,
      action: action,
      exhibition_id: this.exhibition,
      exhibition_uuid: this.exhibitionUuid,
      user_id: this.userId,
      gallery_id: this.gallery,
      exhibition_name: this.exhibitionName,
      gallery_name: this.galleryName,
    })
  }

  onArtworkBookmark = (artworkId, artworkUuid) => {
    this.track(EVENTS.ARTWORK_BOOKMARK, {
      artwork_id: artworkId,
      artwork_uuid: artworkUuid,
      exhibition_uuid: this.exhibitionUuid,
      exhibition_id: this.exhibition,
      gallery_id: this.gallery,
      exhibition_name: this.exhibitionName,
      gallery_name: this.galleryName,
    })
  }

  onUseManNav = ({menuItem, searchItem=null}) => {
    this.track(EVENTS.USE_MAIN_NAV, {
      page_path: this.pagePath,
      menu_item: menuItem,
      search_term: searchItem
    })
  }
  onUseFooterNav = ({menuItem}) => {
    this.track(EVENTS.USE_FOOTER_NAV, {
      page_path: this.pagePath,
      menu_item: menuItem
    })
  }

  onJoiningMailingList = (formName) => {
    this.track(EVENTS.START_TOUR, {
      form_name: formName,
      user_id: this.userId,
      page_path: this.pagePath,
    })
  }
  onStartTour = () => {
    this.track(EVENTS.START_TOUR, {
      exhibition_id: this.exhibition,
      exhibition_uuid: this.exhibitionUuid,
      exhibition_name: this.exhibitionName,
    })
  }

  onRefuseTour = () => {
    this.track(EVENTS.REFUSE_TOUR, {
      exhibition_id: this.exhibition,
      exhibition_uuid: this.exhibitionUuid,
    })
  }
}


const rudderStackEvents = new RudderStackEvents();
export default rudderStackEvents;
