const states = {
    scene: null,
    demoScene:null,
    leaveEventsDetails: false,
    goBackFromEventDetails: null,
    loadingProgress: 0,
    viewDesc: false,
    exitScreen: false,
    viewMap: false,
    viewEvent: false,
    isGuidedModeOnMobile: false,
    selectedEvent: null,
    artworksList: [],
    detailedArtworks: [],
    onSeenArtworkId: null,
    detailsPopup: false,
    audioDetailsPopup: false,
    selectedArtwork: null,
    exhibitionDetails: null,
    isFavourite: false,
    isSidebar: false,
    showSplashScreen: false,
    isGuideAlreadyLoaded:false,
    selectedGuideModeArtworkId:null,
    roomChangingState:{
        isChanging:false,
        value:null,
    },
    splashScreenByInfo: false,
    initialY: 0,
    isAudioPopupFull: false,
    event: null,
    eventsResp: null,
    intervalId: null,
    liveStreamInstance: null,
    displayScreenSharingBox: false,
    videoLayout: null,
    streamingStatus: 'notLoaded',
    isConversation: false,
    isModerator: true,
    eventFromProps: false,
    loadingPageDetails: {},
    isArtworkDescription: false,
    artworkDescHeight: 0,
    curateHeight: 0,
    isLoader: true,
    isMicMuted: false,
    isConnectionError: false,
    isCurateAudio: false,
    isLeaveEventPopup: false,
    isStreaming:true,
    isStreamMuted: false,
    exitPopupText: 'Exit the Exhibition',
    selectedRoom: null,
    isRoomLoading: false,
    isOpening: false,
    recentVideoArtwork: {
        id: null,
        state: false
    },
    moveToTop:false,
    eventChannel: null,
    defaultArtworkId: null,
    showRoomsBox: false,
    audioPlaying: false,
    audioUrl: '',
    audioCurrentTime: 0,
    audioTotalTime: 0,
    isAnime: false,
    isActive: false,
    isArtwork2dModal: false,
    artworkPhotos: [],
    listview: false,
    isArtworkModal: false,
    artworkModalSelectedIndex: null,
    guidedArtworkDetails: {
        next: false,
        fromSwipe: false,
        id: null
    },
    curateStyle: {
        height: `${window.innerHeight}px`
    },
    viewportWidth: window.innerWidth,
    callBackOnRoomLoad: null,
    isLoadingArtworks: true,
    restartTour: 0,
    moveToNextRoom: 0,
    shouldShowGuidedMode: false,
    isDropdownSwitch: false,
    activeTooltipTitle: 0,
    showTootltip: false,
    listViewLoading:true,
    is3dViewOpen: false,
    isExhibitionExit: false,
    toggleLogin: false,
    loadingScreen: true,
    activeHeader: false,
    isTootip: false,
    artworkLabel: false,
    sidePanel: false,
    isDetailedMode: false,
    softArtworkId: null,
    isMobileArtwork: false,
    instructed: false,
    isLoadingScreen: true,
    isEnquireAside: false,
    selectedTooltip: null,
    selectedTooltipLabel: "",
    selectedTooltipIndex: 0,
    isLockedInDetailsMode: false,
    tooltipInstructed: false,
    tooltipStyleTop: null,
    isMobileArtworkExpanded: false,
    firstArtworkVisited: false,
    isRoomAnimated: false,
    isDesktopArtworkVisited: false,
    userInteraction: true,
    currentTime: 0,
    sidePanelAuthForm: false,
    isPanelForAuth: false,
    toolTipBottom: null,
    currentMobileArtworkState: "down",
    isCurateAuthModal: false,
    isArtworkVideoInstructions: false,
    selectedSoftArtwork: null,
    isCurateVideoInstructions: false,
    firstTimeArtworkVideoInstruction: true,
    confirmationPopupVariant: '',
    isNotDefaultRoom: false,
    isSkipButton: true,
    isMenuVisible: true,
    introVideoUrl: '',
    introVideoPoster: '',
    isArtworkDefaultStateActive: false,
    artworkMinimizedActive: false,
    isIntroVideoEnded: true,
    curateLoadingState: [],
    isRoomSwitchModal: false,
    roomSwitchModalVariant: 'room-switch',
    shouldShowLoader: true,
    isLockedRoom: false,
    artworkAdditionalPhotos: [],
    deviceOrientation: 'portrait',
    isIntoAudioPlaying: false,
    isIntoAudioFound: false,
    curateV2Response: {},
    showDescription: true
};

export default states;
