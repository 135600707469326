import {
    LOGIN,
    CHANGE_SEARCH,
    SET_REDIRECT_PATH,
    SET_CONVERSATION,
    SET_VIEW_HEIGHT,
    SET_FIRST_LOAD,
    SET_ARTWORK_ID,
    SET_IS_ERROR,
    SET_ARTWORKS_IDS,
    SET_EXHIBITION_ID,
    SET_IS_MOBILE_FORM
} from './actionTypes';

export function login() {
    return {
        type: LOGIN,
        payload: true
    }
}

export function changeSearch(value) {
    return {
        type: CHANGE_SEARCH,
        payload: value
    }
}

export function setRedirectPath(value) {
    return {
        type: SET_REDIRECT_PATH,
        payload: value
    }
}

export function setConversation(value) {
    return {
        type: SET_CONVERSATION,
        payload: value
    }
}

export function setViewHeight(value) {
    return {
        type: SET_VIEW_HEIGHT,
        payload: value
    }
}

export function setFirstLoad(value) {
    return {
        type: SET_FIRST_LOAD,
        payload: value
    }
}

export function setArtworkId(value) {
    return {
        type: SET_ARTWORK_ID,
        payload: value
    }
}

export function setExhibitionId(value) {
    return {
        type: SET_EXHIBITION_ID,
        payload: value
    }
}

export function setArtworkIds(value) {
    return {
        type: SET_ARTWORKS_IDS,
        payload: value
    }
}

export function setIsError(value, message) {
    return {
        type: SET_IS_ERROR,
        payload: {value, message}
    }
}

export function setIsMobileForm(value) {
    return {
        type: SET_IS_MOBILE_FORM,
        payload: value
    }
}