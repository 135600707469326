import React, {useContext, useEffect, useState} from "react";
import {UntitledHeader} from "../UntitledHeader/UntitledHeader";
import {Paragraph} from "../Typography2";
import {IProps} from "./types";
// import IslandNav from "../Nav";
import LoadingToGallery from "../Nav/LoadingToGallery/LoadingToGallery";
import './styles.scss'
import IconBtn from "../IconBtn";
import MenuListIcon from '../../assets/icons/list.svg'
import CollaborationsBox from '../../assets/images/collaborations-box.png'
import {CollaborationContext} from "../../containers/CollaborationsNewPage/CollaborationsContext";
import { isMobileDevice } from "../../utils";

// Mobile / Tablet Resolution
const MOBILE_VIEW = 768;

const UntitledWelcome = ({open, setOpen, classes = '', progress}: IProps) => {
  const [visibility, setVisibility] = useState<boolean>(true)
  const [showContainer, setShowContainer] = useState<boolean>(true)
  const [display, setDisplay] = useState<boolean>(false)
  const [loaded, setLoaded] = useState<boolean>(false)
  const [sGalleryLoaded, setIsGalleryLoaded] = useState(false);
  const [isInstructions, setIsInstructions] = useState(false);
  const {collaborationsData, onSelectItem,initialLoad, setInitialLoad,}=useContext(CollaborationContext)

  const screenWidth = window.screen.width

  const hideOverly = () => {
    if (screenWidth > MOBILE_VIEW) {
      setVisibility(false)
      setTimeout(() => {
        setDisplay(false)
        setVisibility(true)
      }, 700)
    } else {
      setShowContainer(false)
      setTimeout(() => {
        setVisibility(false)
        setTimeout(() => {
          setDisplay(false)
          setVisibility(true)
          setShowContainer(true)
        }, 800)
      }, 700)
    }
  }

  const handleClick = () => {
    if(!isInstructions){
      setIsInstructions(true);
    } else {
      setOpen(false)
      if(initialLoad){
        setTimeout(() =>{
          if(collaborationsData[1]){
            let item=collaborationsData[1][0]
            onSelectItem(1, item)
          }
        },5000)
        setInitialLoad(false)
      }
    }
  }

  useEffect(() => {
    if (loaded) {
      if (open) {
        setDisplay(true)
      } else {
        hideOverly()
      }
    }
  }, [open])

  useEffect(() => {
    setLoaded(true)
  }, [])

  if (!display) {
    return null
  }

  let overlayClasses = visibility ? 'show-untitled-overlay' : 'hide-untitled-overlay'

  if (screenWidth <= MOBILE_VIEW) {
    overlayClasses = visibility ? '' : 'untitled-overlay-fade-out'
  }

  const containerClasses = showContainer ? '' : 'hide-welcome-container'

  return (
    <div className='welcome-modal'>
    <div className={`untitled-overlay slideUp ${overlayClasses} ${classes}`}>
      <div className='untitled-welcome-container'>
        <div className="untitled-welcome-container__content">
          <UntitledHeader variant='dark' logoOnly={true} className={containerClasses}/>
          {
            !isInstructions ?
            <div className="untitled-body-wrapper">
              <div>
                <Paragraph
                  className={`body ${containerClasses} first-type-body`}
                  value="As Untitled Art, Miami Beach 2023 Digital Partner, Vortic has harnessed its cutting-edge technology to empower participating galleries to curate their own sustainable virtual booth presentations, bringing their art to anyone, wherever they may be."
                />
                <Paragraph
                  className={`body ${containerClasses}`}
                  value='Enter the Vortic x Untitled Art lobby to delve into over 50 immersive digital exhibitions: effortlessly navigate the virtual space from your computer or mobile device to discover new galleries and find out more about artists and artworks you love. '
                />
              </div>
            </div> : 
            <div className={`untitled-instructions ${containerClasses}`}>
              <div className="untitled-instructions__box">
                <Paragraph value="Use arrows to navigate between galleries" />
                <div className="untitled-instructions__box__flexbox">
                  <IconBtn type="backArrowBright" large onClick={()=>{}} />
                  <IconBtn type="nextArrowBright" large onClick={()=>{}}  />
                </div>
              </div>
              <div className="untitled-instructions__box">
                <Paragraph value="Tap the list icon to view and search galleries" />
                <div className="untitled-instructions__box__flexbox">
                <img className="untitled-instructions-menu" src={MenuListIcon} alt="menu" />
                </div>
              </div>
              <div className="untitled-instructions__box">
                <Paragraph value={
                  (isMobileDevice() || window.innerWidth <= 767) ? 
                  "Double Tap to visit any of the booths" : 
                  "Tap and hold to visit any of the booths"} 
                />
                <div className="untitled-instructions__box__flexbox">
                  <div className="collaborations-box">
                    <img src={CollaborationsBox} alt="box" />
                  </div>
                </div>
              </div>
            </div>
          }
        </div>

        <div>
          <LoadingToGallery progress={progress} variant={'loading-to-gallery'} onClick={() => {progress === 100 && handleClick()}} classes={`${containerClasses}`} isGalleryLoaded={sGalleryLoaded} setIsGalleryLoaded={setIsGalleryLoaded} isInstructionsOpen={isInstructions} isGalleryLoadingVariant={true} />
        </div>
      </div>
    </div>
    </div>
  )
}

export default UntitledWelcome;
