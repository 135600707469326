import React, {createContext, useCallback, useEffect, useState} from 'react';
import {useCurateTypes} from "./hooks/useCurate";
import {useCurateContext} from "./contexts/curateContext";
import {isMobileWidth} from '../../utils/isMobileWidth'

export const CollaborationContext = createContext(null);

const ContextProvider = ({children, isPanelOpen,setIsPanelOpen}) => {
  const [collaborationsData, setCollaborationsData] = useState(undefined);
  const [copyData, setCopyData] = useState({})
  const [selectedRegions, setSelectedRegions] = useState<string[]>([])
  const [searchText, setSearchText] = useState('')
  const [filterData, setFilterData] = useState<Region[]>([])
  const [currentGallery, setCurrentGallery] = useState<number>(0)
  const [totalNoOfGalleries, setTotalNoOfGalleries] = useState<number>(null)
  const [isFiltered, setFiltered] = useState<boolean>(false)
  const [controlsLoading, setControlsLoading] = useState(-1);
  const [mobileProgress, setMobileProgress] = useState(-1);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const {handleUpdateLobbyGalleries, handleFocusOnLobbyGallery, loadingProgress}: useCurateTypes = useCurateContext();

  const handleControlsLoading = () => {
    const isMobile = isMobileWidth()
    if (isMobile) {
      setMobileProgress(0);
      setTimeout(() => {
        setMobileProgress(100);
      }, 1500)
    } else {
      setControlsLoading(0);
      setTimeout(() => {
        setControlsLoading(100);
      }, 1500)
    }
  }

  const SearchAndSet = () => {
    let {filteredData, totalCount} = searchGalleries();
    filteredData = regroupGalleryData(filteredData)
    //load galleries group to curate
    if (totalCount) {
      let key = Object.keys(filteredData)[0]
      handleUpdateLobbyGalleries(filteredData[key])
      setCurrentGallery(1)
      setCopyData(updateSelectedGallery(filteredData, filteredData[1][0].groupId, filteredData[1][0].groupIndex))
    }else{
      //storing data in copyData
      setCopyData(filteredData)
      setCurrentGallery(0)
    }
  }

  //Auto Search on Search Text and Filter
  useEffect(() => {
    if (collaborationsData && (!initialLoad)) {
      SearchAndSet()
    }
    setFiltered(searchText !== '' || selectedRegions.length > 0)
  }, [searchText, selectedRegions, filterData]);


  //Loading and Setting Data for first time
  useEffect(() => {
    if (collaborationsData && collaborationsData[1] && loadingProgress === 100) {
      let key = Object.keys(collaborationsData)[0]
      if (collaborationsData[key]) {
        // collaborationsData[key][0].selected = true
        handleUpdateLobbyGalleries(collaborationsData[key])
      }
      setCopyData(regroupGalleryData(collaborationsData))
      const regions = generateRegions()
      setFilterData(regions)
    }
  }, [collaborationsData, loadingProgress])

  useEffect(() => {
    let temp = []
    Object.keys(copyData).forEach(key => {
      temp = [...temp, ...copyData[key]]
    })
    setTotalNoOfGalleries(temp.length)
    document.addEventListener('listing_on_gallery_selected', listenOnGallerySelected);
    return () => {
      document.removeEventListener('listing_on_gallery_selected', listenOnGallerySelected)
    }
  }, [copyData])

  useEffect(() => {
    if (currentGallery > 0) {
      const previousGallery = getGalleryByIndex(currentGallery - 1)
      const newCopies =  updateSelectedGallery(copyData, previousGallery.groupId, previousGallery.groupIndex);
      setCopyData( {...newCopies});
    }
  }, [currentGallery]);

  interface Continent {
    continent: string;
    selected: boolean;
    id: number;
  }

  interface Region {
    continent: string;
    selected: boolean;
    id: number;
  }

  function getSelectedContinents(continents: Continent[]): string[] {
    const selectedContinentNames = continents
      .filter((continent) => continent.selected)
      .map((continent) => continent.continent);

    return selectedContinentNames;
  }

  function updateSelectedContinents(allContinents: Continent[], selectedRegions: string[]): Continent[] {
    return allContinents.map((continent) => ({
      ...continent,
      selected: selectedRegions.includes(continent.continent),
    }));
  }

  function generateRegions(): Region[] {
    const uniqueContinents: Set<string> = new Set();

    // Collect unique continent values
    Object.keys(collaborationsData).forEach((key) => {
      collaborationsData[key].forEach((item) => {
        uniqueContinents.add(item.continent);
      });
    });

    // Convert unique continent values to Region objects
    const regions: Region[] = Array.from(uniqueContinents).map((continent, index) => ({
      continent,
      selected: false,
      id: index + 1, // Auto-incrementing id
    }));

    return regions;
  }

  const searchGalleries = () => {
    const filteredData = {};
    let totalCount = 0;

    Object.keys(collaborationsData).forEach((key) => {
      const filteredGroup = collaborationsData[key].filter(
        (item) =>
          (selectedRegions.length === 0 || selectedRegions.includes(item.continent)) &&
          (item.title.toLowerCase().includes(searchText.toLowerCase()) ||
            item.artistName.toLowerCase().includes(searchText.toLowerCase()))
      );

      if (filteredGroup.length > 0) {
        filteredData[key] = filteredGroup;
        totalCount += filteredGroup.length;
      }
    });

    return {filteredData, totalCount}
  };
  const regroupGalleryData = (data) => {
    const groupedData = {};
    let currentGroupIndex = 1;
    let currentIndex = 0;

    Object.keys(data).forEach((key) => {
      data[key].forEach((item) => {
        const groupId = currentGroupIndex.toString();

        if (!groupedData[groupId]) {
          groupedData[groupId] = [];
        }
        item.groupId = groupId;
        item.index = currentIndex++;
        item.groupIndex = groupedData[groupId].length
        groupedData[groupId].push(item);

        if (groupedData[groupId].length >= 9) {
          currentGroupIndex++;
        }
      });
    });
    return groupedData;
  }
  const updateSelectedGallery = (data, groupId, groupIndex) => {
    Object.keys(data).forEach((key) => {
      data[key].forEach((item, index) => {
        const isSelected = key === groupId && index === groupIndex;
        item.selected = isSelected;
      });
    });
    return data;
  };
  const handleSearch = async (value) => {
    setSearchText(value)
  }
  const getGalleryByIndex = (index: number) => {
    const groupIndex = Math.floor(index / 9);
    const galleryIndex = index % 9;
    return copyData[groupIndex + 1][galleryIndex];
  }
  const getGalleryById = (Id: number, data: any) => {
    let item = undefined;
    Object.keys(data).forEach(group => {
      data[group].forEach(gallery => {
        if (gallery.id === Id) {
          item = gallery
        }
      })
    })
    return item
  }
  const handleRegion = (regions: Region[]) => {
    const selectedRegions = getSelectedContinents(regions);
    setSelectedRegions(selectedRegions)
  }
  const handlePillClick = (id, state) => {
    let regions = selectedRegions;
    regions.splice(id, 1)
    const updatedFilterData = updateSelectedContinents(filterData, regions)
    setFilterData(updatedFilterData)
    setSelectedRegions(regions)
  }
  const onSelectItem = (key, item) => {
    //get previousGallery
    const previousGallery = getGalleryByIndex(currentGallery>0?currentGallery - 1:0)
    //setCurrentGallery
    setCurrentGallery(item.index + 1)
    //check changes in copy data and change copy Data and set it true for selected
    if (copyData[item.groupId][item.groupIndex].groupId !== previousGallery.groupId) {
      handleControlsLoading()
      handleUpdateLobbyGalleries(copyData[item.groupId])
    }
    handleFocusOnLobbyGallery(item)
  }
  const scrollToGalleryView = (id) => {
    document.getElementById(id).scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'})
  }
  const moveToNextGroup = () => {
    if (currentGallery !== totalNoOfGalleries) {
      if(!isPanelOpen){
        setIsPanelOpen(true)
      }
      const previousGallery = getGalleryByIndex(currentGallery - 1)
      const nextGallery = getGalleryByIndex(currentGallery)
      //setCurrentGallery
      setCurrentGallery(nextGallery.index + 1)
      //change copy Data and set it true for selected
      setCopyData(updateSelectedGallery(copyData, nextGallery.groupId, nextGallery.groupIndex))
      //if group change handleControlsLoading and handleUpdateLobbyGalleries
      if (copyData[nextGallery.groupId][nextGallery.groupIndex].groupId !== previousGallery.groupId) {
        handleControlsLoading()
        handleUpdateLobbyGalleries(copyData[nextGallery.groupId])
      }
      handleFocusOnLobbyGallery(nextGallery)
      scrollToGalleryView(`gallery-${nextGallery.groupId}-${nextGallery.id}`)
    }
  }
  const listenOnGallerySelected = (event) => {
    const {galleryId} = event.detail
    let item = getGalleryById(galleryId, copyData);
    if (item) {
      setCopyData(updateSelectedGallery(copyData, item.groupId, item.groupIndex))
      setCurrentGallery(item.index + 1)
    }
  }
  const moveToPreviousGroup = () => {
    if (currentGallery <= 1) return;
    if(!isPanelOpen){
      setIsPanelOpen(true)
    }
    const previousGallery = getGalleryByIndex(currentGallery - 1)
    const nextGallery = getGalleryByIndex(currentGallery - 2)
    //setCurrentGallery
    setCurrentGallery(nextGallery.index + 1)
    //change copy Data and set it true for selected
    setCopyData(updateSelectedGallery(copyData, nextGallery.groupId, nextGallery.groupIndex))
    //if group change handleControlsLoading and handleUpdateLobbyGalleries
    if (copyData[nextGallery.groupId][nextGallery.groupIndex].groupId !== previousGallery.groupId) {
      handleControlsLoading()
      handleUpdateLobbyGalleries(copyData[nextGallery.groupId])
    }
    handleFocusOnLobbyGallery(nextGallery)
    scrollToGalleryView(`gallery-${nextGallery.groupId}-${nextGallery.id}`)
  }

  const context = {
    collaborationsData: copyData,
    countSearchItem: totalNoOfGalleries,
    handleSearch,
    setCollaborationsData,
    handleRegion,
    filterData,
    onSelectItem,
    handlePillClick,
    selectedRegions,
    searchText,
    moveToNextGroup,
    moveToPreviousGroup,
    currentGroup: currentGallery,
    totalNoOfGalleries,
    handleControlsLoading,
    mobileProgress,
    setMobileProgress,
    controlsLoading,
    setControlsLoading,
    isFiltered,
    setInitialLoad,
    initialLoad
  }

  return (
    <CollaborationContext.Provider value={{...context}}>
      {children}
    </CollaborationContext.Provider>
  );
};

export default ContextProvider;
