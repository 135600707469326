import React, {useContext, useEffect, useState} from 'react';
import IslandNav from '../IslandNav';
import IslandControlsNav from '../IslandControlsNav/IslandControlsNav';
import IslandLoadingNav from '../IslandLoadingNav/IslandLoadingNav';
import  {
    CollaborationContext
} from "../../../containers/CollaborationsNewPage/CollaborationsContext";


interface IProps {
    variant: string;
    handleMenu?:()=>void;
    handleClose?:()=>void;
    isActiveProp: boolean;
    parentCall?: string;
}
const ControlsToSearchAndFilter = ({ variant, handleMenu=()=>{}, parentCall, progress, setProgress, handleClose = () => {}, handlePrev = () => {}, handleNext = () => {}}) => {
    const [isActive, setIsActive] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const {collaborationsData, handleSearch, handleRegion, selectedRegions}=useContext(CollaborationContext)
    const data = collaborationsData?collaborationsData:[]

    useEffect(() => {
        setIsDisabled(true);
        const timeOut = setTimeout(() => {
            setIsDisabled(false);
            clearTimeout(timeOut);
        }, 1000)
        return () => {
            clearTimeout(timeOut);
        }
    }, [isActive])

    const onMenuClick = () => {
        if(!isDisabled){
            setIsActive(true);
            handleMenu();
        }
    }

    const onCloseClick = () => {
        if(!isDisabled){
            setIsActive(false);
            handleClose();
        }
    }

    useEffect(() => {
        if(progress > -1) {
            const loadingTimeout = setTimeout(() => {
                setProgress(-1);
                clearTimeout(loadingTimeout);
            }, 2500)
        }
    }, [progress])

    useEffect(() => {
        if(parentCall === "reset") {
            setIsActive(false);
        }
    }, [parentCall])

    const onPrevClick = () => {
        setProgress(10);
        handlePrev();
    }

    const onNextClick = () => {
        handleNext();
    }

    useEffect(() => {
        if(isActive) {
            const elm = document.querySelector('.collaborations-dragger-wrapper');
            elm?.classList.add('activated');
        } else {
            const elm = document.querySelector('.collaborations-dragger-wrapper');
            elm?.classList.remove('activated');
        }
    }, [isActive])

    return (
        <div className={`island-nav ${`island-nav--${variant}`} ${isActive ? 'activated' : ''} ${progress > -1 ? 'loading-active' : ''}`}>
            <div className='island-nav--controls-to-search-and-filters-content'>
                <div className='dd dd1'>
                    <IslandControlsNav isMobileControlsNav handleMenu={onMenuClick} handlePrev={onPrevClick} handleNext={onNextClick} />
                </div>
                <div className='dd dd2'>
                    <IslandNav variant='search-and-filter-island' handleClose={onCloseClick} data={data} handleSearch={handleSearch} handleRegion={handleRegion} selectedRegions={selectedRegions} />
                </div>
                <div className='dd dd3'>
                    <IslandLoadingNav progress={100} loading={true} />
                </div>
            </div>
        </div>
    );
}

export default ControlsToSearchAndFilter;
