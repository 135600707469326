import { GET_DISCOVER_DETAILS } from './actionTypes';

const initialState = {
    details: {},
}

function getDetails(state = initialState, action) {
    switch (action.type) {

        case GET_DISCOVER_DETAILS:
            return {...state, details: action.payload.data}

        default:
            return state
    }
}

export default getDetails;
