import React from "react";
import {useContext, createContext} from "react";
import useCurate, {useCurateTypes} from "../hooks/useCurate";

export const CurateContext = createContext(null);

export const CurateContextProvider = ({children, galleryFiles,linkedArtworks, collaborationsData}) => {
    const {loadingProgress, scene, handleUpdateLobbyGalleries, resizeLoop, handleFocusOnLobbyGallery }: useCurateTypes = useCurate({collaborationsData, galleryFiles, linkedArtworks});

    const value={loadingProgress, handleUpdateLobbyGalleries, scene, resizeLoop, handleFocusOnLobbyGallery};
    return <CurateContext.Provider value={value}>{children}</CurateContext.Provider>
}

export const useCurateContext = () => useContext(CurateContext)
