import React from "react";
import { Paragraph } from "../Typography2";
import { constructImageUrl } from "../../utils/index";
import "./style.scss";
// import { truncateText } from '../../utils'

export const truncateText = (text, maxLength = 100, appendedChild) => {
  if (!text) return '';  // Return an empty string if text is undefined or null
  if (text.length > maxLength) {
    return <>{text.slice(0, maxLength) + '... '}{appendedChild && appendedChild}</>; 
  }
  return text;
}

const ArtworksList = ({
  artworks,
  handleArtworkClick,
  roomId,
  isOldExhibition,
  handleClickOnMiniDetails,
  setShowDescription
}) => {
  const handleMoreClick = () => {
    handleClickOnMiniDetails();
    setShowDescription(true);
  }
  return (
    <div className="artworks-list-wrapper">
      <div className="boxes-wrapper">
        <div className={`boxes`}>
          {artworks?.length > 0 &&
            artworks
              .filter(
                (artwork) =>
                  !artwork.data.isCollateral &&
                  (isOldExhibition ||
                    parseInt(roomId) === artwork.data.exhibitionSceneId)
              )
              .map((item, index) => {
                return (
                  <div className="border" key={index}>
                    <div
                      className="box"
                      key={item.data.id}
                      onClick={() => handleArtworkClick(item.data.id)}
                    >
                      <div className="img">
                        {item.data.type === "2d" ? (
                          <img
                            src={constructImageUrl(item?.data?.image, "300")}
                            alt="artwork img"
                          />
                        ) : item.data.type === "3d" ? (
                          <img
                            src={constructImageUrl(
                              item?.data?.thumbnail,
                              "300"
                            )}
                            alt="artwork img"
                          />
                        ) : (
                          <img
                            src={constructImageUrl(
                              item?.data?.videoThumbnail,
                              "300"
                            )}
                            alt="artwork img"
                          />
                        )}
                      </div>
                      <div className="details">
                        <Paragraph
                          className="description"
                          value={item.data?.artist?.data?.name}
                        />
                        <p
                          className="paragraph title artwork-title"
                          // value={item.data?.title}
                        >
                          {/* {truncateText(item.data?.title, 50, (<button onClick={handleClickOnMiniDetails}>more</button>))} */}
                          {truncateText(item.data?.title, 50, ((item?.data?.description && item?.data?.description?.trim() !== "") ? (<button onClick={handleMoreClick}>more</button>) : null))}
                        </p>
                      </div>
                      <div className="hover-on-animate"></div>
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
};

export default ArtworksList;
