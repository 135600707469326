import React, {useContext} from 'react';
import "./IslandNav.scss";
import IslandLoadingNav from './IslandLoadingNav/IslandLoadingNav';
import ViewGalleryNav from './ViewGalleryNav/ViewGalleryNav';
import IslandGalleryNav from './IslandGalleryNav/IslandGalleryNav';
import IslandControlsNav from './IslandControlsNav/IslandControlsNav';
import IslandSearchFilterNav from './IslandSearchAndFilter/IslandSearchAndFilter';
import IslandSearchFilterDesktop from './IslandSearchFilterDesktop/IslandSearchFilterDesktop';
import IslandSearchAndFilters from './IslandSearchAndFilters/IslandSearchAndFilters';
import ControlsToSearchAndFilter from './ControlsToSearchAndFilter';
import ControlsToLoading from './ControlsToLoading/ControlsToLoading';
import {GalleryItem} from "../GalleryList/types";
import {CollaborationContext} from "../../containers/CollaborationsNewPage/CollaborationsContext";

interface IProps {
    loading?: boolean;
    isMobileControlsNav?: boolean;
    variant: string;
    progress?: number;
    onClick?: () => void;
    classes?: string;
    handleSearch?: (value: string) => void;
    handleNext?: () => void;
    handlePrev?: () => void;
    handleMenu?: () => void;
    handleClose?: () => void;
    handleRegion?: (regions: GalleryItem[]) => void;
    data?:GalleryItem[],
    selectedRegions?:string[],
    isActiveProp?: boolean,
    parentCall?: string,
    mobileProgress?: number;
    setMobileProgress?: (val: number) => void
}

const IslandNav = ({ loading = false, variant, progress = null, onClick = null, classes = '', handleSearch, handleNext, handlePrev, isMobileControlsNav, handleRegion, data=[], handleMenu, selectedRegions, handleClose, parentCall }: IProps) => {
    const {controlsLoading, mobileProgress, setMobileProgress} = useContext(CollaborationContext)

    if (variant === "controls-to-loading") {
        return <ControlsToLoading progress={controlsLoading} variant={variant} handleNext={handleNext} handlePrev={handlePrev} isMobileControlsNav={isMobileControlsNav} handleMenu={handleMenu} />
    }

    if (variant === "search-and-filter-island") {
        return <IslandSearchAndFilters selectedRegions={selectedRegions} data={data} handleRegion={handleRegion} variant={variant} handleSearch={handleSearch} handleClose={handleClose} />
    }

    if (variant === 'controls-to-search-and-filters'){
        return <ControlsToSearchAndFilter variant={variant} handleMenu={handleMenu} parentCall={parentCall} progress={mobileProgress} setProgress={setMobileProgress} handleClose={handleClose} />
    }

    return (
        <div className={`island-nav ${`island-nav--${variant}`} ${classes} ${isMobileControlsNav ? 'island-nav--controls-mobile-view' : ''}`}>
            {variant === "loading" && <IslandLoadingNav progress={progress} loading={loading} />}
            {variant === "gallery" && <IslandGalleryNav />}
            {variant === "view-gallery" && <ViewGalleryNav />}
            {variant === "controls" && <IslandControlsNav isMobileControlsNav={isMobileControlsNav} />}
            {variant === "search-and-filter" && <IslandSearchFilterNav />}
            {variant === "search-and-filter-desktop" && <IslandSearchFilterDesktop handleSearch={handleSearch} />}
        </div>
    );
}

export default IslandNav;
