export const LOGIN = "LOGIN";
export const SET_IS_ERROR = "SET_IS_ERROR";
export const CHANGE_SEARCH = "CHANGE_SEARCH";
export const SET_FIRST_LOAD = "SET_FIRST_LOAD";
export const SET_ARTWORK_ID = "SET_ARTWORK_ID";
export const SET_VIEW_HEIGHT = "SET_VIEW_HEIGHT";
export const SET_CONVERSATION = "SET_CONVERSATION";
export const SET_REDIRECT_PATH = "SET_REDIRECT_PATH";
export const SET_ARTWORKS_IDS = "SET_ARTWORKS_IDS";
export const SET_EXHIBITION_ID = "SET_EXHIBITION_ID";
export const SET_IS_MOBILE_FORM = "SET_IS_MOBILE_FORM";