import {FETCH_GALLERIES_SUCCESS, SET_GALLERIES_LOADER, UPDATE_SORT_BY, RESET_GALLERIES} from './actionTypes'

const initialState = {
    galleries: [],
    galleriesLoading: true,
    canLoadMore: true,
    currentPage: 0,
    pages: {},
    sortBy: 'alphabetical',
}

function reducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_GALLERIES_SUCCESS:
            return {
                ...state,
                galleries: action.payload.currentPage === 1 ? action.payload.data : [...state.galleries, ...action.payload.data],
                pages: action.payload.page,
                currentPage: action.payload.currentPage,
                canLoadMore: action.payload.page && action.payload.page.lastPage && action.payload.currentPage < action.payload.page.lastPage.index,
                galleriesLoading: false,
            };

        case UPDATE_SORT_BY:
            return {...state, sortBy: action.payload};

        case SET_GALLERIES_LOADER:
            return {
                ...state,
                galleriesLoading: action.payload,
            };

        case RESET_GALLERIES:
            return {
                ...state,
                galleries: [],
                galleriesLoading: true,
                canLoadMore: true,
                currentPage: 0,
                pages: {},
                sortBy: 'alphabetical',
                type: '',
            };

        default:
            return state;
    }
}

export default reducer
