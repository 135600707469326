import React from 'react';
import IconBtn from '../IconBtn';
import "./style.scss";
import { Heading, Paragraph, Label } from '../Typography2';
import Button from '../Button';

export default function RoomSwitchCard({ onClose, isActive, label, index, handleRoomSelection, handleCancel, roomId }) {

  const handleRoomEnter = (isActiveRoom: boolean, roomId: string | number) => {
    if(isActiveRoom){
      handleCancel();
    } else {
      handleRoomSelection(roomId);
    }
  }

  return (
    <div className={`room-switch-card ${isActive ? 'active' : ''}`}>
        <Paragraph className='current-viewing' value="Currently Viewing" />
        <div>
            <Paragraph value={`Room ${index}`} />
            <Heading value={label} />
        </div>
        <Button onClick={() => handleRoomEnter(isActive, roomId)} className='room-switch-card__button' iconType="next-step" value={isActive ? 'Return to Room' : 'Visit Room'} /> 
    </div>
  )
}
