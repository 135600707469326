import React from 'react';
import { Heading } from "../Typography2";
import './styles.scss';

const CollaborationsHeader = ({ title, children = null, bottomGutter = false, className = '' }) => {
  return (
    <div className={`sub-header ${bottomGutter ? 'sub-header--bottom-gutter' : ''} ${className}`}>
      <div className='left-menu'>
        <Heading className='sub-header-title' value={title} />
      </div>
      <div className='right-menu'>
        {children}
      </div>
    </div>
  )
}

export default CollaborationsHeader;